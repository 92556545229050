import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { parseUser } from 'utils/types/User';

const useSensorGroupUsers = (sensorGroupId?: string) => {
  const queryKey = ['sensorGroupUsers', sensorGroupId];

  // Get
  const { data: users, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/sensor_groups/${sensorGroupId}/users`),
    select: (users: any[]) => users.map(parseUser),
    enabled: !!sensorGroupId,
  });

  return {
    users,
    isPending,
  };
};

export default useSensorGroupUsers;
