import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import api from 'utils/api';
import HookOptions from 'utils/types/HookOptions';
import { parseReport } from 'utils/types/Report';
import { ReportRevisionFormValues } from 'utils/types/ReportRevision';

const useUserReports = (userId?: string, { enableGet = true }: HookOptions = {}) => {
  const queryKey = ['userReports', userId];

  const queryClient = useQueryClient();

  // Get
  const {
    data: reports,
    isPending,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: () => api.get(`/users/${userId}/reports`),
    select: (reports: any[]) => reports.map(parseReport),
    enabled: !!userId && enableGet,
  });

  // Create
  const { mutateAsync: createReportByUser } = useMutation({
    mutationFn: async ({ userId, ...values }: ReportRevisionFormValues & { userId: string }) => {
      const report = await api.post(`/users/${userId}/reports`, values);
      return parseReport(report);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userReports', userId] });
    },
  });

  return {
    createReportByUser,
    reports,
    isPending: isPending || isLoading || isFetching,
  };
};

export default useUserReports;
