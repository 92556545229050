import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import useCurrentUser from 'utils/hooks/use-current-user';

type EndpointReturnProps = {
  uptimeCounts?: number[][];
  uptimeDayNames?: string[];
  uptimeHours?: string[];
  numGateways?: number;
  numSensorsActive?: number;
  // numSensorsInactive: number;
  numSensors?: number;
  fractionGatewaysUptime?: number;
  fractionActiveSensors?: number;
  numHeartbeatsTarget?: number;
  numHeartbeats?: number;
  numHeartbeatsCurrent?: number;
};

const useAdminInsightsKpis = (): {
  data: EndpointReturnProps;
  isPending: boolean;
} => {
  const queryKey = ['adminInsightsKpis'];

  const { isAdmin } = useCurrentUser();
  const { data, isPending } = useQuery<EndpointReturnProps, Error>({
    queryKey,
    queryFn: () => api.get('/admin/insights/kpis'),
    select: ({
      counts_calendar_day_names: uptimeDayNames,
      // counts_calendar_grid_shape: countsCalendarGridShape,
      counts_calendar_grid: uptimeCounts,
      counts_calendar_hours: uptimeHours,
      fraction_active_sensors: fractionActiveSensors,
      fraction_gateways_uptime: fractionGatewaysUptime,
      // from_timestamp: fromTimestamp,
      num_gateways: numGateways,
      num_heartbeats_current: numHeartbeatsCurrent,
      num_heartbeats_target: numHeartbeatsTarget,
      num_heartbeats: numHeartbeats,
      num_sensors_active: numSensorsActive,
      // num_sensors_inactive: numSensorsInactive,
      num_sensors: numSensors,
    }: any) =>
      ({
        uptimeCounts,
        // countsCalendarGridShape,
        uptimeDayNames,
        uptimeHours,
        fractionActiveSensors,
        fractionGatewaysUptime,
        // fromTimestamp,
        numGateways,
        numHeartbeatsCurrent,
        numHeartbeatsTarget,
        numHeartbeats,
        numSensorsActive,
        // numSensorsInactive,
        numSensors,
      } as EndpointReturnProps),
    placeholderData: {} as EndpointReturnProps,
    enabled: isAdmin,
  });

  return { data: data as EndpointReturnProps, isPending };
};

export default useAdminInsightsKpis;
