import Spinner from 'components/Spinner';

export type PlotlyData = {
  data: any;
  layout: any;
};

const getCellClassName = (index: number, size: number) => {
  let cellClassName = 'w-full h-full';
  if (index % size !== 0) cellClassName += ' border-r';
  if (index - size > 0) cellClassName += ' border-t';
  if (cellClassName.includes('border')) cellClassName += ' border-brand-gray-lighter-3';
  return cellClassName;
};

export const LoadingPlot: React.FC<{ hideSpinner?: boolean }> = ({ hideSpinner = false }) => (
  <div className="relative">
    <div className="h-96 w-full rounded bg-brand-beige p-8 ">
      <div className="grid grid-cols-5 grid-rows-5 w-full h-full">
        {Array.from({ length: 25 }, (_, i) => (
          <div key={i} className={getCellClassName(i + 1, 5)} />
        ))}
      </div>
    </div>

    {!hideSpinner && <Spinner className="absolute inset-2/4 border-brand-blue" />}
  </div>
);
