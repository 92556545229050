import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { USE_CUSTOMER_SENSOR_GROUPS_QUERY_KEY, USE_USER_GROUPS_QUERY_KEY } from 'utils/hooks/data';
import { useQueryDelete } from 'utils/queries/delete';
import { useQueryUpdate } from 'utils/queries/update';
import HookOptions from 'utils/types/HookOptions';
import SensorGroup, { parseSensorGroup } from 'utils/types/SensorGroup';

const useSensorGroup = (sensorGroupId?: string, { enableGet = true }: HookOptions = {}) => {
  const queryKey = ['sensorGroups', sensorGroupId];

  // Get
  const {
    data: sensorGroup,
    isPending,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: () => api.get(`/sensor_groups/${sensorGroupId}`),
    select: (data: any) => parseSensorGroup(data),
    enabled: enableGet && !!sensorGroupId,
  });

  // Update
  const { mutateAsync: updateSensorGroup } = useQueryUpdate<Partial<SensorGroup>>({
    api,
    endpoint: `/sensor_groups/${sensorGroupId}`,
    queryKey,
    invalidateQueryKeys: [
      queryKey.slice(0, 1),
      [USE_CUSTOMER_SENSOR_GROUPS_QUERY_KEY],
      [USE_USER_GROUPS_QUERY_KEY],
    ],
  });

  // Delete
  const { mutateAsync: deleteSensorGroup } = useQueryDelete({
    queryKey,
    api,
    endpoint: `/sensor_groups/${sensorGroupId}`,
    invalidateQueryKeys: [
      queryKey.slice(0, 1),
      ['customerSensorGroups'],
      ['sensorGroupSubgroups'],
      ['sensorGroupParentGroup'],
      ['sensorGroupParentGroups'],
      ['sensorGroupsHierarchyTree'],
      [USE_USER_GROUPS_QUERY_KEY],
      [USE_CUSTOMER_SENSOR_GROUPS_QUERY_KEY],
    ],
  });

  return {
    sensorGroup,
    isPending: isPending || isLoading || isFetching,
    updateSensorGroup,
    deleteSensorGroup,
  };
};

export default useSensorGroup;
