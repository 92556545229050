import { t } from 'translations';
import { SensorTagRoomType } from 'utils/enums/sensor-tags';

const getSensorTagRoomTypeText = (sensorTagRoomType: SensorTagRoomType) => {
  switch (sensorTagRoomType) {
    case SensorTagRoomType.TechnicalFacilitiesRoom:
      return t('utils:texts.sensor-tags.getSensorTagRoomTypeText.TechnicalFacilitiesRoom');
    case SensorTagRoomType.WetRoom:
      return t('utils:texts.sensor-tags.getSensorTagRoomTypeText.WetRoom');
    case SensorTagRoomType.Kitchen:
      return t('utils:texts.sensor-tags.getSensorTagRoomTypeText.Kitchen');
    case SensorTagRoomType.Basement:
      return t('utils:texts.sensor-tags.getSensorTagRoomTypeText.Basement');
    case SensorTagRoomType.Bedroom:
      return t('utils:texts.sensor-tags.getSensorTagRoomTypeText.Bedroom');
    case SensorTagRoomType.Office:
      return t('utils:texts.sensor-tags.getSensorTagRoomTypeText.Office');
    case SensorTagRoomType.Other:
      return t('utils:texts.sensor-tags.getSensorTagRoomTypeText.Other');
    case SensorTagRoomType.NotRelevant:
      return t('utils:texts.sensor-tags.getSensorTagRoomTypeText.NotRelevant');
    default:
      return 'n/a';
  }
};

export const getSensorTagRoomTypeTextShort = getSensorTagRoomTypeText;

export default getSensorTagRoomTypeText;
