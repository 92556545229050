import useFiles from 'utils/hooks/data/use-files';
import CustomerFile, { parseCustomerFile } from 'utils/types/CustomerFile';

const useCustomerFiles = (customerId: string) => {
  const { createFile } = useFiles<CustomerFile>({
    queryKey: ['customerFiles', customerId],
    endpoint: `/customers/${customerId}/files`,
    enabled: false,
    parseFn: parseCustomerFile,
  });
  return { createFile };
};

export default useCustomerFiles;
