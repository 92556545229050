import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table, TableProps } from 'components';
import ExpandableDiv from 'components/ExpandableDiv';
import { formatFunctionDate } from 'utils/tables/format-functions';
import SensorJoinAccept from 'utils/types/SensorJoinAccept';

export const SensorJoinAcceptsTable: React.FC<
  {
    sensorJoinAccepts: SensorJoinAccept[];
    expandable?: boolean;
  } & Omit<TableProps<SensorJoinAccept>, 'data' | 'columns' | 'tableIdentifier'>
> = ({ sensorJoinAccepts, expandable = false, ...props }) => {
  const { t } = useTranslation('components');

  // Columns
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<SensorJoinAccept>();

    return [
      columnHelper.accessor('timestamp', {
        id: 'timestamp',
        header: () => t('tables.SensorJoinAcceptsTable.columns.timestamp.text'),
        enableColumnFilter: false,
        sortingFn: 'datetime',
        sortDescFirst: true,
        cell: ({ getValue }) => formatFunctionDate(getValue()),
      }),
    ];
  }, [t]);

  const elements = (
    <>
      <Table
        data={sensorJoinAccepts}
        columns={columns}
        pageSize={5}
        noDataPlaceholder={t('tables.SensorJoinAcceptsTable.noDataIndication')}
        sortBy={[
          {
            id: 'timestamp',
            desc: true,
          },
        ]}
        {...props}
      />
    </>
  );

  return expandable ? (
    <ExpandableDiv
      title={t('tables.SensorJoinAcceptsTable.title')}
      tooltipExpand={t('tables.SensorJoinAcceptsTable.ExpandButton.hide')}
      tooltipCollapse={t('tables.SensorJoinAcceptsTable.ExpandButton.show')}
    >
      {elements}
    </ExpandableDiv>
  ) : (
    elements
  );
};
