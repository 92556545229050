import useFiles from 'utils/hooks/data/use-files';
import SensorGroupFile, { parseSensorGroupFile } from 'utils/types/SensorGroupFile';

const useSensorGroupFiles = (sensorGroupId?: string) =>
  useFiles<SensorGroupFile>({
    queryKey: ['sensorGroupFiles', sensorGroupId],
    endpoint: `/sensor_groups/${sensorGroupId}/files`,
    enabled: !!sensorGroupId,
    parseFn: parseSensorGroupFile,
    invalidateQueryKeys: [
      ['sensorGroups', sensorGroupId],
      ['sensorGroupFiles', sensorGroupId],
      ['sensorGroupPrimaryFile', sensorGroupId],
    ],
  });

export default useSensorGroupFiles;
