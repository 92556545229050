import { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

// Source: https://www.svgrepo.com/svg/322013/chart
const faWsChartUp: IconDefinition = {
  prefix: 'fac' as IconPrefix,
  iconName: 'splat' as IconName,
  icon: [
    512, // width
    512, // height
    [], // ligatures
    '', // unicode (if relevant)
    // svg path data
    'M23 23v466h466v-18H41v-82.184l85.854-57.234 70.023 70.022 65.133-260.536L387.28 203.7 455.07 95.73l19.317 11.858 6.102-71.1-60.644 37.616 19.884 12.207-59.01 93.99-130.732-65.366-62.865 251.462-57.98-57.978L41 367.184V23H23z',
  ],
};

export default faWsChartUp;
