import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { useQueryDelete } from 'utils/queries/delete';
import { useQueryUpdate } from 'utils/queries/update';
import Gateway, { parseGateway } from 'utils/types/Gateway';
import HookOptions from 'utils/types/HookOptions';

const useGateway = (gatewayId?: string, { enableGet = true }: HookOptions = {}) => {
  const queryKey = ['gateways', gatewayId];

  // Get
  const {
    data: gateway,
    isPending,
    isSuccess,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/gateways/${gatewayId}`, {
        _disableErrorNotifications: true,
      }),
    select: (gateway: any) => parseGateway(gateway),
    enabled: enableGet && !!gatewayId,
  });
  const gatewayExists = isSuccess;

  // Update
  const { mutateAsync: updateGateway } = useQueryUpdate<Gateway>({
    api,
    queryKey,
    endpoint: `/gateways/${gatewayId}`,
    invalidateQueryKeys: [['customerGateways'], ['gatewayAccessLinks', gatewayId]],
    parseFn: parseGateway,
  });

  // Delete
  const { mutateAsync: deleteGateway } = useQueryDelete<Gateway>({
    api,
    queryKey,
    endpoint: `/gateways/${gatewayId}`,
    invalidateQueryKeys: [['customerGateways']],
  });

  return {
    gateway,
    isPending: isPending || isLoading || isFetching,
    updateGateway,
    deleteGateway,
    gatewayExists,
  };
};

export default useGateway;
