import { closestTo } from 'date-fns/closestTo';
import { isBefore } from 'date-fns/isBefore';
import { subHours } from 'date-fns/subHours';

import SensorType from 'utils/enums/SensorType';
import Sensor from 'utils/types/Sensor';
import Transmission from 'utils/types/Transmission';
import TransmissionAnomaly from 'utils/types/TransmissionAnomaly';

const parseTransmissionsMixed = ({
  transmissions,
  sensors,
  anomalies,
}: {
  transmissions?: Transmission[];
  sensors?: Sensor[];
  anomalies?: TransmissionAnomaly[];
}) => {
  const hardwareId2sensorType: { [key: string]: SensorType } = {};
  const hardwareId2transmissions: { [key: string]: Transmission[] } = {};
  sensors?.forEach(sensor => {
    const hardwareId = (sensor.hardware_id as string) || 'undefined';
    hardwareId2sensorType[hardwareId] = sensor.type;
    hardwareId2transmissions[hardwareId] = [];
  });

  // Use corrected moisture values based on wood type
  transmissions?.forEach(transmission => {
    // Make sure missing sensors are handled
    if (!hardwareId2transmissions[transmission.hardware_id]) {
      hardwareId2transmissions[transmission.hardware_id] = [];
    }

    hardwareId2transmissions[transmission.hardware_id].push(transmission);
  });

  const parsedTransmissions = Object.keys(hardwareId2transmissions)
    .map(hardwareId => chainInvalidTransmissions(hardwareId2transmissions[hardwareId]))
    .flat();

  if (anomalies) {
    const transmissionId2anomaly: { [key: string]: TransmissionAnomaly } = anomalies?.reduce(
      (o: any, anomaly: TransmissionAnomaly) => ({
        ...o,
        [anomaly.transmission_id]: anomaly,
      }),
      {},
    );

    // Save any transmission anomaly on the transmission object
    parsedTransmissions.forEach((transmission: Transmission) => {
      transmission.anomaly = transmissionId2anomaly[transmission.id];
    });
  }

  return parsedTransmissions;
};

const chainInvalidTransmissions = (transmissions: Transmission[]) => {
  const n = transmissions.length;
  if (n === 0) return [];

  // If first transmission is invalid set default moisture value
  if (transmissions[n - 1].isInvalid) {
    transmissions[n - 1].moisture = 15;
  }

  // Chain moisture values
  // For any given invalid transmission use the previous value
  for (let i = n - 2; i >= 0; i--) {
    const transmission = transmissions[i];
    const previousTransmission = transmissions[i + 1];
    if (transmission.isInvalid) {
      transmission.moisture = previousTransmission.moisture;
    }
  }

  return transmissions;
};

export const getSensorTransmission = (
  timeTo: Date,
  max: number,
  hours: number,
  sensorId2Transmission: { [key: string]: Transmission[] | undefined } | undefined,
  sensorId: string,
) => {
  const date = subHours(timeTo, max - hours);
  const transmissionsDates = !!sensorId2Transmission
    ? (sensorId2Transmission[sensorId] || [])
        .map(transmission => transmission.timestamp)
        .filter(transmissionDate => isBefore(transmissionDate, date))
    : [];

  const closestTimestamp = closestTo(date, transmissionsDates);

  const transmission = sensorId2Transmission
    ? sensorId2Transmission[sensorId]?.find(
        transmission => transmission.timestamp.getTime() === closestTimestamp?.getTime(),
      ) || ({} as Transmission)
    : ({} as Transmission);

  return transmission;
};

export { chainInvalidTransmissions, parseTransmissionsMixed };
