import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { parseCustomer } from 'utils/types/Customer';
import HookOptions from 'utils/types/HookOptions';

const useUserCustomers = (userId?: string, { enableGet = true }: HookOptions = {}) => {
  const {
    data: customers,
    isPending,
    isFetching,
  } = useQuery({
    queryKey: ['userCustomers', userId],
    queryFn: () => api.get(`/users/${userId}/customers`),
    select: (customers: any[]) => customers.map(parseCustomer),
    enabled: !!userId && enableGet,
  });

  return {
    customers,
    isPending: isPending || isFetching,
  };
};

export default useUserCustomers;
