import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { BlueprintFormValues } from 'components/forms/BlueprintForm';
import api from 'utils/api';
import { Blueprint, parseBlueprint } from 'utils/types';
import EnrichedFile from 'utils/types/EnrichedFile';
import HookOptions from 'utils/types/HookOptions';

type BlueprintCreate = Omit<BlueprintFormValues, 'file'> & EnrichedFile;

const useUserBlueprints = (userId?: string, { enableGet = true }: HookOptions = {}) => {
  const queryKey = ['userBlueprints', userId];

  const queryClient = useQueryClient();

  // Get
  const {
    data: blueprints,
    isPending,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: () => api.get(`/users/${userId}/blueprints`),
    select: (blueprints: any[]) => blueprints.map(parseBlueprint),
    enabled: !!userId && enableGet,
  });

  // Create
  const { mutateAsync: createBlueprintByUser } = useMutation({
    mutationFn: async (values: BlueprintCreate): Promise<Blueprint> => {
      const blueprint = await api.post(`/users/${userId}/blueprints`, values);
      return parseBlueprint(blueprint);
    },
    onSuccess: (blueprint: Blueprint): void => {
      queryClient.setQueryData(queryKey, (old?: Blueprint[]) =>
        Array.isArray(old) ? [...old, blueprint] : [blueprint],
      );
    },
  });

  return {
    blueprints,
    isPending: isPending || isLoading || isFetching,
    createBlueprintByUser,
  };
};

export default useUserBlueprints;
