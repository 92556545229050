import { t } from 'translations';
import { SensorTagClimate } from 'utils/enums/sensor-tags';

const getSensorTagClimateText = (sensorTagClimate: SensorTagClimate) => {
  switch (sensorTagClimate) {
    case SensorTagClimate.Indoor:
      return t('utils:texts.sensor-tags.getSensorTagClimateText.Indoor');
    case SensorTagClimate.Outdoor:
      return t('utils:texts.sensor-tags.getSensorTagClimateText.Outdoor');
    default:
      return 'n/a';
  }
};

export const getSensorTagClimateTextShort = (sensorTagClimate: SensorTagClimate) => {
  switch (sensorTagClimate) {
    case SensorTagClimate.Indoor:
      return t('utils:texts.sensor-tags.getSensorTagClimateTextShort.Indoor');
    case SensorTagClimate.Outdoor:
      return t('utils:texts.sensor-tags.getSensorTagClimateTextShort.Outdoor');
    default:
      return 'n/a';
  }
};

export default getSensorTagClimateText;
