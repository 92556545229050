import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table, TableLink } from 'components';
import { dateToLocaleString, dateToPrettyDate } from 'utils/date';
import Note from 'utils/types/Note';

export const NotesTable: React.FC<{ data: Note[] }> = ({ data, ...props }) => {
  const { t } = useTranslation('components');

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<Note>();

    return [
      columnHelper.accessor('id', {
        id: 'id',
        header: () => t('tables.NotesTable.columns.id.text'),
        cell: ({ getValue, row: { original } }) => (
          <TableLink
            name={<samp>{getValue() || original.id}</samp>}
            url={`/user/notes/${getValue() || original.id}`}
            datatip={t('tables.NotesTable.columns.id.dataTip.1')}
            externalDatatip={t('tables.NotesTable.columns.id.dataTip.2')}
          />
        ),
        enableColumnFilter: true,
        filterFn: 'equalsString',
        meta: {
          filterVariant: 'select',
        },
      }),

      columnHelper.accessor('created_date', {
        id: 'created_date',
        header: () => t('tables.NotesTable.columns.created_date.text'),
        sortingFn: 'datetime',
        enableColumnFilter: false,
        sortDescFirst: true,
        cell: ({ getValue }) => (
          <span
            data-tooltip-content={dateToLocaleString(getValue())}
            data-tooltip-id="route-tooltip"
          >
            {dateToPrettyDate(getValue())}
          </span>
        ),
      }),
    ];
  }, [t]);

  return (
    <Table
      data={data}
      columns={columns}
      pageSize={10}
      showSearchBar={true}
      showTogglingFilters={true}
      sortBy={[
        {
          id: 'created_date',
          desc: true,
        },
      ]}
      placeholder={t('tables.NotesTable.search.placeholder')}
      noDataPlaceholder={t('tables.NotesTable.noDataIndication')}
      {...props}
    />
  );
};
