import { faBellSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createColumnHelper } from '@tanstack/react-table';
import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { GatewayStateBadge, Table } from 'components';
import EditOnHoverText from 'components/EditOnHoverText';
import GatewayLink from 'components/GatewayLink';
import GatewayUsersInlineList from 'components/gateways/GatewayUsersInlineList';
import UpdateGatewayModal from 'components/modals/UpdateGatewayModal';
import HeartbeatStatus from 'components/signals/HeartbeatStatus';
import LatestTransmissionStatus from 'components/signals/LatestTransmissionStatus';
import NetworkServerStatus from 'components/signals/NetworkServerStatus';
import SimStatus from 'components/signals/SimStatus';
import { AlarmCell } from 'components/tables/GatewaysPaginatedTable/components';
import GatewayThumbnail from 'components/thumbnails/GatewayThumbnail';
import { lg } from 'utils/breakpoints';
import { dateToLocaleString, dateToPrettyDate } from 'utils/date';
import { useWindowSize, useTimePeriod } from 'utils/hooks';
import { useGateway } from 'utils/hooks/data';
import { notificationSuccess } from 'utils/notifications';
import { percentageMetricCellFn } from 'utils/tables/format-functions';
import { sortingFnDateTime, sortingFnGeographicLocation } from 'utils/tables/sort-functions';
import { getGatewayStateText } from 'utils/texts/gateway-state';
import getGatewaySensorsDistanceText from 'utils/texts/GatewaySensorsDistance';
import Gateway, { networkServerSource2value } from 'utils/types/Gateway';

export const GatewaysPaginatedTable: React.FC<{
  gateways: Gateway[];
  showSearchBar?: boolean;
  isPending?: boolean;
  fromSensorGroupId?: string;
  includeConnectionStatusColumns?: boolean;
}> = ({
  gateways,
  showSearchBar = true,
  isPending,
  fromSensorGroupId,
  includeConnectionStatusColumns = false,
  ...props
}) => {
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [activeGatewayId, setActiveGatewayId] = useState<string>();

  const { t } = useTranslation();

  const [width] = useWindowSize();
  const {
    timePeriod: [timeFrom, timeTo],
  } = useTimePeriod();

  const { gateway, updateGateway } = useGateway(activeGatewayId);

  const data = useMemo(() => gateways || [], [gateways]);

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<Gateway>();

    return [
      columnHelper.accessor('name', {
        id: 'name',
        header: () => t('components:tables.GatewaysPaginatedTable.columns.name.text'),
        size: 400,
        cell: ({ getValue, row: { original } }) => (
          <div className="flex items-center max-h-full gap-2">
            <GatewayLink
              className="shrink-0"
              gatewayId={original.id}
              fromSensorGroupId={fromSensorGroupId}
              timeFrom={timeFrom}
              timeTo={timeTo}
            >
              <GatewayThumbnail
                gatewayId={original.id}
                gatewayFileId={original.gateway_file_id}
                height={34}
              />
            </GatewayLink>

            <GatewayLink
              gatewayId={original.id}
              fromSensorGroupId={fromSensorGroupId}
              timeFrom={timeFrom}
              timeTo={timeTo}
            >
              <>{getValue() ? getValue() : t('utils:unknown')} </>
            </GatewayLink>

            {!original.send_alarms && (
              <FontAwesomeIcon
                className="text-brand-gray-light-1"
                icon={faBellSlash}
                size="lg"
                data-tooltip-content={t(
                  'components:tables.GatewaysPaginatedTable.columns.name.tooltip',
                )}
                data-tooltip-id="route-tooltip"
              />
            )}
          </div>
        ),
        enableColumnFilter: false,
      }),

      columnHelper.accessor('geographic_location', {
        id: 'geographic_location',
        header: () => t('components:tables.GatewaysPaginatedTable.columns.description'),
        size: width > lg ? 300 : 250,
        sortingFn: sortingFnGeographicLocation,
        enableColumnFilter: false,
        cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <EditOnHoverText
            text={
              original.geographic_location?.description ||
              t('components:tables.GatewaysPaginatedTable.columns.descriptionPlaceholder')
            }
            onClick={() => {
              setActiveGatewayId(original.id);
              setShowUpdateModal(true);
            }}
          />
        ),
      }),

      columnHelper.accessor('state', {
        header: () => t('components:tables.GatewaysPaginatedTable.columns.state.text'),
        id: 'state',
        filterFn: 'equalsString',
        meta: {
          filterVariant: 'select',
          transform: getGatewayStateText,
          className: 'text-center',
        },
        size: 150,
        cell: ({ getValue }) => <GatewayStateBadge state={getValue()} />,
      }),

      columnHelper.accessor('send_alarms', {
        header: () => t('components:tables.GatewaysPaginatedTable.columns.alarms'),
        id: 'send_alarms',
        sortingFn: 'basic',
        enableColumnFilter: false,
        meta: {
          className: 'text-center',
        },
        size: 150,
        cell: ({
          cell: {
            row: { original },
          },
        }) => <AlarmCell id={original.id} checked={!!original.send_alarms} />,
      }),

      columnHelper.accessor('setup_completed_at', {
        header: () => t('components:tables.GatewaysPaginatedTable.columns.setup_completed_at'),
        id: 'setup_completed_at',
        enableColumnFilter: false,
        sortingFn: sortingFnDateTime,
        size: 200,
        cell: ({ getValue }) => (
          <span
            data-tooltip-content={dateToLocaleString(getValue())}
            data-tooltip-id="route-tooltip"
          >
            {dateToPrettyDate(getValue())}
          </span>
        ),
      }),

      columnHelper.accessor('latest_transmission_received_at', {
        header: () =>
          t('components:tables.GatewaysPaginatedTable.columns.latest_transmission_received_at'),
        id: 'latest_transmission_received_at',
        sortingFn: sortingFnDateTime,
        enableColumnFilter: false,
        size: width > lg ? 210 : 170,
        cell: ({ getValue }) => (
          <LatestTransmissionStatus latestTransmissionReceivedAt={getValue()} />
        ),
      }),

      columnHelper.accessor('sim_id', {
        header: () => t('components:tables.GatewaysTable.columns.sim_id.text'),
        id: 'sim_id',
        enableColumnFilter: false,
        size: width > lg ? 180 : 140,
        meta: {
          hidden: !includeConnectionStatusColumns,
        },
        cell: ({ getValue, row: { original } }) => (
          <SimStatus
            className="text-lg"
            simId={getValue()}
            simSource={original.sim_source}
            simIpAddress={original.sim_ip_address}
            simOnline={original.sim_online}
          />
        ),
      }),

      columnHelper.accessor('network_server_source', {
        header: () => t('components:tables.GatewaysTable.columns.network_server_source.text'),
        id: 'network_server_source',
        enableColumnFilter: false,
        sortingFn: (rowA: any, rowB: any) =>
          networkServerSource2value(rowA.original.network_server_source) -
          networkServerSource2value(rowB.original.network_server_source),
        size: width > lg ? 150 : 120,
        meta: {
          hidden: !includeConnectionStatusColumns,
        },
        cell: ({ getValue, row: { original } }) => (
          <NetworkServerStatus
            className="text-lg"
            gatewayId={original.id}
            networkServerSource={getValue()}
            networkServerOnline={original.network_server_online}
          />
        ),
      }),

      columnHelper.accessor('heartbeats_past_24h', {
        header: () => t('components:tables.GatewaysTable.columns.heartbeats_past_24h.text'),
        id: 'heartbeats_past_24h',
        enableColumnFilter: false,
        sortingFn: (rowA: any, rowB: any) =>
          (rowA.original?.heartbeats_past_24h || 0) - (rowB.original?.heartbeats_past_24h || 0),
        size: width > lg ? 130 : 110,
        meta: {
          hidden: !includeConnectionStatusColumns,
        },
        cell: ({ getValue }) => {
          const value = getValue();
          return (
            <HeartbeatStatus
              className="text-lg"
              online={!!value && value > 0}
              countsPast24h={value}
            />
          );
        },
      }),

      columnHelper.accessor('sim_ip_address', {
        header: () => t('components:tables.GatewaysTable.columns.ip.text'),
        id: 'sim_ip_address',
        enableColumnFilter: false,
        size: width > lg ? 180 : 150,
        meta: {
          hidden: !includeConnectionStatusColumns,
        },
        cell: ({ getValue }) => <samp>{getValue()}</samp>,
      }),

      columnHelper.accessor('meta_cpu_usage', {
        header: () => t('components:tables.GatewaysTable.columns.meta_cpu_usage.text'),
        id: 'meta_cpu_usage',
        enableColumnFilter: false,
        cell: ({ getValue }) => percentageMetricCellFn(getValue()),
      }),

      columnHelper.accessor('meta_memory_usage', {
        header: () => t('components:tables.GatewaysTable.columns.memory.text'),
        id: 'meta_memory_usage',
        enableColumnFilter: false,
        cell: ({ getValue }) => percentageMetricCellFn(getValue()),
      }),

      columnHelper.accessor('meta_sd_card_usage', {
        header: () => t('components:tables.GatewaysTable.columns.meta_sd_card_size_kb.text'),
        id: 'meta_sd_card_usage',
        enableColumnFilter: false,
        cell: ({ getValue }) => percentageMetricCellFn(getValue()),
      }),

      columnHelper.accessor('meta_rootfs_usage', {
        header: () => t('components:tables.GatewaysTable.columns.meta_rootfs_size_kb.text'),
        id: 'meta_rootfs_usage',
        enableColumnFilter: false,
        cell: ({ getValue }) => percentageMetricCellFn(getValue()),
      }),

      columnHelper.accessor('customer_id', {
        header: () => t('components:tables.GatewaysPaginatedTable.columns.customer_id'),
        id: 'customer_id',
        enableSorting: false,
        enableColumnFilter: false,
        size: 150,
        cell: ({
          cell: {
            row: { original },
          },
        }) => <GatewayUsersInlineList gatewayId={original.id} />,
      }),

      columnHelper.accessor('sensors_distance', {
        header: () => t('components:tables.GatewaysPaginatedTable.columns.state.sensors_distance'),
        id: 'sensors_distance',
        enableColumnFilter: false,
        filterFn: 'equalsString',
        meta: {
          filterVariant: 'select',
          transform: getGatewaySensorsDistanceText,
          className: 'text-center',
        },
        size: width > lg ? 210 : 170,
        cell: ({ getValue }) => getGatewaySensorsDistanceText(getValue()),
      }),

      columnHelper.accessor('id', {
        header: () => t('utils:id'),
        id: 'id',
        size: 300,
        cell: ({ getValue }) => <samp>{getValue()}</samp>,
        enableColumnFilter: false,
      }),

      columnHelper.accessor('version', {
        header: () => t('utils:version'),
        id: 'version',
        size: 100,
        filterFn: 'equalsString',
        cell: ({ getValue }) => <samp>{getValue()}</samp>,
        meta: {
          filterVariant: 'select',
        },
      }),
    ];
  }, [width, includeConnectionStatusColumns, t, fromSensorGroupId, timeFrom, timeTo]);

  const onUpdateModalSubmit = async (newValues: Partial<Gateway>) => {
    await updateGateway(newValues);
    notificationSuccess(
      t('components:tables.GatewaysPaginatedTable.onUpdateModalSubmit.successText'),
    );
    setActiveGatewayId(undefined);
    setShowUpdateModal(false);
  };

  return (
    <div data-testid="gateways-paginated-table-div">
      <Table
        data={data}
        columns={columns}
        showSearchBar={showSearchBar}
        showTogglingFilters={true}
        loading={isPending}
        placeholder={t('components:tables.GatewaysPaginatedTable.searchBar.placeholder')}
        pageSize={10}
        sortBy={[{ id: 'setup_completed_at', desc: true }]}
        {...props}
      />

      <UpdateGatewayModal
        onSubmit={onUpdateModalSubmit}
        show={showUpdateModal}
        setShow={setShowUpdateModal}
        values={gateway}
      />
    </div>
  );
};
