import {
  faCircleQuestion,
  faCompass,
  faDoorOpen,
  faFan,
  faHome,
  faMicroscope,
  faRulerVertical,
  faScrewdriverWrench,
  faThermometerHalf,
} from '@fortawesome/free-solid-svg-icons';

import {
  faWsBricks2,
  faWsCasing,
  faWsCrane,
  faWsHouse1,
  faWsInsulation,
  faWsKitchen,
  faWsRoomType,
  faWsScrew,
  faWsWall,
} from 'components/icons';
import { SensorTagName, SensorTagDescriptionName } from 'utils/types/SensorTags';

export const getSensorTagIcon = (sensorTagName: SensorTagName | SensorTagDescriptionName) => {
  switch (sensorTagName) {
    case 'primary_element_category':
    case 'primary_element_category_description':
      return faHome;
    case 'primary_element_type':
      return faWsWall;
    case 'primary_element_type_description':
      return faWsBricks2;
    case 'complementary_element':
      return faDoorOpen;
    case 'complementary_element_description':
      return faWsHouse1;
    case 'orientation':
      return faCompass;
    case 'room_type':
      return faWsRoomType;
    case 'room_type_description':
      return faWsKitchen;
    case 'vertical_placement':
      return faRulerVertical;
    case 'casing':
      return faWsCasing;
    case 'climate':
      return faThermometerHalf;
    case 'construction_phase':
      return faWsCrane;
    case 'construction_principles':
      return faScrewdriverWrench;
    case 'insulation':
      return faWsInsulation;
    case 'screw_type':
      return faWsScrew;
    case 'ventilation':
      return faFan;
    case 'usage':
      return faMicroscope;
    default:
      return faCircleQuestion;
  }
};
