import useFile from 'utils/hooks/data/use-file';
import SensorFile, { parseSensorFile } from 'utils/types/SensorFile';

const useSensorFile = (sensorId?: string, fileId?: string) =>
  useFile<SensorFile>({
    queryKey: ['sensorFiles', sensorId, fileId],
    endpoint: `/sensors/${sensorId}/files/${fileId}`,
    enabled: !!sensorId && !!fileId,
    parseFn: parseSensorFile,
    invalidateQueryKeys: [
      ['sensorFiles', sensorId],
      ['sensorPrimaryFile', sensorId, false],
      ['sensorPrimaryFile', sensorId, true],
    ],
  });

export default useSensorFile;
