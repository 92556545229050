import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';

const useTransmissionPayloadUrl = (
  transmissionId: string
): { transmissionPayloadUrl: string; isPending: boolean } => {
  const queryKey = ['transmissionPayloadUrl', transmissionId];

  // Get
  const { data: transmissionPayloadUrl, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/transmissions/${transmissionId}/raw_payload`),
    enabled: !!transmissionId,
  });

  return {
    transmissionPayloadUrl,
    isPending,
  };
};

export default useTransmissionPayloadUrl;
