import { t } from 'translations';
import { SensorTagUsage } from 'utils/enums/sensor-tags';

const getSensorTagUsageText = (sensorTagUsage: SensorTagUsage) => {
  switch (sensorTagUsage) {
    case SensorTagUsage.Construction:
      return t('utils:texts.sensor-tags.getSensorTagUsageText.Construction');
    case SensorTagUsage.TestingOrResearch:
      return t('utils:texts.sensor-tags.getSensorTagUsageText.TestingOrResearch');
    default:
      return 'n/a';
  }
};

export const getSensorTagUsageTextShort = (sensorTagUsage: SensorTagUsage) => {
  switch (sensorTagUsage) {
    case SensorTagUsage.Construction:
      return t('utils:texts.sensor-tags.getSensorTagUsageTextShort.Construction');
    case SensorTagUsage.TestingOrResearch:
      return t('utils:texts.sensor-tags.getSensorTagUsageTextShort.TestingOrResearch');
    default:
      return 'n/a';
  }
};

export default getSensorTagUsageText;
