import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { useCurrentUser } from 'utils/hooks';
import { parseBlueprint } from 'utils/types/Blueprint';

const useAdminBlueprints = () => {
  const queryKey = ['adminBlueprints'];

  const { isAdmin } = useCurrentUser();

  // Get
  const { data: blueprints, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get('/admin/blueprints'),
    select: (blueprints: any[]) => blueprints.map(parseBlueprint),
    enabled: isAdmin,
  });

  return {
    blueprints,
    isPending,
  };
};

export default useAdminBlueprints;
