import { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

// From https://www.svgrepo.com/svg/240209/blueprint
const faWsBlueprint: IconDefinition = {
  prefix: 'fac' as IconPrefix,
  iconName: 'splat' as IconName,
  icon: [
    512, // width
    512, // height
    [], // ligatures
    '', // unicode (if relevant)
    // svg path data
    'M490.667,74.667H167.637c-9.536-36.693-42.645-64-82.304-64C38.272,10.667,0,48.939,0,96v320 c0,47.061,38.272,85.333,85.333,85.333h405.333c11.797,0,21.333-9.536,21.333-21.333V96 C512,84.203,502.464,74.667,490.667,74.667z M128,342.123c-12.565-7.296-27.136-11.456-42.667-11.456 c-15.531,0-30.101,4.16-42.667,11.456V96c0-23.531,19.136-42.667,42.667-42.667C108.864,53.333,128,72.469,128,96V342.123z M403.84,416h-41.173c-11.797,0-21.333-9.536-21.333-21.333c0-11.797,9.536-21.333,21.333-21.333h41.173 c11.776,0,21.333,9.536,21.333,21.333C425.173,406.464,415.616,416,403.84,416z M426.667,330.667 c0,11.797-9.536,21.333-21.333,21.333H234.667c-11.797,0-21.333-9.536-21.333-21.333V224c0-6.699,3.157-13.035,8.533-17.067 l85.333-64c7.595-5.696,18.005-5.696,25.6,0l85.333,64c5.376,4.032,8.533,10.368,8.533,17.067V330.667z',
  ],
};

export default faWsBlueprint;
