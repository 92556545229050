import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { floorToLatest5Minutes } from 'utils/date';
import { parseSensorWeatherObservation } from 'utils/types/SensorWeatherObservation';

export interface HookParams {
  fromTimestamp?: Date;
  toTimestamp?: Date;
  includeHumidity?: boolean;
  includePrecip?: boolean;
  includeTemperature?: boolean;
  includeWind?: boolean;
  limit?: number;
}

const useSensorWeatherObservations = (
  sensorId?: string,
  {
    fromTimestamp,
    toTimestamp,
    includeHumidity = false,
    includePrecip = false,
    includeTemperature = false,
    includeWind = false,
    limit = 50000,
  }: HookParams = {},
) => {
  if (fromTimestamp) {
    fromTimestamp = floorToLatest5Minutes(fromTimestamp);
  }
  if (toTimestamp) {
    toTimestamp = floorToLatest5Minutes(toTimestamp);
  }

  const queryKey = [
    'sensorWeatherObservations',
    sensorId,
    fromTimestamp,
    toTimestamp,
    includeHumidity,
    includePrecip,
    includeTemperature,
    includeWind,
    limit,
  ];

  // Get
  const { data: sensorWeatherObservations, isPending } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/sensors/${sensorId}/weather`, {
        params: {
          from_timestamp: fromTimestamp,
          to_timestamp: toTimestamp,
          include_humidity: includeHumidity,
          include_precip: includePrecip,
          include_temperature: includeTemperature,
          include_wind: includeWind,
          limit,
        },
      }),
    select: (sensorWeatherObservations: any[]) =>
      sensorWeatherObservations.map(observation => parseSensorWeatherObservation(observation)),
    enabled: !!sensorId && !!fromTimestamp && !!toTimestamp,
  });

  return {
    sensorWeatherObservations,
    isPending,
  };
};

export default useSensorWeatherObservations;
