import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import useCurrentUser from 'utils/hooks/use-current-user';
import User, { parseUser } from 'utils/types/User';

type ActiveUserDetails = {
  user: User;
  last_active_date: Date;
};

const useAdminInsightsActiveUsers = ({
  fromTimestamp,
  toTimestamp,
}: {
  fromTimestamp?: Date;
  toTimestamp?: Date;
}) => {
  const queryKey = ['adminInsightsActiveUsers', fromTimestamp, toTimestamp];

  const { isAdmin } = useCurrentUser();

  const { data: activeUserDetails, isPending } = useQuery({
    queryKey,
    queryFn: () =>
      api.get('/admin/insights/active_users', {
        params: {
          from_timestamp: fromTimestamp,
          to_timestamp: toTimestamp,
        },
      }),
    select: (activeUserDetails: any[]) =>
      activeUserDetails.map((activeUserDetail: any) => ({
        user: parseUser(activeUserDetail.user),
        last_active_date: new Date(activeUserDetail.last_active_date),
      })) as ActiveUserDetails[],
    enabled: !!fromTimestamp && !!toTimestamp && isAdmin,
  });

  return { activeUserDetails, isPending };
};

export default useAdminInsightsActiveUsers;
