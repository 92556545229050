import { createColumnHelper } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table, TableLink } from 'components';
import AlarmType from 'utils/enums/AlarmType';
import { parseI18LanguageToLocale } from 'utils/enums/Locale';
import { sensorId2NameFunc, gatewayId2NameFunc } from 'utils/formatting';
import { useSelectedCustomer } from 'utils/hooks';
import { useCustomerGateways, useCustomerSensors, useCustomerAlarmRules } from 'utils/hooks/data';
import { getAlarmTypeText } from 'utils/sensor/texts';
import { formatFunctionDate } from 'utils/tables/format-functions';
import Alarm from 'utils/types/Alarm';
import { alarmRuleId2LocalizedNameFunc } from 'utils/types/AlarmRule';

export const AlarmsTable: React.FC<{
  data: Alarm[];
  onClick?: (alarm: Alarm) => void;
  columnsToShow?: (keyof Alarm)[];
  isPending?: boolean;
}> = ({
  data,
  onClick,
  columnsToShow = ['type', 'sensor_id', 'gateway_id', 'created_date'],
  isPending,
}) => {
  const { customerId } = useSelectedCustomer();
  const { gateways } = useCustomerGateways(customerId, {
    enableGet: columnsToShow?.includes('gateway_id'),
  });
  const { sensors } = useCustomerSensors(customerId);
  const { alarmRules } = useCustomerAlarmRules(customerId);
  const noAlarms = data.length === 0 || !data;

  const { t, i18n } = useTranslation('components');
  const currentLocale = parseI18LanguageToLocale(i18n.language);

  const columns = useMemo(() => {
    const gatewayId2Name = gatewayId2NameFunc(gateways);
    const sensorId2Name = sensorId2NameFunc(sensors);
    const alarmRuleId2LocalizedName = alarmRuleId2LocalizedNameFunc(currentLocale, alarmRules);

    const columnHelper = createColumnHelper<Alarm>();

    return [
      columnHelper.accessor('sensor_id', {
        id: 'sensor_id',
        header: () => t('tables.AlarmsTable.columns.sensor_id.text'),
        enableColumnFilter: false,
        meta: {
          hidden: !columnsToShow.includes('sensor_id'),
        },
        cell: ({ getValue }) => {
          const value = getValue();
          if (!value) return null;
          const text = sensorId2Name[value] || value;
          return (
            <TableLink
              name={text}
              url={`/user/sensors/${value}`}
              datatip={t('tables.AlarmsTable.columns.sensor_id.dataTip.1')}
              externalDatatip={t('tables.AlarmsTable.columns.sensor_id.dataTip.2')}
            />
          );
        },
      }),

      columnHelper.accessor('type', {
        id: 'type',
        header: () => t('tables.AlarmsTable.columns.type.text'),
        enableColumnFilter: !noAlarms,
        enableGlobalFilter: true,
        filterFn: (row, columnId, filterValue) => row.getValue(columnId) === Number(filterValue),
        meta: {
          filterVariant: 'select',
          transform: getAlarmTypeText,
          hidden: !columnsToShow.includes('type'),
        },
        cell: ({ getValue, row: { original } }) => {
          const value = getValue();
          const customAlarmRuleName =
            value === AlarmType.CustomAlarmRule && !!original.alarm_rule_id
              ? alarmRuleId2LocalizedName[original.alarm_rule_id]
              : undefined;
          const alarmRuleText = getAlarmTypeText(value);
          const name = customAlarmRuleName
            ? `${customAlarmRuleName} (${alarmRuleText})`
            : alarmRuleText;
          return <>{name}</>;
        },
      }),

      columnHelper.accessor('gateway_id', {
        id: 'gateway_id',
        header: () => t('tables.AlarmsTable.columns.gateway_id.text'),
        enableColumnFilter: false,
        meta: {
          hidden: !columnsToShow.includes('gateway_id'),
        },
        cell: ({ getValue }) => {
          const value = getValue();
          if (!value) return null;
          const text = gatewayId2Name[value] || value;
          return (
            <TableLink
              name={text}
              url={`/user/gateways/${value}`}
              datatip={t('tables.AlarmsTable.columns.gateway_id.dataTip.1')}
              externalDatatip={t('tables.AlarmsTable.columns.gateway_id.dataTip.2')}
            />
          );
        },
      }),

      columnHelper.accessor('created_date', {
        id: 'created_date',
        header: () => t('tables.AlarmsTable.columns.created_date.text'),
        enableColumnFilter: false,
        sortingFn: 'datetime',
        meta: {
          hidden: !columnsToShow.includes('created_date'),
        },
        cell: ({ getValue }) => formatFunctionDate(getValue()),
      }),
    ];
  }, [alarmRules, columnsToShow, currentLocale, gateways, noAlarms, sensors, t]);

  return (
    <Table
      data={data}
      columns={columns}
      loading={isPending}
      showSearchBar={!noAlarms}
      showTogglingFilters={!noAlarms}
      noDataPlaceholder={t('tables.AlarmsTable.noDataIndication')}
      placeholder={t('tables.AlarmsTable.search.placeholder')}
      pageSize={5}
      onClick={onClick}
      sortBy={[
        {
          id: 'created_date',
          desc: true,
        },
      ]}
    />
  );
};
