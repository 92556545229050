import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SensorsSelectInput, Warning } from 'components';
import LoadingCard from 'components/cards/LoadingCard';
import { unqiueArrayByStringProperty } from 'utils/arrays';
import Permission from 'utils/enums/Permission';
import {
  useBlueprintSensors,
  useUtilsBulkSensorPositionCheck,
  useUtilsPermissionsSensors,
} from 'utils/hooks/data';
import Sensor from 'utils/types/Sensor';

export const BlueprintAttachedSensorsField: React.FC<{
  blueprintId: string;
  availableSensors?: Sensor[];
  disabled?: boolean;
}> = ({ blueprintId, availableSensors, disabled }) => {
  const [isUpdatingSensors, setIsUpdatingSensors] = useState(false);

  const { t } = useTranslation('components');

  const {
    sensors: blueprintSensors,
    addSensorsToBlueprintById,
    removeSensorsFromBlueprintById,
    isPending: isPendingSensors,
  } = useBlueprintSensors(blueprintId);

  // Combine available sensors and blueprint sensors
  const availableSensorsAndBlueprintSensors =
    isPendingSensors || !availableSensors
      ? undefined
      : unqiueArrayByStringProperty(
          [...(blueprintSensors || []), ...(availableSensors || [])],
          'id',
        );

  const { sensorHasBlueprintPosition, isPending: isPendingHasPosition } =
    useUtilsBulkSensorPositionCheck(availableSensorsAndBlueprintSensors);
  const { sensorPermissions, isPending: isPendingSensorPermissions } = useUtilsPermissionsSensors(
    availableSensorsAndBlueprintSensors,
    Permission.Edit,
  );

  const blueprintSensorIds = blueprintSensors?.map(sensor => sensor.id);

  // Sensors to select are either:
  // 1. Sensor without any blueprint position
  // 2. Sensor attached to the existing blueprint
  const sensorsToSelect = availableSensorsAndBlueprintSensors?.filter(
    sensor => blueprintSensorIds?.includes(sensor.id) || !sensorHasBlueprintPosition?.[sensor.id],
  );
  const sensorsToSelectIds = sensorsToSelect?.map(sensor => sensor.id);

  const initialSelectedSensorIds = blueprintSensors?.map(sensor => sensor.id);
  const sensorIdsWithoutSufficientPermission = !sensorPermissions
    ? []
    : sensorsToSelectIds?.filter(sensorId => !sensorPermissions?.[sensorId]);
  const sensorIdToTooltip = sensorIdsWithoutSufficientPermission
    ? Object.fromEntries(
        sensorIdsWithoutSufficientPermission.map(sensorId => [
          sensorId,
          t('blueprints.BlueprintAttachedSensorsField.notSufficientPermission'),
        ]),
      )
    : undefined;
  const isPending = isPendingSensors || isPendingHasPosition || isPendingSensorPermissions;

  if (isPending) return <LoadingCard count={2} />;

  const onBlur = async (sensorIds: string[]) => {
    setIsUpdatingSensors(true);

    // Determine delete and newly added IDs
    // Added and deleted sensors IDs can only be any of the available sensors
    const newSensorIds = sensorIds
      .filter(sensorId => !blueprintSensorIds?.includes(sensorId))
      .filter(sensorId => sensorsToSelectIds?.includes(sensorId));
    const deletedSensorIds = (blueprintSensorIds || [])
      .filter(sensorId => !sensorIds.includes(sensorId))
      .filter(sensorId => sensorsToSelectIds?.includes(sensorId));

    // Add each new added sensor
    try {
      if (newSensorIds.length > 0) {
        await addSensorsToBlueprintById(newSensorIds);
      }
      // Delete each removed sensor
      if (deletedSensorIds.length > 0) {
        await removeSensorsFromBlueprintById(deletedSensorIds);
      }
    } catch (error) {}

    setIsUpdatingSensors(false);
  };

  return (
    <>
      <SensorsSelectInput
        onBlur={onBlur}
        sensorsToSelect={sensorsToSelect}
        initialSelectedSensorIds={initialSelectedSensorIds}
        disabledSensorIds={sensorIdsWithoutSufficientPermission}
        disabled={disabled}
        isPending={isUpdatingSensors}
        sensorIdToTooltip={sensorIdToTooltip}
      />

      {sensorsToSelect?.length === 0 && (
        <Warning className="my-3">
          {t('blueprints.BlueprintAttachedSensorsField.noSensors')}
        </Warning>
      )}
    </>
  );
};
