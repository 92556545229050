import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import Sensor from 'utils/types/Sensor';

export const useUtilsBulkSensorPositionCheck = (sensors?: Sensor[]) => {
  const sensorIds = (sensors || []).map(sensor => sensor.id);

  const queryKey = ['useUtilsBulkSensorPositionCheck', sensorIds];

  const { data: sensorHasBlueprintPosition, isPending } = useQuery({
    queryKey,
    queryFn: async () => api.post('/utils/bulk_sensor_position_check', sensorIds),
    select: (sensorHasBlueprintPosition: any) =>
      sensorHasBlueprintPosition as { [key: string]: boolean },
  });

  return {
    sensorHasBlueprintPosition,
    isPending,
  };
};
