import useCustomer from 'utils/hooks/data/customers/use-customer';
import useSensor from 'utils/hooks/data/sensors/use-sensor';
import useCurrentUser from 'utils/hooks/use-current-user';

const useSensorCustomer = (sensorId?: string) => {
  const { isAdmin } = useCurrentUser();
  const { sensor } = useSensor(sensorId);
  return useCustomer(isAdmin ? sensor?.customer_id : undefined);
};

export default useSensorCustomer;
