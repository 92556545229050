import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import { useMemo, useRef } from 'react';

import api from 'utils/api';
import HookOptions from 'utils/types/HookOptions';
import UserInvitation, {
  UserInvitationFormValues,
  parseUserInvitation,
} from 'utils/types/UserInvitation';

const useCustomerUserInvitations = (
  customerId?: string,
  { enableGet = true }: HookOptions = {},
) => {
  const queryKey = ['customerUserInvitations', customerId];

  const queryClient = useQueryClient();

  const previousCustomerUserInvitationsRef = useRef<UserInvitation[] | undefined>();

  // User Invitations
  const { data: fectchedUserInvitations, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/customers/${customerId}/user_invitations`),
    select: (data: any[]) => data.map(data => parseUserInvitation(data)),
    enabled: !!customerId && enableGet,
    refetchInterval: 5000,
  });

  const userInvitations = useMemo(() => {
    if (!isEqual(previousCustomerUserInvitationsRef.current, fectchedUserInvitations)) {
      previousCustomerUserInvitationsRef.current = fectchedUserInvitations;
      return fectchedUserInvitations || [];
    }
    return previousCustomerUserInvitationsRef.current;
  }, [fectchedUserInvitations]);

  // Create
  const { mutateAsync: createUserInvitation } = useMutation({
    mutationFn: async (values: UserInvitationFormValues) => {
      if (values.name === '') {
        values.name = undefined;
      }
      if (values.custom_text === '') {
        values.custom_text = undefined;
      }
      const userInvitationsRaw: any[] = await api.post(
        `/customers/${customerId}/user_invitations`,
        values,
      );
      return userInvitationsRaw.map(parseUserInvitation);
    },
    onSuccess: userInvitations => {
      queryClient.setQueryData(queryKey, userInvitations);
    },
  });

  return {
    userInvitations,
    createUserInvitation,
    isPending,
  };
};

export default useCustomerUserInvitations;
