import useFiles from 'utils/hooks/data/use-files';
import GatewayFile, { parseGatewayFile } from 'utils/types/GatewayFile';
import HookOptions from 'utils/types/HookOptions';

const useGatewayFiles = (gatewayId?: string, { enableGet = true }: HookOptions = {}) =>
  useFiles<GatewayFile>({
    queryKey: ['gatewayFiles', gatewayId],
    endpoint: `/gateways/${gatewayId}/files`,
    enabled: !!gatewayId && enableGet,
    parseFn: parseGatewayFile,
    invalidateQueryKeys: [
      ['gatewayFiles', gatewayId],
      ['gatewayPrimaryFile', gatewayId, false],
      ['gatewayPrimaryFile', gatewayId, true],
    ],
  });

export default useGatewayFiles;
