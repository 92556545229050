import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { parseSensorGroupFile } from 'utils/types/SensorGroupFile';

const useSensorGroupPrimaryFile = (
  sensorGroupId?: string,
  useThumbnail: boolean = false,
  enableGet: boolean = true
) => {
  const queryKey = ['sensorGroupPrimaryFile', sensorGroupId, useThumbnail];

  // Gateway File
  const { data: file, isPending } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/sensor_groups/${sensorGroupId}/files/primary/`, {
        params: { use_thumbnail: useThumbnail },
        _disableErrorNotifications: true,
        _disableRedirects: true,
      }),
    select: (file: any) => {
      if (!file) return null;

      return parseSensorGroupFile(file);
    },
    enabled: enableGet && !!sensorGroupId,
    meta: {
      disableLoading: true,
    },
  });

  return {
    file,
    isPending,
  };
};

export default useSensorGroupPrimaryFile;
