import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import api from 'utils/api';
import useBlueprint from 'utils/hooks/data/blueprints/use-blueprint';
import { parseBlueprintPosition } from 'utils/types';

const useBlueprintPositions = (blueprintId?: string, disableLoading = true) => {
  const queryKey = ['blueprintPositions', blueprintId];

  const queryClient = useQueryClient();

  const { blueprint } = useBlueprint(blueprintId!);

  // Blueprint Postition - Get
  const { data: blueprintPositions, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/blueprints/${blueprintId}/positions`),
    select: (blueprintPositions: any[]) => blueprintPositions.map(parseBlueprintPosition),
    enabled: !!blueprintId,
    meta: {
      disableLoading,
    },
  });

  // Update by sensor ID
  const { mutateAsync: updateSensorPositionById } = useMutation({
    mutationFn: async ({
      sensorId,
      ...props
    }: {
      sensorId: string;
      position_x: number | null;
      position_y: number | null;
    }) => {
      const blueprintPositionsRaw: any[] = await api.patch(
        `/blueprints/${blueprintId}/sensors/${sensorId}/position`,
        props,
      );
      return blueprintPositionsRaw.map(parseBlueprintPosition);
    },
    onSuccess: async (blueprintPositions, { sensorId }) => {
      queryClient.setQueryData(queryKey, blueprintPositions);

      // Update customer blueprint positions directly in cache
      queryClient.invalidateQueries({
        queryKey: ['customerBlueprintPositions', blueprint?.customer_id],
      });
      queryClient.invalidateQueries({
        queryKey: ['sensorGroupBlueprints'],
      });
      queryClient.invalidateQueries({
        queryKey: ['sensorBlueprint', sensorId],
      });
    },
    meta: {
      disableLoading,
    },
  });

  return {
    updateSensorPositionById,
    blueprintPositions,
    isPending,
  };
};

export default useBlueprintPositions;
