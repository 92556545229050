import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Table, TableLink, RoleSelectInput } from 'components';
import { faWsUser, faWsUsers, faWsGroup } from 'components/icons';
import AccessLinkType, { getAccessLinkTypeText } from 'utils/enums/AccessLinkType';
import Role from 'utils/enums/Role';
import { notificationWarningConfirm } from 'utils/notifications';
import { sortingFnBoolean } from 'utils/tables/sort-functions';
import { boolean2text } from 'utils/texts/common';
import { getRoleText } from 'utils/texts/Role';
import AccessLink from 'utils/types/AccessLink';

type AccessLinkTableData = {
  id: string;
  email?: string;
  receive_alarms_email?: boolean;
} & AccessLink;

interface Props {
  accessLinks: AccessLink[];
  isPending?: boolean;
  onDelete?: (accessLink: AccessLink) => Promise<void>;
  onUpdate?: (accessLink: AccessLink, role: Role) => Promise<void>;
  usersOnly?: boolean;
  customersOnly?: boolean;
  showUserEmails?: boolean;
  showUserReceiveAlarmsEmail?: boolean;
}

export const AccessLinksTable: React.FC<Props> = ({
  accessLinks,
  isPending,
  onDelete,
  onUpdate,
  usersOnly = false,
  customersOnly = false,
  showUserEmails = false,
  showUserReceiveAlarmsEmail = false,
}) => {
  if (usersOnly && customersOnly) {
    throw Error('AccessLinksTable: usersOnly and customersOnly cannot be true at the same time.');
  }

  const { t } = useTranslation('components');

  const data = useMemo(
    () =>
      accessLinks
        .map((accessLink, idx) => ({
          id: String(idx),
          email: accessLink.user?.email,
          receive_alarms_email: accessLink.user?.receive_alarms_email,
          ...accessLink,
        }))
        .sort((a, b) => {
          const nameA =
            a.type === AccessLinkType.Customer
              ? a.customer?.name?.toLowerCase() || ''
              : a.user?.full_name?.toLowerCase() || '';
          const nameB =
            b.type === AccessLinkType.Customer
              ? b.customer?.name?.toLowerCase() || ''
              : b.user?.full_name?.toLowerCase() || '';

          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        }) as AccessLinkTableData[],
    [accessLinks],
  );

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<AccessLinkTableData>();

    return [
      columnHelper.accessor('user', {
        id: 'user',
        header: () =>
          usersOnly
            ? t('tables.AccessLinksTable.columns.name.text.1')
            : customersOnly
              ? t('tables.AccessLinksTable.columns.name.text.2')
              : t('tables.AccessLinksTable.columns.name.text.3'),
        enableColumnFilter: false,
        sortingFn: (rowA: any, rowB: any) => {
          const nameA =
            rowA.original.type === AccessLinkType.Customer
              ? rowA.original?.customer?.name!
              : rowA.original?.user.full_name!;

          const nameB =
            rowB.original.type === AccessLinkType.Customer
              ? rowB.original?.customer?.name!
              : rowB.original?.user.full_name!;

          return nameA.localeCompare(nameB);
        },
        cell: ({ getValue, row: { original } }) => {
          const name =
            original.type === AccessLinkType.Customer
              ? original?.customer?.name!
              : getValue()?.full_name!;
          const url =
            original.type === AccessLinkType.Customer
              ? `/user/organizations/${original?.customer?.id!}`
              : `/user/users/${original?.user?.id!}`;
          const datatip =
            original.type === AccessLinkType.Customer
              ? t('tables.AccessLinksTable.user.datatip.1')
              : t('tables.AccessLinksTable.user.datatip.2');
          return (
            <div className="flex justify-between pt-[5px]">
              <div className="flex items-center">
                <Avatar
                  name={name}
                  size="30px"
                  title={name}
                  round={original.type === AccessLinkType.User}
                />
                <TableLink className="ml-2" name={name} url={url} datatip={datatip} />
              </div>
              {original.customer && original.customer.logo_url && (
                <Link to={url}>
                  <img
                    className="mt-[5px]"
                    style={{ height: '20px', width: 'auto' }}
                    src={original.customer.logo_url}
                    alt={original.customer.name}
                    data-tooltip-content={datatip}
                    data-tooltip-id="route-tooltip"
                  />
                </Link>
              )}
            </div>
          );
        },
      }),

      columnHelper.accessor('type', {
        id: 'type',
        header: () => t('tables.AccessLinksTable.columns.type.text'),
        enableColumnFilter: false,
        meta: {
          hidden: usersOnly || customersOnly,
        },
        cell: ({ getValue }) => (
          <div className="mt-3">
            <FontAwesomeIcon
              className="mr-2"
              icon={getValue() === AccessLinkType.Customer ? faWsUsers : faWsUser}
              size="lg"
              width="22px"
            />
            {getAccessLinkTypeText(getValue())}
          </div>
        ),
      }),

      columnHelper.accessor('email', {
        id: 'email',
        header: () => t('tables.AccessLinksTable.columns.email.text'),
        size: 100,
        enableColumnFilter: false,
        meta: {
          hidden: !showUserEmails,
        },
        cell: ({ getValue }) => <div className="mt-3">{getValue()}</div>,
      }),

      columnHelper.accessor('receive_alarms_email', {
        id: 'receive_alarms_email',
        header: () => t('tables.AccessLinksTable.columns.receive_alarms_email.text'),
        size: 70,
        enableColumnFilter: false,
        sortingFn: sortingFnBoolean,
        meta: {
          hidden: !showUserReceiveAlarmsEmail,
        },
        cell: ({ getValue }) => <div className="mt-3">{boolean2text(getValue())}</div>,
      }),

      columnHelper.accessor('role', {
        id: 'role',
        header: () => t('tables.AccessLinksTable.columns.role.text'),
        enableColumnFilter: false,
        cell: ({ getValue, row: { original, id } }) => (
          <div className="flex justify-between gap-x-4">
            {onUpdate && !original.inherited_from_group ? (
              <RoleSelectInput
                id={id}
                className="flex-grow"
                onSelect={role => {
                  onUpdate(original, role);
                }}
                initialSelectedOption={getValue()}
                isPending={isPending}
              />
            ) : (
              <div className="my-3">{getRoleText(getValue())}</div>
            )}

            {original.inherited_from_group ? (
              <Link to={`/user/groups/${original.inherited_from_group.id}/access`}>
                <FontAwesomeIcon
                  className="cursor-pointer mt-3 text-brand-green hover:text-blue-500"
                  icon={faWsGroup}
                  size="lg"
                  data-tooltip-content={t(
                    'tables.AccessLinksTable.columns.role.dataTip.inherited',
                    {
                      name: original.inherited_from_group.name,
                    },
                  )}
                  data-tooltip-id="route-tooltip"
                />
              </Link>
            ) : onDelete ? (
              <FontAwesomeIcon
                className="text-brand-gray-light-2 cursor-pointer mt-2 hover:text-brand-orange relative -top-1"
                icon={faXmark}
                size="2x"
                onClick={async event => {
                  event.stopPropagation();

                  const confirmed = await notificationWarningConfirm();
                  if (!confirmed) return;
                  await onDelete(original);
                }}
              />
            ) : (
              <></>
            )}
          </div>
        ),
      }),
    ];
  }, [
    customersOnly,
    isPending,
    onDelete,
    onUpdate,
    showUserEmails,
    showUserReceiveAlarmsEmail,
    t,
    usersOnly,
  ]);

  return (
    <Table
      data={data}
      columns={columns}
      sortBy={[
        {
          id: 'user',
          desc: false,
        },
      ]}
      pageSize={5}
    />
  );
};
