enum SensorTagComplementaryElement {
  // Terrain
  // FencesPergolaAndForeclosure = 301
  // ComplementaryStairsRampsInTerrain = 302
  // Transitions = 303

  // External walls
  ExternalDoors = 311,
  ExternalWindows = 312,
  // ExternalDoorsCurtainWallSystems = 313,
  // ExternalWindowOpeningsCurtainWallSystems = 314,
  // ExternalPanelsSpandrelsMullionProfilesCurtainWallSystems = 315,
  // ExternalPortsRevolvingDoors = 316,
  // ExternalWallAndScreeningWalls = 317,
  // ExternalOtherSecondaryElementsToExternalWalls = 319,

  // Interior walls
  InteriorDoors = 321,
  InteriorWindowsHatchesAndTrapdoors = 322,
  // InteriorDoorsCurstanWallSystems = 323,
  // InteriorWindowOpeningsCurtainWallSystems = 324,
  // InteriorWindowsSpandrelsMullionsCurtainWallsSystems = 326,
  // InteriorMoveableInsideWalls = 327,
  // InteriorScreeningWalls = 328,
  // InteriorSecondaryElementsToInteriorWalls = 329,

  // Slabs
  // FloorFinishesRaisedOnJoists = 331,
  // FloatingFloors = 332,
  // HatchesGratesMatWellsAndFrames = 333,
  // InSituFloors = 334,
  // RaisedFinishes = 335,
  // SlabsSoundAbsorbers = 336,
  // OtherSlapsComplementaries = 339,

  // Stairs and Ramps
  // RailingsGuardrailsAndHandrailsExterior = 341,
  // RailingsGuardrailsAndHandrailsInterior = 342,
  // GratingsMatWellsAndFrames = 343,
  // OtherStairComplementaries = 349,

  // Ceilings
  // InteriorSuspendedCeilings = 351,
  // ExteriorSuspendedCeilings = 352,
  // InteriorSurfaceMountedCeilings = 353,
  // ExteriorSurfaceMountedCeilings = 354,
  // InteriorCeilingSkirts = 355,
  // ExteriorCeilingSkirts = 356,
  // CeilingsSoundAbsorbers = 357,

  // Balconies
  // HandrailsParapets = 361,

  // Roof
  Dormers = 371,
  SkylightsSmokeHatchesAndRoofAccessHatches = 372,
  // SunscreenForSkylights = 373,
  // MansafeSystems = 374,
  // FireParapetWalls = 375,
  // Flashings = 376,
  // CoveringsOnFinishedRoofs = 377,
  RoofOutlet = 378,
  SoilPipeVent = 379,
  VentilationOpening = 380,
  OverpressureVentilation = 381,
  BMUFitting = 382,

  // Custom
  NotRelevant = 501,
  Other = 502,
}

export default SensorTagComplementaryElement;
