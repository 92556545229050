import useFile from 'utils/hooks/data/use-file';
import HookOptions from 'utils/types/HookOptions';
import SensorGroupFile, { parseSensorGroupFile } from 'utils/types/SensorGroupFile';

const useSensorGroupFile = (sensorGroupId?: string, fileId?: string, hookOptions?: HookOptions) =>
  useFile<SensorGroupFile>({
    queryKey: ['sensorGroupFiles', sensorGroupId, fileId],
    endpoint: `/sensor_groups/${sensorGroupId}/files/${fileId}`,
    enabled: !!sensorGroupId && !!fileId,
    parseFn: parseSensorGroupFile,
    invalidateQueryKeys: [
      ['sensorGroups', sensorGroupId],
      ['sensorGroupFiles', sensorGroupId],
      ['sensorGroupPrimaryFile', sensorGroupId],
    ],
    hookOptions,
  });

export default useSensorGroupFile;
