import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BlueprintCanvasContext } from 'components/BlueprintCanvas/components/BlueprintCanvasContext';
import Carousel from 'components/Carousel';
import InfoText from 'components/InfoText';
import SensorIcon from 'images/sensor-icon-2.png';
import { chunkify } from 'utils/arrays';
import { sm, md } from 'utils/breakpoints';
import { sensorId2NameFunc } from 'utils/formatting';
import { useWindowSize } from 'utils/hooks';
import { useBlueprintPositions, useBlueprintSensors } from 'utils/hooks/data';

export const SensorsCarousel: React.FC<{
  blueprintId: string;
  onlyEnableSensorIds?: string[];
}> = ({ blueprintId, onlyEnableSensorIds }) => {
  const { t } = useTranslation('components');

  const [windowWidth] = useWindowSize();

  const { activeBlueprintPosition, setActiveBlueprintPosition } =
    useContext(BlueprintCanvasContext);

  const { sensors } = useBlueprintSensors(blueprintId);
  const { blueprintPositions } = useBlueprintPositions(blueprintId);

  const sensorId2Name = sensorId2NameFunc(sensors);

  const blueprintPositionChunks = useMemo(() => {
    const sensorIdsToPutFirst = onlyEnableSensorIds ? onlyEnableSensorIds : [];
    const positions = (blueprintPositions || []).sort((a, b) =>
      sensorIdsToPutFirst.includes(a.sensor_id) ? -1 : 1,
    );

    const chunkSize = windowWidth < sm ? 4 : windowWidth < md ? 6 : 8;
    return chunkify(positions, chunkSize);
  }, [blueprintPositions, onlyEnableSensorIds, windowWidth]);

  const enableSensorIds = Array.isArray(onlyEnableSensorIds)
    ? onlyEnableSensorIds
    : (sensors || []).map(sensor => sensor.id);

  return (
    <>
      {blueprintPositions && blueprintPositions.length > 0 ? (
        <>
          <InfoText
            className="text-center my-2"
            text={t('blueprints.BlueprintSensorsBox.subTitle')}
            data-tooltip-content={t('blueprints.BlueprintSensorsBox.infoIcon')}
            data-tooltip-id="route-tooltip"
          />
        </>
      ) : (
        <InfoText
          className="text-center my-3"
          text={t('blueprints.BlueprintSensorsBox.noSensorsSubTitle')}
          data-tooltip-content={t('blueprints.BlueprintSensorsBox.noSensorsInfoIcon')}
          data-tooltip-id="route-tooltip"
        />
      )}

      <Carousel
        wrapperClassName="h-28"
        elements={blueprintPositionChunks.map((blueprintPositionChunk, indexOuter) => (
          <div key={indexOuter} className="flex justify-center gap-x-5">
            {blueprintPositionChunk.map((blueprintPosition, index) => {
              const sensorName = sensorId2Name[blueprintPosition.sensor_id];
              const enabled = enableSensorIds.includes(blueprintPosition.sensor_id);
              const active = activeBlueprintPosition?.id === blueprintPosition.id;

              return (
                <div
                  key={index}
                  className={classNames('flex flex-col gap-1 p-3 rounded', {
                    shadow: active,
                    'opacity-50': !enabled,
                  })}
                >
                  <img
                    className={classNames('block mx-auto w-auto h-[60px]', {
                      'cursor-grab': enabled && !blueprintPosition.isPositionDefined,
                      'cursor-grabing': active,
                    })}
                    src={SensorIcon}
                    alt={`Sensor ${sensorName}`}
                    data-tooltip-content={`Sensor ${sensorName}`}
                    data-tooltip-id="route-tooltip"
                    draggable={enabled}
                    onDragStart={event => {
                      event.dataTransfer.setData('blueprint-position-id', blueprintPosition.id);
                      event.dataTransfer.setData(
                        'blueprint-sensor-id',
                        blueprintPosition.sensor_id,
                      );
                      setActiveBlueprintPosition(blueprintPosition);
                    }}
                    onDragEnd={() => setActiveBlueprintPosition(undefined)}
                    onMouseEnter={() => setActiveBlueprintPosition(blueprintPosition)}
                    onMouseLeave={() => setActiveBlueprintPosition(undefined)}
                  />

                  {blueprintPosition.isPositionDefined ? (
                    <FontAwesomeIcon
                      className="relative text-brand-green-light-1"
                      icon={faCheckCircle}
                      data-tooltip-content={t(
                        'blueprints.BlueprintSensorsBox.dataTip.withPosition',
                      )}
                      data-tooltip-id="route-tooltip"
                    />
                  ) : (
                    <FontAwesomeIcon
                      className="relative text-brand-green"
                      icon={faExclamationCircle}
                      data-tooltip-content={t(
                        'blueprints.BlueprintSensorsBox.dataTip.withoutPosition',
                      )}
                      data-tooltip-id="route-tooltip"
                    />
                  )}
                </div>
              );
            })}
          </div>
        ))}
      />
    </>
  );
};
