import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import api from 'utils/api';
import { parseAlarmNotification } from 'utils/types/AlarmNotification';
import HookOptions from 'utils/types/HookOptions';

type Options = HookOptions & {
  enableUnseen?: boolean;
  enableUnseenCount?: boolean;
};

const useUserAlarmNotifications = (
  userId?: string,
  { enableGet = true, enableUnseen = false, enableUnseenCount = false, limit, offset }: Options = {}
) => {
  const queryClient = useQueryClient();

  // Alarm Notifications - Get
  const {
    data: alarmNotifications,
    isPending,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ['userAlarmNotifications', userId, limit, offset],
    queryFn: () =>
      api.get(`/users/${userId}/alarm_notifications`, {
        params: {
          limit,
          offset,
        },
      }),
    select: (alarmNotifications: any[]) => alarmNotifications.map(parseAlarmNotification),
    enabled: !!userId && enableGet,
  });

  // Alarm Notifications - Get (unseen)
  const { data: alarmNotificationsUnseen } = useQuery({
    queryKey: ['userAlarmNotificationsUnseen', userId, limit, offset],
    queryFn: () =>
      api.get(`/users/${userId}/alarm_notifications`, {
        params: {
          unseen_only: true,
          limit,
          offset,
        },
      }),
    select: (alarmNotifications: any[]) => alarmNotifications.map(parseAlarmNotification),
    enabled: !!userId && enableUnseen,
  });

  // Alarm Notifications - Get (unseen count)
  const { data: unseenCount } = useQuery({
    queryKey: ['userAlarmNotificationsUnseenCount', userId, limit, offset],
    queryFn: () =>
      api.get(`/users/${userId}/alarm_notifications`, {
        params: {
          unseen_only: true,
          count_only: true,
          limit,
          offset,
        },
        _disableErrorNotifications: true,
      }),
    select: (unseenCount: any) => unseenCount as number,
    enabled: !!userId && enableUnseenCount,
    placeholderData: 0,
  });

  // Alarm Notifications - Mutate
  const { mutate } = useMutation<unknown, unknown, any, unknown>({
    mutationFn: props => api.put(`/users/${userId}/alarm_notifications`, props),
    onSuccess: async (result, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: ['userAlarmNotifications', userId],
      });
      queryClient.invalidateQueries({
        queryKey: ['userAlarmNotificationsUnseen', userId],
      });
      queryClient.invalidateQueries({
        queryKey: ['userAlarmNotificationsUnseenCount', userId],
      });
    },
  });

  const markAllRead = () => {
    mutate({ seen_on_web: true });
  };

  return {
    alarmNotifications,
    isPending: isPending || isLoading || isFetching,
    alarmNotificationsUnseen,
    unseenCount,
    markAllRead,
  };
};

export default useUserAlarmNotifications;
