import { t } from 'translations';
import { BlueprintViewStateType } from 'utils/contexts/blueprint-view-state-context';
import MoistureState from 'utils/enums/MoistureState';
import SignalStrengthState from 'utils/enums/SignalStrengthState';

export const getScaleStateBlueprint = (blueprintViewState: BlueprintViewStateType) => {
  if (blueprintViewState === BlueprintViewStateType.Moisture)
    return [
      {
        value: MoistureState.INACTIVE,
        label: 'Inactive',
        getTooltip: () => t('utils:blueprint.scale.gradientDataInactive'),
      },
      {
        value: MoistureState.MOISTURE_0_10,
        label: '0',
        getTooltip: () =>
          t('utils:blueprint.scale.Moisture.gradientDataBetween', {
            value1: '0',
            value2: '10',
          }),
      },
      {
        value: MoistureState.MOISTURE_10_15,
        label: '10',
        getTooltip: () =>
          t('utils:blueprint.scale.Moisture.gradientDataBetween', {
            value1: '10',
            value2: '15',
          }),
      },
      {
        value: MoistureState.MOISTURE_15_20,
        label: '15',
        getTooltip: () =>
          t('utils:blueprint.scale.Moisture.gradientDataBetween', {
            value1: '15',
            value2: '20',
          }),
      },
      {
        value: MoistureState.MOISTURE_20_25,
        label: '20',
        getTooltip: () =>
          t('utils:blueprint.scale.Moisture.gradientDataBetween', {
            value1: '20',
            value2: '25',
          }),
      },
      {
        value: MoistureState.MOISTURE_25_30,
        label: '25',
        getTooltip: () =>
          t('utils:blueprint.scale.Moisture.gradientDataBetween', {
            value1: '25',
            value2: '30',
          }),
      },
      {
        value: MoistureState.MOISTURE_30_35,
        label: '30',
        getTooltip: () =>
          t('utils:blueprint.scale.Moisture.gradientDataBetween', {
            value1: '30',
            value2: '35',
          }),
      },
      {
        value: MoistureState.MOISTURE_35_40,
        label: '35',
        getTooltip: () =>
          t('utils:blueprint.scale.Moisture.gradientDataBetween', {
            value1: '35',
            value2: '40',
          }),
      },
      {
        value: MoistureState.MOISTURE_40_100,
        label: '<40',
        getTooltip: () =>
          t('utils:blueprint.scale.Moisture.gradientDataAbove', {
            value: '40',
          }),
      },
    ];
  else if (blueprintViewState === BlueprintViewStateType.SignalStrength)
    return [
      {
        value: SignalStrengthState.INACTIVE,
        label: 'Inactive',
        getTooltip: () => t('utils:blueprint.scale.gradientDataInactive'),
      },
      {
        value: SignalStrengthState.VERY_GOOD,
        label: '7',
        getTooltip: () => t('utils:blueprint.scale.SignalStrength.VeryGood'),
      },
      {
        value: SignalStrengthState.GOOD,
        label: '8',
        getTooltip: () => t('utils:blueprint.scale.SignalStrength.Good'),
      },
      {
        value: SignalStrengthState.FAIR,
        label: '9',
        getTooltip: () => t('utils:blueprint.scale.SignalStrength.Fair'),
      },
      {
        value: SignalStrengthState.MODERATE,
        label: '10',
        getTooltip: () => t('utils:blueprint.scale.SignalStrength.Moderate'),
      },
      {
        value: SignalStrengthState.POOR,
        label: '11',
        getTooltip: () => t('utils:blueprint.scale.SignalStrength.Poor'),
      },
      {
        value: SignalStrengthState.VERY_POOR,
        label: '12',
        getTooltip: () => t('utils:blueprint.scale.SignalStrength.VeryPoor'),
      },
    ];
  else return [];
};
