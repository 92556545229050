import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import PossibleSensorTags, { possibleSensorTagsEmpty } from 'utils/types/PossibleSensorTags';
import SensorTags from 'utils/types/SensorTags';

const useUtilsPossibleTags = (sensorTags: Partial<SensorTags> = {}) => {
  const queryKey = ['utils', 'possibleTags', sensorTags];

  const {
    data: possibleSensorTags,
    refetch,
    isFetched,
  } = useQuery({
    queryKey,
    queryFn: () => api.post('/utils/possible_tags', sensorTags),
    select: (possibleSensorTags: any) => possibleSensorTags as PossibleSensorTags,
    placeholderData: possibleSensorTagsEmpty,
  });

  return {
    possibleSensorTags: possibleSensorTags || possibleSensorTagsEmpty,
    refetch,
    isFetched,
  };
};

export default useUtilsPossibleTags;
