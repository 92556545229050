import { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

const faWsCrane: IconDefinition = {
  prefix: 'fac' as IconPrefix,
  iconName: 'splat' as IconName,
  icon: [
    13, // width
    24, // height
    [], // ligatures
    '', // unicode (if relevant)
    // svg path data
    'M12.4723 17.7523C12.4743 18.8419 12.1908 19.9129 11.6501 20.8588C11.1094 21.8047 10.3303 22.5924 9.39039 23.1435C8.45051 23.6946 7.38264 23.9898 6.29316 23.9997C5.20368 24.0097 4.13059 23.734 3.18079 23.2002C2.23099 22.6664 1.43762 21.8931 0.879688 20.9573C0.321761 20.0214 0.018753 18.9557 0.000842825 17.8664C-0.0170673 16.777 0.250746 15.7019 0.777608 14.7482C1.30447 13.7946 2.07199 12.9956 3.00374 12.4308C3.29198 12.2365 3.55097 12.002 3.77286 11.7345C3.92904 11.3598 4.05757 10.9742 4.15742 10.5808V8.25261L2.17225 5.63343C2.10544 5.54313 2.06905 5.43395 2.06831 5.32162V2.9311C2.06905 2.81878 2.10544 2.70959 2.17225 2.61929L4.00152 0.207981C4.04874 0.142191 4.11127 0.0888862 4.1837 0.0526695C4.25614 0.0164528 4.3363 -0.00158659 4.41726 0.000109439H8.05502C8.13598 -0.00158659 8.21614 0.0164528 8.28858 0.0526695C8.36101 0.0888862 8.42354 0.142191 8.47076 0.207981L10.2896 2.61929C10.3564 2.70959 10.3928 2.81878 10.3936 2.9311V5.32162C10.3928 5.43395 10.3564 5.54313 10.2896 5.63343L8.31486 8.25261V11.88C8.31419 12.1398 8.26484 12.3971 8.16935 12.6387C8.08646 12.8388 7.97817 13.0275 7.84715 13.2L4.8434 16.2037C4.53327 16.4839 4.31192 16.8487 4.20664 17.2532C4.10137 17.6576 4.11678 18.0841 4.25097 18.4799C4.3738 18.8041 4.57575 19.0924 4.83844 19.3186C5.10113 19.5448 5.41622 19.7017 5.75504 19.775C6.09385 19.8484 6.44562 19.8358 6.77835 19.7385C7.11107 19.6412 7.41417 19.4622 7.66006 19.2178L11.8175 15.0604C12.2383 15.896 12.4622 16.8168 12.4723 17.7523Z',
  ],
};

export default faWsCrane;
