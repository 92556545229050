import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { useCurrentUser } from 'utils/hooks';
import { parseGateway } from 'utils/types/Gateway';

const useAdminGateways = () => {
  const queryKey = ['adminGateways'];

  const { isAdmin } = useCurrentUser();

  // Get Gateways
  const { data: gateways, isPending } = useQuery({
    queryKey,
    queryFn: () =>
      api.get('/admin/gateways', {
        params: { include_demo_data: true },
      }),
    select: (gateways: any[]) => gateways.map(parseGateway),
    enabled: isAdmin,
  });

  return {
    gateways,
    isPending,
  };
};

export default useAdminGateways;
