import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import api from 'utils/api';
import SensorTagPredictions, { sensorTagPredictionsEmpty } from 'utils/types/SensorTagPredictions';
import SensorTags from 'utils/types/SensorTags';

const useSensorTagPredictions = (sensorId?: string, sensorTags: Partial<SensorTags> = {}) => {
  const queryKey = ['sensorTagPredictions', sensorId, sensorTags];

  const { data: sensorTagPredictions } = useQuery({
    queryKey,
    queryFn: () => api.post(`/sensors/${sensorId}/tags/predict`, sensorTags),
    select: (sensorTagPredictions: any) => sensorTagPredictions as SensorTagPredictions,
    placeholderData: sensorTagPredictionsEmpty,
    enabled: !!sensorId,
  });

  return {
    sensorTagPredictions: useMemo(
      () => sensorTagPredictions || sensorTagPredictionsEmpty,
      [sensorTagPredictions]
    ),
  };
};

export default useSensorTagPredictions;
