import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import api from 'utils/api';
import useReport from 'utils/hooks/data/reports/use-report';
import ReportRevision, {
  ReportRevisionFormValues,
  parseReportRevision,
} from 'utils/types/ReportRevision';

const useReportRevisions = (reportId?: string) => {
  const queryKey = ['reportRevisions', reportId];

  const queryClient = useQueryClient();

  const { report } = useReport(reportId);

  // Get
  const {
    data: reportRevisions,
    isPending,
    isFetching,
    isLoading,
  } = useQuery({
    queryKey,
    queryFn: () => api.get(`/reports/${reportId}/revisions`),
    select: (data: any[]) => data.map(parseReportRevision),
    enabled: !!reportId,
    meta: {
      disableLoading: true,
    },
  });

  // Create
  const { mutateAsync: createReportRevision } = useMutation<
    ReportRevision,
    Error,
    ReportRevisionFormValues
  >({
    mutationFn: async (values: ReportRevisionFormValues) => {
      // Use current active locale for the report
      const sensorGroup = await api.post(`/reports/${reportId}/revisions`, values);
      return parseReportRevision(sensorGroup);
    },
    onSuccess: (reportRevision: ReportRevision): void => {
      queryClient.invalidateQueries({
        queryKey,
        exact: false,
      });
      queryClient.invalidateQueries({
        queryKey: ['customerReports', report?.customer_id],

        exact: false,
      });
    },
  });

  return {
    reportRevisions,
    createReportRevision,
    isPending: isPending || isFetching || isLoading,
  };
};

export default useReportRevisions;
