import { useEffect, useState, Dispatch, SetStateAction } from 'react';

import useQueryParam, { QueryParamOptions, KnownQueryKeys } from 'utils/hooks/use-query-param';

export const useQueryParamInteger = (
  key: KnownQueryKeys,
  options: QueryParamOptions = {}
): [number | undefined, Dispatch<SetStateAction<number | undefined>>] => {
  const [valueString, setValueString] = useQueryParam(key, options);

  const parsedInt = parseInt(valueString as string, 10);
  const initialValue = isNaN(parsedInt) ? undefined : parsedInt;

  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    // Parse to integer
    const parsedInt = parseInt(valueString as string, 10);

    if (isNaN(parsedInt)) {
      if (value !== undefined) {
        setValue(undefined);
      }
    } else {
      if (value !== parsedInt) {
        setValue(parsedInt);
      }
    }
  }, [valueString]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (typeof value === 'number') {
      const parsedString = value.toString();
      if (valueString !== parsedString) {
        setValueString(parsedString);
      }
    } else {
      if (valueString !== undefined) {
        setValueString(undefined);
      }
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return [value, setValue];
};

export default useQueryParamInteger;
