import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { allCustomer } from 'utils/types/Customer';
import CustomerFile, { parseCustomerFile } from 'utils/types/CustomerFile';

const useCustomerPrimaryFile = (
  customerId?: string,
  useThumbnail: boolean = false,
  enableGet: boolean = true
) => {
  const queryKey = ['customerPrimaryFile', customerId, useThumbnail];

  // Customer File
  const { data: file, isPending } = useQuery<CustomerFile | null>({
    queryKey,
    queryFn: () =>
      api.get(`/customers/${customerId}/files/primary/`, {
        params: { use_thumbnail: useThumbnail },
        _disableErrorNotifications: true,
        _disableRedirects: true,
      }),
    select: (file: any) => {
      if (!file) return null;

      return parseCustomerFile(file);
    },
    enabled: enableGet && !!customerId && customerId !== allCustomer.id,
    meta: {
      disableLoading: true,
    },
  });

  return {
    file,
    isPending,
  };
};

export default useCustomerPrimaryFile;
