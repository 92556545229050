import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { parseDocumentChange } from 'utils/types/DocumentChange';
import HookOptions from 'utils/types/HookOptions';

const useSensorChanges = (sensorId?: string, { enableGet = true }: HookOptions = {}) => {
  const queryKey = ['sensorChanges', sensorId];

  // Sensor Changes - Get
  const { data: documentChanges, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/sensors/${sensorId}/changes`),
    select: (data: any[]) => data.map(parseDocumentChange),
    enabled: enableGet && !!sensorId,
  });

  return {
    documentChanges,
    isPending,
  };
};

export default useSensorChanges;
