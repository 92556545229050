import { faColumns, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Column, Row } from '@tanstack/react-table';
import classNames from 'classnames';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, TableAction } from 'components';
import {
  SearchBar,
  ToggleColumnsVisibilityModal,
} from 'components/tables/Table/components/TableToolbar/components';

interface TableToolbarProps<T extends {}> {
  colspan: number;
  placeholder?: string;
  columns: Column<T, any>[];
  selectedRows: Row<T>[];
  filtering: string;
  setFiltering: Dispatch<SetStateAction<string>>;
  isFiltersVisible: boolean;
  setIsFiltersVisible: Dispatch<SetStateAction<boolean>>;
  showTogglingColumns?: boolean;
  showTogglingFilters?: boolean;
  showSearchBar?: boolean;
  actions?: TableAction[];
  resetColumnFilters: (defaultState?: boolean) => void;
  resetColumnVisibility: (defaultState?: boolean) => void;
  resetPageIndex: (defaultState?: boolean) => void;
  getIsAllColumnsVisible: () => boolean;
  getToggleAllColumnsVisibilityHandler: () => (event: unknown) => void;
}

export const TableToolbar = <T extends { id?: string }>({
  colspan,
  placeholder,
  columns,
  selectedRows,
  filtering,
  setFiltering,
  isFiltersVisible,
  setIsFiltersVisible,
  showTogglingColumns,
  showTogglingFilters,
  showSearchBar,
  actions,
  resetColumnFilters,
  resetColumnVisibility,
  resetPageIndex,
  getIsAllColumnsVisible,
  getToggleAllColumnsVisibilityHandler,
}: TableToolbarProps<T>) => {
  const { t } = useTranslation();

  const [isToggleColumnsVisibilityModalOpen, setIsToggleColumnsVisibilityModalOpen] =
    useState(false);

  return (
    (actions?.length || showSearchBar || showTogglingColumns || showTogglingFilters) && (
      <div className="bg-brand-gray-light-4">
        <div
          aria-colspan={colspan}
          className="flex flex-col-reverse sm:flex-row justify-between p-4 gap-2"
        >
          {/* Actions buttons */}
          {actions && selectedRows.length > 0 && (
            <div className="flex flex-col sm:flex-row gap-2">
              {actions.map((action, index) => (
                <Button
                  key={index}
                  onClick={() => {
                    action.action(selectedRows.map(row => row.original.id || ''));
                  }}
                  variant="outline-secondary"
                  size="sm"
                >
                  <FontAwesomeIcon icon={action.icon} className="mr-1" />
                  {action.title}
                </Button>
              ))}
            </div>
          )}

          {/* Search bar and icons */}
          <div className="flex gap-2 items-center ml-0 sm:ml-auto">
            {' '}
            {/* Search functionality */}
            {showSearchBar && (
              <div className="flex-grow">
                <SearchBar
                  filtering={filtering}
                  setFiltering={setFiltering}
                  resetPageIndex={resetPageIndex}
                  placeholder={placeholder || t('components:tables.Table.searchField.placeholder')}
                />
              </div>
            )}
            <div className="inline-flex text-brand-gray-light-2 gap-2">
              {/* Show/hide columns filters functionality */}
              {showTogglingFilters && (
                <button
                  onClick={e => {
                    setIsFiltersVisible(isFiltersVisible => !isFiltersVisible);
                    if (isFiltersVisible === true) {
                      resetColumnFilters();
                    }
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <FontAwesomeIcon
                    icon={faFilter}
                    data-tooltip-content={
                      isFiltersVisible
                        ? t('components:tables.Table.toolbar.resetColumnFilters')
                        : t('components:tables.Table.toolbar.showColumnFilters')
                    }
                    data-tooltip-id="route-tooltip"
                    className={classNames('focus:outline-none', {
                      'text-brand-orange': isFiltersVisible,
                      'text-brand-gray-light-2': !isFiltersVisible,
                    })}
                  />
                </button>
              )}

              {/* Show/hide columns functionality */}
              {showTogglingColumns && (
                <div className="flex">
                  <button
                    data-testid="toggle-filters-button"
                    onClick={e => {
                      setIsToggleColumnsVisibilityModalOpen(
                        isToggleColumnsVisibilityModalOpen => !isToggleColumnsVisibilityModalOpen,
                      );
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faColumns}
                      data-tooltip-content={t('components:tables.Table.toolbar.showHideColumns')}
                      data-tooltip-id="route-tooltip"
                    />
                  </button>
                  {isToggleColumnsVisibilityModalOpen && (
                    <div className="absolute">
                      {' '}
                      <ToggleColumnsVisibilityModal
                        columsToToggle={columns.filter(
                          column => column.columnDef.meta?.hideFromToggleModal !== true,
                        )}
                        setIsToggleColumnsVisibilityModalOpen={
                          setIsToggleColumnsVisibilityModalOpen
                        }
                        getIsAllColumnsVisible={() => getIsAllColumnsVisible()}
                        getToggleAllColumnsVisibilityHandler={() =>
                          getToggleAllColumnsVisibilityHandler()
                        }
                        resetColumnVisibility={() => resetColumnVisibility()}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
};
