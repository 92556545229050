import { t } from 'translations';
import { SensorTagVerticalPlacement } from 'utils/enums/sensor-tags';

const getSensorTagVerticalPlacementText = (
  sensorTagVerticalPlacement: SensorTagVerticalPlacement
) => {
  switch (sensorTagVerticalPlacement) {
    case SensorTagVerticalPlacement.Top:
      return t('utils:texts.sensor-tags.getSensorTagVerticalPlacementText.Top');
    case SensorTagVerticalPlacement.Middle:
      return t('utils:texts.sensor-tags.getSensorTagVerticalPlacementText.Middle');
    case SensorTagVerticalPlacement.Bottom:
      return t('utils:texts.sensor-tags.getSensorTagVerticalPlacementText.Bottom');
    default:
      return 'n/a';
  }
};

export const getSensorTagVerticalPlacementTextShort = getSensorTagVerticalPlacementText;

export default getSensorTagVerticalPlacementText;
