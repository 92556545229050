import { BlueprintViewStateType } from 'utils/contexts/blueprint-view-state-context';
import MoistureState from 'utils/enums/MoistureState';
import MoldGrowthRiskState from 'utils/enums/MoldGrowthRiskState';
import SignalStrengthState from 'utils/enums/SignalStrengthState';
import { moisture2State, signalStrengthToState } from 'utils/sensor/state';
import { getSensorTransmission } from 'utils/sensor/transmissions';
import Transmission from 'utils/types/Transmission';

export const brandBlue = '#3782F2';
export const brandGray = '#3E3E3E';
export const brandGrayLight1 = '#6A6A6A';
export const brandGrayLight2 = '#969696';
export const brandGrayLight3 = '#C2C2C2';
export const brandGrayLight4 = '#EEEEEE';
export const brandGreen = '#044749';
export const brandGreenLight1 = '#2B5F60';
export const brandGreenLight2 = '#557F80';
export const brandLime = '#E2F0C3';
export const brandOrange = '#FF825F';
export const brandOrangeLight1 = '#FF9B7F';
export const brandOrangeLight2 = '#FFB49F';

export const moistureStateBetween0And10 = '#a8d88d';
export const moistureStateBetween10And15 = '#7dba5b';
export const moistureStateBetween15And20 = '#528f31';
export const moistureStateBetween20And25 = '#326715';
export const moistureStateBetween25And30 = '#26520e';
export const moistureStateBetween30And35 = '#0D3900';
export const moistureStateBetween35And40 = '#001F00';
export const moistureStateBetween40And100 = '#000600';

export const signalStrengthStateVeryGood = '#057405';
export const signalStrengthStateGood = '#41AC44';
export const signalStrengthStateFair = '#7AC665';
export const signalStrengthStateModerate = '#FFB118';
export const signalStrengthStatePoor = '#FD6518';
export const signalStrengthStateVeryPoor = '#FC1515';

export const activeSensorColor = '#326715';
export const inactiveSensorColor = '#979899';

export type Color = {
  r: number;
  g: number;
  b: number;
  a: number;
};

export const hexToTailwindString = (color: string): string => {
  // This is used since the dynamic coloring in tailwind wasn't working in some cases
  // Add as needed
  if (color === brandBlue) return 'brand-blue';
  else if (color === brandOrange) return 'brand-orange';
  else
    throw new Error(`hex color ${color} not yet implemented in utils.colors.hexToTailwindString`);
};

export const colorToRGBAString = (color: Color, alphaOverride: number | null = null): string =>
  `rgba(${color.r}, ${color.g}, ${color.b}, ${alphaOverride != null ? alphaOverride : color.a})`;

export const hexToColor = (hex: string): Color => {
  //Will fall over if you try to use a shorthand hex like #FFF or #000
  //Doesn't handle alpha channel either, can add if needed
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return { r, g, b, a: 1 };
};

export const getMoistureStateHexColor = (sensorState: MoistureState) => {
  switch (sensorState) {
    case MoistureState.MOISTURE_40_100:
      return moistureStateBetween40And100;
    case MoistureState.MOISTURE_35_40:
      return moistureStateBetween35And40;
    case MoistureState.MOISTURE_30_35:
      return moistureStateBetween30And35;
    case MoistureState.MOISTURE_25_30:
      return moistureStateBetween25And30;
    case MoistureState.MOISTURE_20_25:
      return moistureStateBetween20And25;
    case MoistureState.MOISTURE_15_20:
      return moistureStateBetween15And20;
    case MoistureState.MOISTURE_10_15:
      return moistureStateBetween10And15;
    case MoistureState.MOISTURE_0_10:
      return moistureStateBetween0And10;
    case MoistureState.ACTIVE:
      return activeSensorColor;
    case MoistureState.INACTIVE:
    case MoistureState.INVALID:
      return inactiveSensorColor;
    default:
      return inactiveSensorColor;
  }
};

export const getSignalStrengthStateHexColor = (sensorState: SignalStrengthState) => {
  switch (sensorState) {
    case SignalStrengthState.VERY_GOOD:
      return signalStrengthStateVeryGood;
    case SignalStrengthState.GOOD:
      return signalStrengthStateGood;
    case SignalStrengthState.FAIR:
      return signalStrengthStateFair;
    case SignalStrengthState.MODERATE:
      return signalStrengthStateModerate;
    case SignalStrengthState.POOR:
      return signalStrengthStatePoor;
    case SignalStrengthState.VERY_POOR:
      return signalStrengthStateVeryPoor;
    case SignalStrengthState.INACTIVE:
      return inactiveSensorColor;
    default:
      return inactiveSensorColor;
  }
};

export const getMoldGrowthRiskStateHexColor = (moldGrowthRiskState: MoldGrowthRiskState) => {
  switch (moldGrowthRiskState) {
    case MoldGrowthRiskState.HIGH_RISK:
      return '#EB7514';
    case MoldGrowthRiskState.LOW_RISK:
      return '#528f31';
    case MoldGrowthRiskState.MODERATE_RISK:
      return '#f6b54e';
    case MoldGrowthRiskState.NO_RISK:
      return '#BCBCBC';
    case MoldGrowthRiskState.UNKNOWN:
      return '#BCBCBC';
    case MoldGrowthRiskState.VERY_HIGH_RISK:
      return '#E43030';
    default:
      return '#999999';
  }
};

export const getSensorStateHexColor = <
  T extends MoistureState | SignalStrengthState | MoldGrowthRiskState,
>(
  sensorState: T,
): string => {
  if (Object.values(MoistureState).includes(sensorState as MoistureState)) {
    return getMoistureStateHexColor(sensorState as MoistureState);
  } else if (Object.values(SignalStrengthState).includes(sensorState as SignalStrengthState)) {
    return getSignalStrengthStateHexColor(sensorState as SignalStrengthState);
  } else if (Object.values(MoldGrowthRiskState).includes(sensorState as MoldGrowthRiskState))
    return getMoldGrowthRiskStateHexColor(sensorState as MoldGrowthRiskState);

  return inactiveSensorColor;
};

// https://stackoverflow.com/a/16360660/2538589
// var color1 = '#FF0000';
// var color2 = '#00FF00';
// var ratio = 0.5;
export const calculateMiddleColor = (color1: string, color2: string, ratio: number) => {
  const hex = (x: number) => {
    const y = x.toString(16);
    return y.length === 1 ? `0${x}` : y;
  };

  const r = Math.ceil(
    parseInt(color1.substring(1, 3), 16) * ratio +
      parseInt(color2.substring(1, 3), 16) * (1 - ratio),
  );
  const g = Math.ceil(
    parseInt(color1.substring(3, 5), 16) * ratio +
      parseInt(color2.substring(3, 5), 16) * (1 - ratio),
  );
  const b = Math.ceil(
    parseInt(color1.substring(5, 7), 16) * ratio +
      parseInt(color2.substring(5, 7), 16) * (1 - ratio),
  );

  return `#${hex(r)}${hex(g)}${hex(b)}`;
};

// Generated using https://www.npmjs.com/package/colormap
export const colorMapWinter = [
  '#0000ff',
  '#000df8',
  '#001bf2',
  '#0028eb',
  '#0036e4',
  '#0043de',
  '#0051d7',
  '#005ed0',
  '#006bca',
  '#0079c3',
  '#0086bc',
  '#0094b5',
  '#00a1af',
  '#00aea8',
  '#00bca1',
  '#00c99b',
  '#00d794',
  '#00e48d',
  '#00f287',
  '#00ff80',
];
const nColorMapWinter = colorMapWinter.length;
const value2colorWinter = (value: number, min: number, max: number) => {
  const index = Math.min(
    nColorMapWinter - 1,
    Math.floor(((value - min) / (max - min)) * nColorMapWinter),
  );
  return colorMapWinter[index];
};

export const windSpeed2color = (windSpeed: number) => value2colorWinter(10 - windSpeed, 0, 10);

export const getSensorStateColor = (
  timeTo: Date,
  max: number,
  hours: number,
  sensorId2Transmission: { [key: string]: Transmission[] | undefined } | undefined,
  position: string,
  blueprintViewState: BlueprintViewStateType,
) => {
  const transmission = getSensorTransmission(timeTo, max, hours, sensorId2Transmission, position);

  const sensorState =
    blueprintViewState === BlueprintViewStateType.Moisture
      ? moisture2State(transmission.moisture)
      : signalStrengthToState(transmission.spreading_factor);

  return getSensorStateHexColor(sensorState);
};
