import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import Coordinates from 'utils/types/Coordinates';

type ReverseGeocodingResult = {
  latitude: number;
  longitude: number;
  country_code?: string;
  city?: string;
  country?: string;
};

const useUtilsReverseGeocoding = (coordinates?: Coordinates) => {
  const queryKey = ['utils', 'reverseGeocoding', coordinates];

  const { data: reverseGeocodingResult } = useQuery({
    queryKey,
    queryFn: async () =>
      api.get(`/utils/reverse_geocoding/${coordinates?.latitude}/${coordinates?.longitude}`, {
        _disableErrorNotifications: true,
        _disableRedirects: true,
      }),
    select: (reverseGeocodingResult: any) => reverseGeocodingResult as ReverseGeocodingResult,
    enabled: !!(coordinates && coordinates.latitude && coordinates.longitude),
    meta: {
      disableLoading: true,
    },
  });

  const formattedResult = reverseGeocodingResult
    ? `${reverseGeocodingResult.city}, ${reverseGeocodingResult.country}`
    : undefined;

  return {
    formattedResult,
    reverseGeocodingResult,
  };
};

export default useUtilsReverseGeocoding;
