import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { floorToLatest5Minutes } from 'utils/date';
import { parseSensor } from 'utils/types/Sensor';

const useGatewaySensors = (
  gatewayId: string,
  {
    fromTimestamp,
    toTimestamp,
  }: {
    fromTimestamp: Date;
    toTimestamp: Date;
  }
) => {
  fromTimestamp = floorToLatest5Minutes(fromTimestamp);
  toTimestamp = floorToLatest5Minutes(toTimestamp);

  const queryKey = ['gatewaySensors', gatewayId, fromTimestamp, toTimestamp];

  // Get
  const { data: sensors, isPending } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/gateways/${gatewayId}/sensors`, {
        params: { from_timestamp: fromTimestamp, to_timestamp: toTimestamp },
      }),
    select: (sensors: any[]) => sensors.map(parseSensor),
    enabled: !!gatewayId,
  });

  return {
    sensors,
    isPending,
  };
};

export default useGatewaySensors;
