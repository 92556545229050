import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { useQueryDelete } from 'utils/queries/delete';
import { useQueryUpdate } from 'utils/queries/update';
import HookOptions from 'utils/types/HookOptions';

const useFile = <FileType>({
  queryKey,
  endpoint,
  enabled,
  parseFn,
  invalidateQueryKeys = [],
  hookOptions,
}: {
  queryKey: any[];
  endpoint: string;
  enabled: boolean;
  invalidateQueryKeys?: any[][];
  parseFn: (file: any) => FileType;
  hookOptions?: HookOptions;
}) => {
  const invalidateQuery = [queryKey[0], queryKey[1]?.id];

  // Get
  const { data: file, isPending } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(endpoint, {
        _disableErrorNotifications: hookOptions?.disableErrorNotifications,
      }),
    select: (file: any) => parseFn(file),
    enabled,
  });

  // Update
  const { mutateAsync: updateFile } = useQueryUpdate<FileType>({
    api,
    endpoint,
    queryKey,
    invalidateQueryKeys: [invalidateQuery, ...invalidateQueryKeys],
  });

  // Delete
  const { mutateAsync: deleteFile } = useQueryDelete<FileType>({
    queryKey,
    api,
    endpoint,
    invalidateQueryKeys: [invalidateQuery, ...invalidateQueryKeys],
  });

  return {
    file: enabled ? file : undefined,
    updateFile,
    deleteFile,
    isPending,
  };
};

export default useFile;
