import {
  faBell,
  faBellSlash,
  faCog,
  faCubes,
  faDiagramProject,
  faDraftingCompass,
  faFile,
  faFileAlt,
  faImages,
  faLocationArrow,
  faPlayCircle,
  faSatelliteDish,
  faSitemap,
  faSkullCrossbones,
  faTable,
  faTachometerAlt,
  faTruck,
  faUser,
  faUsers,
  faUserShield,
  faUserTie,
  faCircleNodes,
} from '@fortawesome/free-solid-svg-icons';

import faWsBlueprint from 'components/icons/faWsBlueprint1';
import faWsBricks1 from 'components/icons/faWsBricks1';
import faWsBricks2 from 'components/icons/faWsBricks2';
import faWsBuildings from 'components/icons/faWsBuildings';
import faWsCasing from 'components/icons/faWsCasing';
import faWsChartDown from 'components/icons/faWsChartDown';
import faWsChartForecast from 'components/icons/faWsChartForecast';
import faWsChartUp from 'components/icons/faWsChartUp';
import faWsCompass from 'components/icons/faWsCompass';
import faWsConstructionCrane from 'components/icons/faWsConstructionCrane';
import faWsCrane from 'components/icons/faWsCrane';
import faWsHouse1 from 'components/icons/faWsHouse1';
import faWsInsulation from 'components/icons/faWsInsulation';
import faWsKitchen from 'components/icons/faWsKitchen';
import faWsRoomType from 'components/icons/faWsRoomType';
import faWsScrew from 'components/icons/faWsScrew';
import faWsSwitch from 'components/icons/faWsSwitch';
import faWsWall from 'components/icons/faWsWall';
import { getSensorTagIcon } from 'components/icons/SensorTags';

// Tector icons
const faWsAdmin = faUserShield;
const faWsAlarm = faBell;
const faWsAnomaly = faSkullCrossbones;
const faWsCustomer = faUserTie;
const faWsDemoApplication = faPlayCircle;
const faWsDocumentChange = faFile;
const faWsGateway = faSatelliteDish;
const faWsGroup = faCubes;
const faWsGroupTypeDelivery = faTruck;
const faWsGroupTypeProject = faDiagramProject;
const faWsGroupTypeUnknown = faWsGroup;
const faWsImages = faImages;
const faWsNoAlarm = faBellSlash;
const faWsOrganization = faSitemap;
const faWsPosition = faDraftingCompass;
const faWsReport = faFileAlt;
const faWsSensor = faTachometerAlt;
const faWsSettings = faCog;
const faWsTags = faLocationArrow;
const faWsTransmissions = faTable;
const faWsUser = faUser;
const faWsUsers = faUsers;
const faWsCircleNodes = faCircleNodes;

export {
  faWsAdmin,
  faWsAlarm,
  faWsAnomaly,
  faWsBlueprint,
  faWsBricks1,
  faWsBricks2,
  faWsBuildings,
  faWsCasing,
  faWsChartDown,
  faWsChartForecast,
  faWsChartUp,
  faWsCircleNodes,
  faWsCompass,
  faWsConstructionCrane,
  faWsCrane,
  faWsCustomer,
  faWsDemoApplication,
  faWsDocumentChange,
  faWsGateway,
  faWsGroup,
  faWsGroupTypeDelivery,
  faWsGroupTypeProject,
  faWsGroupTypeUnknown,
  faWsHouse1,
  faWsImages,
  faWsInsulation,
  faWsKitchen,
  faWsNoAlarm,
  faWsOrganization,
  faWsPosition,
  faWsReport,
  faWsRoomType,
  faWsScrew,
  faWsSensor,
  faWsSettings,
  faWsSwitch,
  faWsTags,
  faWsTransmissions,
  faWsUser,
  faWsUsers,
  faWsWall,
  getSensorTagIcon,
};
