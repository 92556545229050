import AlarmType from 'utils/enums/AlarmType';

type Alarm = {
  id: string;

  alarm_id: string;
  type: AlarmType;
  transmission_id?: string;
  transmission_before_id?: string;
  sensor_id?: string;
  hardware_id?: string;
  gateway_id?: string;
  alarm_rule_id?: string;

  created_date: Date;
};

export const parseAlarm = (alarm: any): Alarm => {
  alarm.created_date = new Date(alarm.created_date);
  return alarm as Alarm;
};

export default Alarm;
