import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { parseSensorGroup } from 'utils/types/SensorGroup';

const useGatewayGroups = (gatewayId?: string) => {
  const queryKey = ['gatewayGroups', gatewayId];

  const { data: groups, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/gateways/${gatewayId}/groups`),
    select: (data: any[]) => data.map(parseSensorGroup),
    enabled: !!gatewayId,
  });

  return {
    groups,
    isPending,
  };
};

export default useGatewayGroups;
