import { FormikProps } from 'formik';
import { useCallback, useRef, Ref } from 'react';

const useIsFormDirty = (): [Ref<FormikProps<any>>, () => boolean] => {
  const ref = useRef<FormikProps<any>>(null);
  const isFormDirty = useCallback(() => !!ref.current && ref.current.dirty, [ref]);
  return [ref, isFormDirty];
};

export default useIsFormDirty;
