import useUserCustomers from 'utils/hooks/data/users/use-user-customers';
import useCurrentUser from 'utils/hooks/use-current-user';

const useCurrentUserCustomers = (enableGet: boolean = true) => {
  const { userId } = useCurrentUser();
  return useUserCustomers(userId, {
    enableGet,
  });
};

export default useCurrentUserCustomers;
