import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { parseDocumentChange } from 'utils/types/DocumentChange';

const useDocumentChanges = () => {
  const queryKey = ['document_changes'];

  // Get
  const { data: documentChanges, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get('/admin/document_changes'),
    select: (documentChanges: any[]) => documentChanges.map(parseDocumentChange),
  });

  return {
    documentChanges,
    isPending,
  };
};

export default useDocumentChanges;
