import { useEffect, useMemo, Dispatch, SetStateAction } from 'react';

import { KnownQueryKeys } from 'utils/hooks/use-query-param';
import useQueryParamInteger from 'utils/hooks/use-query-param-integer';

export const useQueryParamBoolean = (
  initialValue: boolean,
  key: KnownQueryKeys
): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const [valueInteger, setValueInteger] = useQueryParamInteger(key);

  const setValue = (x: SetStateAction<boolean>) => {
    x = x instanceof Function ? x(value) : x;
    setValueInteger(x ? 1 : 0);
  };
  const value = useMemo(() => valueInteger === 1, [valueInteger]);

  useEffect(() => {
    if (valueInteger === undefined) {
      setValue(initialValue);
    }
  }, [valueInteger, initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return [value, setValue];
};

export default useQueryParamBoolean;
