import { Dispatch, SetStateAction, useState } from 'react';

import { Dropdown, DropdownItem } from 'components/dropdowns';

const MEASURE_TYPES = ['Moisture', 'Humidity', 'Temperature'];

export const MeasureTypeDropdown: React.FC<{
  setMeasureType: Dispatch<SetStateAction<string>>;
}> = ({ setMeasureType }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(MEASURE_TYPES[0]);

  return (
    <Dropdown
      className="w-full inline-flex items-center justify-center rounded-none"
      buttonText={selected}
      open={isOpen}
      setisOpen={setIsOpen}
      buttonWidth="w-full"
      variant="info"
    >
      {MEASURE_TYPES.map(measureType => (
        <DropdownItem
          className="text-brand-gray"
          controlId={`sensorMeasureType-${measureType}`}
          onClick={() => {
            setSelected(measureType);
            setIsOpen(false);
            setMeasureType(measureType);
          }}
        >
          {measureType}
        </DropdownItem>
      ))}
    </Dropdown>
  );
};
