import { useMutation, useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { useCurrentUser } from 'utils/hooks';
import queryClient from 'utils/queries/client';
import User, { parseUser } from 'utils/types/User';

const useUser = (userId?: string) => {
  const { user: currentUser } = useCurrentUser();

  const queryKey = ['users', userId];

  // Get
  const { data: user, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/users/${userId}`),
    select: (user: any) => parseUser(user),
    placeholderData: {},
    enabled: !!userId,
  });

  // Update
  const { mutateAsync: updateUser } = useMutation({
    mutationFn: async (user: Partial<User>) => {
      const userRaw = await api.put(`/users/${userId}`, user);
      return parseUser(userRaw);
    },
    onSuccess: async user => {
      queryClient.setQueryData(queryKey, user);
      queryClient.invalidateQueries({ queryKey: ['userDocumentChanges', userId] });
      queryClient.invalidateQueries({ queryKey: ['userCustomerAccessLinks', userId] });

      // If the updated user is the currently logged in user
      // update the details of the user session
      if (user.id === currentUser?.id) {
        queryClient.setQueryData(['currentUser'], user);
      }
    },
  });

  // Delete
  const { mutateAsync: deleteUser } = useMutation({
    mutationKey: queryKey,
    mutationFn: async () => await api.delete(`/admin/users/${userId}`),
    onSuccess: async () =>
      queryClient.invalidateQueries({ queryKey: queryKey.slice(0, 1), exact: true }),
  });

  return {
    user,
    isPending,
    updateUser,
    deleteUser,
  };
};

export default useUser;
