import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { parseAlarm } from 'utils/types/Alarm';

const useCustomerAlarms = (customerId?: string) => {
  const queryKey = ['customerAlarms', customerId];

  // Get
  const { data: alarms, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/customers/${customerId}/alarms`),
    select: (alarms: any[]) => alarms.map(alarm => parseAlarm(alarm)),
    enabled: !!customerId,
  });

  return { alarms, isPending };
};

export default useCustomerAlarms;
