import useUserUsers from 'utils/hooks/data/users/use-user-users';
import useCurrentUser from 'utils/hooks/use-current-user';

const useCurrentUserUsers = (enableGet: boolean = true) => {
  const { userId } = useCurrentUser();

  return useUserUsers(userId, { enableGet });
};

export default useCurrentUserUsers;
