import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { ActionMeta, InputActionMeta, MultiValue } from 'react-select';

import { sortedArrayByStringProperty } from 'utils/arrays';
import Customer from 'utils/types/Customer';

type OptionType = { label: string; value: string };

interface CustomersSelectInputProps {
  onBlur?: ({ customerIds }: { customerIds: string[] }) => Promise<void>;
  initialSelectedCustomerIds?: string[];
  disabled?: boolean;
  customersToSelect?: Customer[];
  isPending?: boolean;
}

export const CustomersSelectInput = React.forwardRef<any, CustomersSelectInputProps>(
  ({ disabled, initialSelectedCustomerIds, onBlur, customersToSelect, isPending }, ref) => {
    const [inputValue, setInputValue] = useState('');
    const [selectedOptions, setSelectedOptions] = useState<MultiValue<OptionType>>();

    const { t } = useTranslation('components');

    const onChange = (
      _selectedOptions: MultiValue<OptionType>,
      { action, option, removedValue }: ActionMeta<OptionType>,
    ) => {
      setSelectedOptions(_selectedOptions);
    };

    // Define options array
    const options = useMemo(
      () =>
        sortedArrayByStringProperty(
          customersToSelect?.map(customer => ({
            label: customer.name,
            value: customer.id,
          })),
          'label',
        ),
      [customersToSelect],
    );

    useEffect(() => {
      if (initialSelectedCustomerIds) {
        const optionsSelected = options.filter(option =>
          initialSelectedCustomerIds.includes(option.value),
        );

        setSelectedOptions(optionsSelected);
      }
    }, [initialSelectedCustomerIds, options]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Select<OptionType, true>
        ref={ref}
        inputValue={inputValue}
        onInputChange={(value: string, action: InputActionMeta) => {
          if (action.action === 'input-change') {
            setInputValue(value);
          }
        }}
        isLoading={isPending}
        value={selectedOptions}
        onChange={onChange}
        onBlur={async () => {
          if (onBlur) {
            const selectedIds = selectedOptions
              ? selectedOptions.map((option: OptionType) => option.value)
              : [];
            setInputValue('');
            await onBlur({ customerIds: selectedIds });
          }
        }}
        styles={{ input: provided => ({ ...provided, boxShadow: 'none' }) }}
        options={options}
        placeholder={
          isPending
            ? t('inputs.CustomersSelectInput.placeholder.loading')
            : t('inputs.CustomersSelectInput.placeholder.loaded')
        }
        closeMenuOnSelect={false}
        maxMenuHeight={400}
        minMenuHeight={250}
        menuPlacement="auto"
        isMulti
        isDisabled={disabled}
        isClearable={false}
        backspaceRemovesValue={false}
        hideSelectedOptions
        isSearchable
        menuShouldScrollIntoView
        openMenuOnClick
        openMenuOnFocus
      />
    );
  },
);
