import { t } from 'translations';
import { SensorTagScrewType } from 'utils/enums/sensor-tags';

const getSensorTagScrewTypeText = (sensorTagScrewType: SensorTagScrewType) => {
  switch (sensorTagScrewType) {
    case SensorTagScrewType.InsulatedScrews:
      return t('utils:texts.sensor-tags.getSensorTagScrewTypeText.InsulatedScrews');
    case SensorTagScrewType.UninsulatedScrews:
      return t('utils:texts.sensor-tags.getSensorTagScrewTypeText.UninsulatedScrews');
    default:
      return 'n/a';
  }
};

export const getSensorTagScrewTypeTextShort = (sensorTagScrewType: SensorTagScrewType) => {
  switch (sensorTagScrewType) {
    case SensorTagScrewType.InsulatedScrews:
      return t('utils:texts.sensor-tags.getSensorTagScrewTypeTextShort.InsulatedScrews');
    case SensorTagScrewType.UninsulatedScrews:
      return t('utils:texts.sensor-tags.getSensorTagScrewTypeTextShort.UninsulatedScrews');
    default:
      return 'n/a';
  }
};

export default getSensorTagScrewTypeText;
