import useAlarm from 'utils/hooks/data/alarms/use-alarm';
import useSensor from 'utils/hooks/data/sensors/use-sensor';
import HookOptions from 'utils/types/HookOptions';

const useAlarmSensor = (alarmId: string, { enableGet = true }: HookOptions = {}) => {
  const { alarm } = useAlarm(alarmId);
  return useSensor(alarm?.sensor_id!, { enableGet });
};

export default useAlarmSensor;
