import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { useCurrentUser } from 'utils/hooks';
import Sensor from 'utils/types/Sensor';

const useAdminSalesOrderSensorStatus = (sensorIds?: string[]) => {
  const sensorIdsCommaseparated = (sensorIds || []).join(',');

  const queryKey = ['adminSalesOrderSensorStatus', sensorIdsCommaseparated];

  const { isAdmin } = useCurrentUser();

  // Get sales orders
  const { data: sensorStatus, refetch } = useQuery({
    queryKey,
    queryFn: () =>
      api.get('/admin/sales_orders_sensor_status', {
        params: {
          sensor_ids: sensorIdsCommaseparated,
        },
      }),
    select: (sensorStatus: any) =>
      sensorStatus as {
        sensors_without_valid_transmissions: Sensor[];
        sensors_without_transmissions: Sensor[];
      },
    enabled: isAdmin && sensorIds && sensorIds.length > 0,
  });

  return {
    sensorStatus,
    refetch,
  };
};

export default useAdminSalesOrderSensorStatus;
