import { faCloudArrowDown, faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { differenceInHours } from 'date-fns/differenceInHours';
import { roundToNearestHours } from 'date-fns/roundToNearestHours';
import { subHours } from 'date-fns/subHours';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlueprintCanvasContext } from 'components/BlueprintCanvas/components/BlueprintCanvasContext';
import InfoText from 'components/InfoText';
import EmailModal from 'components/modals/EmailModal';
import { dateToLocaleString } from 'utils/date';
import { useCurrentUser, useTimePeriod } from 'utils/hooks';
import { useBlueprintSensorValues } from 'utils/hooks/data';
import { notificationSuccess } from 'utils/notifications';

export const PlayableTimeline: React.FC<{
  blueprintId: string;
  show?: boolean;
  playOnMount?: boolean;
  loop?: boolean;
  numberOfSteps?: number;
  stepDelayMs?: number;
}> = ({
  blueprintId,
  playOnMount = false,
  show = false,
  loop = false,
  numberOfSteps = 48,
  stepDelayMs = 250,
}) => {
  const { t } = useTranslation('components');

  const inputRef = useRef<HTMLInputElement | null>(null);
  const isDraggingRef = useRef<boolean>(false);

  const [intervalId, setIntervalId] = useState<number | null>(null);
  const [showEmailModal, setShowEmailModal] = useState(false);

  const { hours, setHours } = useContext(BlueprintCanvasContext);

  const { user, isAdmin } = useCurrentUser();
  const {
    timePeriod: [timeFrom, timeTo],
  } = useTimePeriod();

  const { generateAndSendVideo } = useBlueprintSensorValues(
    blueprintId,
    {
      timeFrom: subHours(timeFrom, 12), // ensuring there is transmisisons from the timelines beginning
      timeTo,
    },
    { enableGet: false },
  );

  const hoursInTimePeriod = differenceInHours(
    roundToNearestHours(timeTo),
    roundToNearestHours(timeFrom),
  );
  const hoursStep = hoursInTimePeriod / numberOfSteps;

  const play = () => {
    isDraggingRef.current = false;

    if (!!intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
      return;
    }

    if (hours >= hoursInTimePeriod) {
      setHours(0);
    }

    const id = setInterval(() => {
      // Do not do anything if the user is dragging the range input
      console.log('isDraggingRef.current', isDraggingRef.current);
      if (isDraggingRef.current) return;

      setHours(val => {
        if (val < hoursInTimePeriod) {
          return val + hoursStep;
        }
        return val;
      });
    }, stepDelayMs);

    setIntervalId(Number(id));
  };

  useEffect(() => {
    // In order to handle float precision causing the last step to not be reached we ceil the value
    if (hours >= hoursInTimePeriod || (hoursInTimePeriod - hours) / hoursStep < 0.1) {
      setHours(hoursInTimePeriod);
      if (intervalId) {
        if (loop) {
          // If looping we reset the hours to 0 after a short delay
          setTimeout(() => setHours(0), 1000);
        } else {
          clearInterval(intervalId);
          setIntervalId(null);
        }
      }
    }
  }, [hours, hoursInTimePeriod, timeTo, timeFrom, intervalId, loop]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (playOnMount) {
      play();
    }
  }, [playOnMount]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!show) return null;

  const percent = hours / hoursInTimePeriod;

  return (
    <div className="mb-4 lg:mb-6">
      <div className="lg:mt-14 mt-9 flex items-center gap-3">
        <FontAwesomeIcon
          onClick={play}
          className="bg-brand-green-light-1 cursor-pointer text-brand-gray-light-4 h-4 w-4 rounded-full p-3"
          icon={!!intervalId ? faPause : faPlay}
        />

        <div className="h-3 w-full rounded-md relative flex">
          <input
            className="h-3"
            ref={inputRef}
            type="range"
            min={0}
            max={hoursInTimePeriod}
            step={hoursStep}
            value={hours}
            onChange={({ target: { value } }) => setHours(Number(value))}
            onMouseDownCapture={() => (isDraggingRef.current = true)}
            onMouseUpCapture={() => (isDraggingRef.current = false)}
          />
          <p
            style={
              percent < 0.99
                ? {
                    left: Math.max(
                      percent * (inputRef.current?.getBoundingClientRect().width || 0) -
                        percent * 20 -
                        37,
                      0,
                    ), // 20 is the width off the thumb
                  }
                : {
                    right: 0,
                  }
            }
            className={classNames(
              {
                'after:right-0': hours === hoursInTimePeriod,
                'after:left-9': hours !== 0 && hours !== hoursInTimePeriod,
                'after:left-0': hours === 0,
              },
              'absolute -top-12 z-30 px-2.5 py-2',
              'shadow-xl bg-white rounded',
              'text-brand-gray text-center text-xs lg:text-sm whitespace-nowrap',
              'after:absolute after:w-0 after:top-8 after:z-30',
              'after:h-0 after:border-l-[10px] after:border-l-transparent after:border-r-[10px] after:border-r-transparent',
              'after:border-b-[10px] after:border-b-transparent after:border-t-[10px] after:border-t-white',
            )}
          >
            {dateToLocaleString(subHours(timeFrom, -hours))}
          </p>
        </div>
      </div>

      {generateAndSendVideo && (
        <>
          {isAdmin && (
            <button
              className="mt-3 text-xs lg:text-base text-brand-gray-light-2 hover:text-blue-500 hover:underline"
              onClick={() => setShowEmailModal(true)}
            >
              <FontAwesomeIcon className="mr-2" icon={faCloudArrowDown} />
              {t('blueprints.BlueprintTimeline.generateVideo')}
            </button>
          )}

          <EmailModal
            onSubmit={async ({ email }) => {
              await generateAndSendVideo(email);
              setShowEmailModal(false);
              notificationSuccess(
                t('blueprints.BlueprintTimeline.EmailModal.successMessage', { email }),
              );
            }}
            show={showEmailModal}
            setShow={setShowEmailModal}
            intialEmail={user?.email}
            title={t('blueprints.BlueprintTimeline.EmailModal.title')}
            buttonText={t('blueprints.BlueprintTimeline.EmailModal.buttonText')}
            prependElement={
              <InfoText text={t('blueprints.BlueprintTimeline.EmailModal.infoText')} />
            }
          />
        </>
      )}
    </div>
  );
};
