import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import api from 'utils/api';
import { parseTransmission } from 'utils/types/Transmission';
import TransmissionAnomaly, { parseTransmissionAnomaly } from 'utils/types/TransmissionAnomaly';

const useTransmission = (transmissionId?: string) => {
  const queryKey = ['transmissions', transmissionId];

  const queryClient = useQueryClient();

  // Get
  const { data: transmission, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/transmissions/${transmissionId}/anomaly`),
    select: ({ transmission, anomaly }) => {
      if (anomaly) transmission.anomaly = parseTransmissionAnomaly(anomaly);
      return parseTransmission(transmission);
    },
    enabled: !!transmissionId,
  });

  // Update
  const { mutateAsync: updateTransmissionAnomaly } = useMutation({
    mutationKey: queryKey,
    mutationFn: async (values: Pick<TransmissionAnomaly, 'type' | 'description' | 'validation'>) =>
      api.patch(`/transmissions/${transmissionId}/anomaly`, values),
    onSuccess: async (result: any) => {
      queryClient.setQueryData(queryKey, result);
      queryClient.invalidateQueries({
        queryKey: ['transmissions'],
      });
      queryClient.invalidateQueries({
        queryKey: ['adminInsightsCustomers'],
      });
      queryClient.invalidateQueries({
        queryKey: ['sensorTransmissions'],
      });
    },
  });

  // Mark new
  const { mutateAsync: markTransmissionAnomaly } = useMutation({
    mutationKey: queryKey,
    mutationFn: async () => api.post(`/transmissions/${transmissionId}/anomaly`),
    onSuccess: async (result: any) => {
      queryClient.setQueryData(queryKey, result);
      queryClient.invalidateQueries({
        queryKey: ['transmissions'],
      });
      queryClient.invalidateQueries({
        queryKey: ['adminInsightsCustomers'],
      });
    },
  });

  return {
    transmission,
    updateTransmissionAnomaly,
    markTransmissionAnomaly,
    isPending,
  };
};

export default useTransmission;
