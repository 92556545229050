import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import api from 'utils/api';
import { floorToLatest5Minutes } from 'utils/date';
import useSensorGroupSensors from 'utils/hooks/data/sensor-groups/use-sensor-group-sensors';
import { parseTransmissionsMixed } from 'utils/sensor/transmissions';
import HookOptions from 'utils/types/HookOptions';
import Sensor from 'utils/types/Sensor';
import SensorStats, { SensorsStats } from 'utils/types/SensorStats';
import Transmission, { parseTransmission } from 'utils/types/Transmission';

type ReturnProps = {
  transmissions: Transmission[];
  stats?: SensorsStats;
  emc?: number[];
};

const useSensorGroupTransmissions = (
  sensorGroupId?: string,
  {
    fromTimestamp = undefined,
    toTimestamp = undefined,
    limit = undefined,
    includeMetaColumns = false,
  }: {
    fromTimestamp?: Date;
    toTimestamp?: Date;
    limit?: number;
    includeMetaColumns?: boolean;
  } = {},
  { enableGet = true }: HookOptions = {}
) => {
  fromTimestamp = floorToLatest5Minutes(fromTimestamp!); // TODO: Fix
  toTimestamp = floorToLatest5Minutes(toTimestamp!); // TODO: Fix

  const queryKey = [
    'sensorGroupTransmissions',
    sensorGroupId,
    fromTimestamp,
    toTimestamp,
    limit,
    includeMetaColumns,
  ];

  // Get
  // TODO: Set Query Data for each sensor based on result
  const { data, isPending, isLoading, isFetching } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/sensor_groups/${sensorGroupId}/transmissions_with_stats`, {
        params: {
          from_timestamp: fromTimestamp,
          to_timestamp: toTimestamp,
          limit,
          include_meta_columns: includeMetaColumns,
        },
      }),
    select: ({ transmissions, stats, emc }) =>
      ({
        transmissions: (transmissions || []).map(parseTransmission),
        stats,
        emc,
      } as ReturnProps),
    enabled: enableGet && !!sensorGroupId && !!fromTimestamp && !!toTimestamp,
  });
  const transmissionsRaw = data?.transmissions;
  const statsRaw = data?.stats;
  const emc = data?.emc;

  // Get sensors
  const { sensors } = useSensorGroupSensors(sensorGroupId);

  // Parse transmissions
  const transmissions = useMemo(() => {
    if (!transmissionsRaw) return undefined;
    return parseTransmissionsMixed({
      transmissions: transmissionsRaw,
      sensors,
    });
  }, [transmissionsRaw, sensors]);

  // Parse stats
  const stats = useMemo(() => {
    if (!statsRaw || !sensors) return [];

    const hardwareId2Sensor: { [key: string]: Sensor } = sensors?.reduce(
      (o: any, sensor: Sensor) => ({ ...o, [sensor.hardware_id]: sensor }),
      {}
    );

    return Object.entries(statsRaw)
      .map(([hardwareId, sensorStats]: [string, SensorStats]) => {
        const sensor = hardwareId2Sensor[hardwareId];
        if (!sensorStats) return { sensor } as SensorStats;
        sensorStats.sensor = sensor;
        return sensorStats;
      })
      .filter(sensorStats => sensorStats.sensor);
  }, [statsRaw, sensors]);

  return {
    transmissions,
    stats,
    emc,
    isPending: isPending || isLoading || isFetching,
  };
};

export default useSensorGroupTransmissions;
