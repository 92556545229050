import { t } from 'translations';
import ActivityState from 'utils/enums/ActivityState';
import AlarmType from 'utils/enums/AlarmType';
import HumidityState from 'utils/enums/HumidityState';
import MoistureState from 'utils/enums/MoistureState';
import MoldGrowthRiskState from 'utils/enums/MoldGrowthRiskState';
import SensorType, { SensorSubtype } from 'utils/enums/SensorType';
import SignalStrengthState from 'utils/enums/SignalStrengthState';
import TemperatureState from 'utils/enums/TemperatureState';
import TreeType from 'utils/enums/TreeType';

export const getTemperatureStateText = (temperatureState?: TemperatureState) => {
  switch (temperatureState) {
    case TemperatureState.UNKNOWN:
      return t('utils:unknown');
    case TemperatureState.TEMPERATURE_30_20:
      return t('utils:sensor.texts.TemperatureState', {
        from: -30,
        to: -20,
      });
    case TemperatureState.TEMPERATURE_20_10:
      return t('utils:sensor.texts.TemperatureState', {
        from: -20,
        to: -10,
      });
    case TemperatureState.TEMPERATURE_10_0:
      return t('utils:sensor.texts.TemperatureState', {
        from: -10,
        to: 0,
      });
    case TemperatureState.TEMPERATURE_0_10:
      return t('utils:sensor.texts.TemperatureState', {
        from: 0,
        to: 10,
      });
    case TemperatureState.TEMPERATURE_10_20:
      return t('utils:sensor.texts.TemperatureState', {
        from: 10,
        to: 20,
      });
    case TemperatureState.TEMPERATURE_20_30:
      return t('utils:sensor.texts.TemperatureState', {
        from: 20,
        to: 30,
      });
    case TemperatureState.TEMPERATURE_30_40:
      return t('utils:sensor.texts.TemperatureState', {
        from: 30,
        to: 40,
      });
    case TemperatureState.TEMPERATURE_40_50:
      return t('utils:sensor.texts.TemperatureState', {
        from: 40,
        to: 50,
      });
    default:
      return 'n/a';
  }
};

export const getHumidityStateText = (humidityState?: HumidityState) => {
  switch (humidityState) {
    case HumidityState.UNKNOWN:
      return t('utils:unknown');
    case HumidityState.HUMIDITY_0_10:
      return t('utils:sensor.texts.HumidityState', {
        from: 0,
        to: 10,
      });
    case HumidityState.HUMIDITY_10_20:
      return t('utils:sensor.texts.HumidityState', {
        from: 10,
        to: 20,
      });
    case HumidityState.HUMIDITY_20_30:
      return t('utils:sensor.texts.HumidityState', {
        from: 20,
        to: 30,
      });
    case HumidityState.HUMIDITY_30_40:
      return t('utils:sensor.texts.HumidityState', {
        from: 30,
        to: 40,
      });
    case HumidityState.HUMIDITY_40_50:
      return t('utils:sensor.texts.HumidityState', {
        from: 40,
        to: 50,
      });
    case HumidityState.HUMIDITY_50_60:
      return t('utils:sensor.texts.HumidityState', {
        from: 50,
        to: 60,
      });
    case HumidityState.HUMIDITY_60_70:
      return t('utils:sensor.texts.HumidityState', {
        from: 60,
        to: 70,
      });
    case HumidityState.HUMIDITY_70_80:
      return t('utils:sensor.texts.HumidityState', {
        from: 70,
        to: 80,
      });
    case HumidityState.HUMIDITY_80_90:
      return t('utils:sensor.texts.HumidityState', {
        from: 80,
        to: 90,
      });
    case HumidityState.HUMIDITY_90_100:
      return t('utils:sensor.texts.HumidityState', {
        from: 90,
        to: 100,
      });
    default:
      return 'n/a';
  }
};

export const getSignalStrengthStateText = (signalStrengthState?: SignalStrengthState) => {
  switch (signalStrengthState) {
    case SignalStrengthState.UNKNOWN:
      return t('utils:unknown');
    case SignalStrengthState.VERY_GOOD:
      return t('utils:sensor.texts.SignalStrengthState.VeryGood');
    case SignalStrengthState.GOOD:
      return t('utils:sensor.texts.SignalStrengthState.Good');
    case SignalStrengthState.FAIR:
      return t('utils:sensor.texts.SignalStrengthState.Fair');
    case SignalStrengthState.MODERATE:
      return t('utils:sensor.texts.SignalStrengthState.Moderate');
    case SignalStrengthState.POOR:
      return t('utils:sensor.texts.SignalStrengthState.Poor');
    case SignalStrengthState.VERY_POOR:
      return t('utils:sensor.texts.SignalStrengthState.VeryPoor');
    default:
      return 'n/a';
  }
};

export const getActivityStateText = (activityState?: ActivityState) => {
  switch (activityState) {
    case ActivityState.ACTIVE:
      return t('utils:sensor.texts.ActivityState.Active');
    case ActivityState.INACTIVE:
      return t('utils:sensor.texts.ActivityState.InActive');
    case ActivityState.DEREGISTERED:
      return t('utils:sensor.texts.ActivityState.Deregistered');
    default:
      return 'n/a';
  }
};

export const getMoldGrowthRiskStateText = (moldGrowthRiskState?: MoldGrowthRiskState) => {
  switch (moldGrowthRiskState) {
    case MoldGrowthRiskState.UNKNOWN:
      return t('utils:unknown');
    case MoldGrowthRiskState.HIGH_RISK:
      return t('utils:sensor.texts.MoldGrowthRiskState.HighRisk');
    case MoldGrowthRiskState.LOW_RISK:
      return t('utils:sensor.texts.MoldGrowthRiskState.LowRisk');
    case MoldGrowthRiskState.MODERATE_RISK:
      return t('utils:sensor.texts.MoldGrowthRiskState.ModerateRisk');
    case MoldGrowthRiskState.NO_RISK:
      return t('utils:sensor.texts.MoldGrowthRiskState.NoRisk');
    case MoldGrowthRiskState.VERY_HIGH_RISK:
      return t('utils:sensor.texts.MoldGrowthRiskState.VeryHighRisk');
    default:
      return 'n/a';
  }
};

export const getSensorStateText = (sensorState?: MoistureState) => {
  switch (sensorState) {
    case MoistureState.UNKNOWN:
      return t('utils:unknown');
    case MoistureState.MOISTURE_0_10:
      return t('utils:sensor.texts.SensorState.Moisture', {
        from: 0,
        to: 10,
      });
    case MoistureState.MOISTURE_10_15:
      return t('utils:sensor.texts.SensorState.Moisture', {
        from: 10,
        to: 15,
      });
    case MoistureState.MOISTURE_15_20:
      return t('utils:sensor.texts.SensorState.Moisture', {
        from: 15,
        to: 20,
      });
    case MoistureState.MOISTURE_20_25:
      return t('utils:sensor.texts.SensorState.Moisture', {
        from: 20,
        to: 25,
      });
    case MoistureState.MOISTURE_25_30:
      return t('utils:sensor.texts.SensorState.Moisture', {
        from: 25,
        to: 30,
      });
    case MoistureState.MOISTURE_30_35:
      return t('utils:sensor.texts.SensorState.Moisture', {
        from: 30,
        to: 35,
      });
    case MoistureState.MOISTURE_35_40:
      return t('utils:sensor.texts.SensorState.Moisture', {
        from: 35,
        to: 40,
      });
    case MoistureState.MOISTURE_40_100:
      return t('utils:sensor.texts.SensorState.Moisture', {
        from: 40,
        to: 100,
      });
    case MoistureState.ACTIVE:
      return t('utils:sensor.texts.SensorState.Active');
    case MoistureState.INACTIVE:
      return t('utils:sensor.texts.SensorState.InActive');
    case MoistureState.INVALID:
      return t('utils:sensor.texts.SensorState.Invalid');
    case MoistureState.CREATED:
      return t('utils:sensor.texts.SensorState.Created');
    default:
      return 'n/a';
  }
};

export const getTreeTypeText = (treeType?: TreeType) => {
  switch (treeType) {
    case TreeType.PINE:
      return t('utils:sensor.texts.TreeType.Pine');
    case TreeType.SPRUCE:
      return t('utils:sensor.texts.TreeType.Spruce');
    case TreeType.CUSTOM_PLYWOOD_BUNCH_001:
      return t('utils:sensor.texts.TreeType.CustomPlywoodBunch001');
    case TreeType.DOUGLAS_FIR:
      return t('utils:sensor.texts.TreeType.DouglasFir');
    case TreeType.OAK_DENMARK:
      return t('utils:sensor.texts.TreeType.OakDenmark');
    case TreeType.CHESTNUT:
      return t('utils:sensor.texts.TreeType.Chestnut');
    case TreeType.OAK_HEARTWOOD_LJUBLJANA:
      return t('utils:sensor.texts.TreeType.OakHeartwoodLjubljana');
    case TreeType.POPLAR_THERMALLY_MODIFIED:
      return t('utils:sensor.texts.TreeType.PoplarThermallyModified');
    case TreeType.SPRUCE_UK_COATED_LJUBLJANA:
      return t('utils:sensor.texts.TreeType.SpruceUKCoatedLjubljana');
    case TreeType.UNKNOWN:
      return t('utils:unknown');
    default:
      return 'n/a';
  }
};

export const getSensorTypeText = (sensorType?: SensorType) => {
  switch (sensorType) {
    case SensorType.WOODSENSE_WOODY:
      return t('utils:sensor.texts.getSensorTypeText.WoodsenseWoody');
    case SensorType.WOODSENSE_FLATTY:
      return t('utils:sensor.texts.getSensorTypeText.WoodsenseFlatty');
    case SensorType.SENSATIVE_STRIP:
      return t('utils:sensor.texts.getSensorTypeText.SensativeStrip');
    case SensorType.ELSYS:
      return t('utils:sensor.texts.getSensorTypeText.Elsys');
    default:
      return null;
  }
};

export const getSensorSubtypeText = (sensorSubType?: SensorSubtype) => {
  switch (sensorSubType) {
    case SensorSubtype.ELSYS_ERS_CO2_LITE:
      return t('utils:sensor.texts.getSensorSubtypeText.ElsysERSCO2Lite');
    case SensorSubtype.ELSYS_ERS_LITE:
      return t('utils:sensor.texts.getSensorSubtypeText.ElsysERSLite');
    case SensorSubtype.ELSYS_ERS_EYE:
      return t('utils:sensor.texts.getSensorSubtypeText.ElsysERSEye');
    case SensorSubtype.ELSYS_ERS_SOUND:
      return t('utils:sensor.texts.getSensorSubtypeText.ElsysERSSound');
    case SensorSubtype.ELSYS_ERS_VOC:
      return t('utils:sensor.texts.getSensorSubtypeText.ElsysERSVOC');
    case SensorSubtype.WOODSENSE_WOODY_PREMIUM:
      return t('utils:sensor.texts.getSensorSubtypeText.WoodsenseWoodyPremium');
    case SensorSubtype.WOODSENSE_WOODY_QA:
      return t('utils:sensor.texts.getSensorSubtypeText.WoodsenseWoodyQA');
    default:
      return null;
  }
};

export const getSensorAttributeText = (attribute: string) => {
  switch (attribute) {
    case 'tree_type':
      return t('utils:sensor.texts.attribute.tree_type');
    case 'name':
      return t('utils:sensor.texts.attribute.name');
    case 'location_description':
      return t('utils:sensor.texts.attribute.location_description');
    default:
      return attribute;
  }
};

export const getAlarmTypeText = (alarmType: AlarmType) => {
  switch (alarmType) {
    case AlarmType.Unknown:
      return t('utils:unknown');
    case AlarmType.MissingValues48Hours:
      return t('utils:sensor.texts.AlarmType.MissingValues48Hours');
    case AlarmType.MissingValues1Week:
      return t('utils:sensor.texts.AlarmType.MissingValues1Week');
    case AlarmType.MissingValues4Weeks:
      return t('utils:sensor.texts.AlarmType.MissingValues4Weeks');
    case AlarmType.SensativeFloodAlarm:
      return t('utils:sensor.texts.AlarmType.SensativeFloodAlarm');
    case AlarmType.GatewayMissingHeartbeats24Hours:
      return t('utils:sensor.texts.AlarmType.GatewayMissingHeartbeats24Hours');
    case AlarmType.GatewayMissingHeartbeats1Week:
      return t('utils:sensor.texts.AlarmType.GatewayMissingHeartbeats1Week');
    case AlarmType.GatewayMissingHeartbeats4Weeks:
      return t('utils:sensor.texts.AlarmType.GatewayMissingHeartbeats4Weeks');
    case AlarmType.CustomAlarmRule:
      return t('utils:sensor.texts.AlarmType.CustomAlarmRule');
    case AlarmType.InternalNewDevice:
      return t('utils:sensor.texts.AlarmType.InternalNewDevice');
    case AlarmType.InternalMoistureIncreased:
      return t('utils:sensor.texts.AlarmType.InternalMoistureIncreased');
    case AlarmType.ReferenceValues:
      return t('utils:sensor.texts.AlarmType.ReferenceValues');
    default:
      return 'n/a';
  }
};
