import { useMemo } from 'react';

import { unqiueArrayByStringProperty } from 'utils/arrays';
import Permission from 'utils/enums/Permission';
import { useUserSensors, useCustomerSensors } from 'utils/hooks/data';
import { Options as OptionsCustomerSensors } from 'utils/hooks/data/customers/use-customer-sensors';
import { Options as OptionsUserSensors } from 'utils/hooks/data/users/use-user-sensors';
import { CUSTOMER_ID_TECTOR_STOCK } from 'utils/types/Customer';

type Options = OptionsCustomerSensors & OptionsUserSensors;

export const useUserAndCustomerSensors = (
  userId?: string,
  customerId?: string,
  {
    enableGet = true,
    includeTags = false,
    includeGeographicLocation = false,
    includeStates = false,
    includeAllSensors = false,
    permission = Permission.View,
  }: Options = {},
) => {
  const { sensors: sensorsUser, isPending: isPendingUserSensors } = useUserSensors(userId, {
    includeTags,
    includeGeographicLocation,
    includeStates,
    includeAllSensors,
    enableGet,
    permission,
  });
  const { sensors: sensorsCustomer, isPending: isPendingCustomerSensors } = useCustomerSensors(
    customerId,
    {
      includeTags,
      includeGeographicLocation,
      includeStates,
      enableGet,
      permission,
    },
  );

  const sensors = useMemo(
    () =>
      // We merge the sensors from the user and the customer and remove duplicates.
      unqiueArrayByStringProperty(
        [...(sensorsUser || []), ...(sensorsCustomer || [])].filter(
          // We hide sensors from Tector stock for admins to limit the number of sensors shown.
          // Unless the admin specifically selected Tector stock as the customer.
          sensor =>
            customerId === CUSTOMER_ID_TECTOR_STOCK ||
            sensor.customer_id !== CUSTOMER_ID_TECTOR_STOCK,
        ),
        'id',
      ),
    [sensorsUser, sensorsCustomer, customerId],
  );

  return {
    sensors,
    isPending: isPendingUserSensors || isPendingCustomerSensors,
  };
};
