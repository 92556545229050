enum SensorTagPrimaryElementCategory {
  // Terrain = 20,
  ExternalWalls = 21,
  InteriorWalls = 22,
  Slabs = 23,
  StairsAndRamps = 24,
  LoadBearingBeamsAndColumns = 25,
  BalconiesAndExteriorHallways = 26,
  Roofs = 27,
  OtherPrimary = 28,
}

export default SensorTagPrimaryElementCategory;
