import classNames from 'classnames';
import { OptionProps, components } from 'react-select';

import { OptionType } from 'components/inputs/components/MultiSelect/types';

export const Option = (props: OptionProps<OptionType>) => (
  <div data-tooltip-content={props.data.tooltip} data-tooltip-id="multi-select-tooltip">
    <components.Option {...props}>
      <div
        className={classNames('flex align-center space-x-2', {
          'opacity-50': props.data.isDisabled,
        })}
      >
        {/* Note: Removing 'onChange' causes warning in console */}
        <input type="checkbox" checked={props.isSelected} onChange={() => {}} />
        <label className="cursor-default">{props.label}</label>
      </div>
    </components.Option>
  </div>
);
