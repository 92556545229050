import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import api from 'utils/api';
import Role from 'utils/enums/Role';
import HookOptions from 'utils/types/HookOptions';
import { parseUserInvitation } from 'utils/types/UserInvitation';

const useGatewayUserInvitations = (gatewayId?: string, { enableGet = true }: HookOptions = {}) => {
  const queryKey = ['gatewayUserInvitations', gatewayId];

  const queryClient = useQueryClient();

  // User Invitations
  const { data: userInvitations, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/gateways/${gatewayId}/user_invitations`),
    select: (data: any[]) => data.map(data => parseUserInvitation(data)),
    enabled: !!gatewayId && enableGet,
  });

  // Create
  const { mutateAsync: createUserInvitation } = useMutation({
    mutationFn: async (values: { email: string; role: Role }) => {
      const userInvitationsRaw: any[] = await api.post(
        `/gateways/${gatewayId}/user_invitations`,
        values
      );
      return userInvitationsRaw.map(parseUserInvitation);
    },
    onSuccess: userInvitations => {
      queryClient.setQueryData(queryKey, userInvitations);
    },
  });

  return {
    userInvitations,
    createUserInvitation,
    isPending,
  };
};

export default useGatewayUserInvitations;
