import { t } from 'translations';
import { SensorTagInsulation } from 'utils/enums/sensor-tags';

const getSensorTagInsulationText = (sensorTagInsulation: SensorTagInsulation) => {
  switch (sensorTagInsulation) {
    case SensorTagInsulation.SurroundedByInsulation:
      return t('utils:texts.sensor-tags.getSensorTagInsulationText.SurroundedByInsulation');
    case SensorTagInsulation.NotSurroundedByInsulation:
      return t('utils:texts.sensor-tags.getSensorTagInsulationText.NotSurroundedByInsulation');
    default:
      return 'n/a';
  }
};

export const getSensorTagInsulationTextShort = (sensorTagInsulation: SensorTagInsulation) => {
  switch (sensorTagInsulation) {
    case SensorTagInsulation.SurroundedByInsulation:
      return t('utils:texts.sensor-tags.getSensorTagInsulationTextShort.SurroundedByInsulation');
    case SensorTagInsulation.NotSurroundedByInsulation:
      return t('utils:texts.sensor-tags.getSensorTagInsulationTextShort.NotSurroundedByInsulation');
    default:
      return 'n/a';
  }
};

export default getSensorTagInsulationText;
