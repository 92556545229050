import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from 'utils/api';
import { useSelectedCustomer } from 'utils/hooks';
import SensorTags from 'utils/types/SensorTags';

const useSensorTags = (sensorId?: string) => {
  const queryClient = useQueryClient();

  const { customerId } = useSelectedCustomer();

  // Sensor Tags - Update
  const { mutateAsync: updateSensorTags } = useMutation({
    mutationFn: (sensorTags: SensorTags) => api.patch(`/sensors/${sensorId}/tags`, sensorTags),
    onSuccess: async (sensorRaw: any) => {
      queryClient.setQueryData(['sensors', sensorId], sensorRaw);
      await queryClient.invalidateQueries({
        queryKey: ['customerSensors', customerId],
      });
      await queryClient.invalidateQueries({
        queryKey: ['sensorGroupSensors'],
      });
    },
  });

  // Update by sensor Id
  const { mutateAsync: updateSensorTagsById } = useMutation({
    mutationFn: ({ id: sensorId, ...sensorTags }: { id: string } & SensorTags) =>
      api.patch(`/sensors/${sensorId}/tags`, sensorTags),
    onSuccess: async (sensorRaw: any) => {
      queryClient.setQueryData(['sensors', sensorId], sensorRaw);
      await queryClient.invalidateQueries({
        queryKey: ['customerSensors', customerId],
      });
      await queryClient.invalidateQueries({
        queryKey: ['sensorGroupSensors'],
      });
    },
  });

  return {
    updateSensorTags,
    updateSensorTagsById,
  };
};

export default useSensorTags;
