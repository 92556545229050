import SensorType, { SensorSubtype } from 'utils/enums/SensorType';
import TreeType from 'utils/enums/TreeType';
import AlarmRule, { parseAlarmRule } from 'utils/types/AlarmRule';
import GeographicLocation, { parseGeographicLocation } from 'utils/types/GeographicLocation';
import SensorStates, { parseSensorStates } from 'utils/types/SensorStates';
import SensorTags, { parseSensorTags } from 'utils/types/SensorTags';
import SmappeeServicelocation from 'utils/types/SmappeeServicelocation';

type Sensor = {
  id: string;

  customer_id?: string;
  name: string;
  type: SensorType;
  subtype?: SensorSubtype;
  demo_application_id?: string;
  hardware_id: string;
  at_woodsense_office: boolean;
  send_alarms?: boolean;
  setup_completed_at?: Date;
  sensor_file_id?: string;
  sensor_file_url?: string;
  tree_type: TreeType;
  heartbeat_interval?: number;
  latest_transmission_received_at?: Date;

  sw_version?: string;
  hw_version?: string;

  ttn_app_id?: string;
  ttn_app_eui?: string;
  ttn_dev_id?: string;
  ttn_dev_eui?: string;

  // Relations
  alarm_rules?: AlarmRule[];
  smappee_servicelocation?: SmappeeServicelocation;
  geographic_location?: GeographicLocation;
  states: SensorStates;
  tags?: SensorTags;

  created_date: Date;
  created_by: string;
  updated_date?: Date;
  updated_by?: string;

  // Dynamically defined properties
  isLoraSensor: boolean;
};

export const parseSensor = (sensor: any): Sensor => {
  sensor.created_date = new Date(sensor.created_date);
  if (sensor.updated_date) sensor.updated_date = new Date(sensor.updated_date);

  if (sensor.setup_completed_at) sensor.setup_completed_at = new Date(sensor.setup_completed_at);

  sensor.states = parseSensorStates(sensor.states);

  if (sensor.tags) {
    sensor.tags = parseSensorTags(sensor.tags);
  } else {
    sensor.tags = {} as SensorTags;
  }

  if (sensor.alarm_rules) {
    sensor.alarm_rules = sensor.alarm_rules.map(parseAlarmRule);
  }

  if (sensor.geographic_location) {
    sensor.geographic_location = parseGeographicLocation(sensor.geographic_location);
  }

  if (sensor.latest_transmission_received_at) {
    sensor.latest_transmission_received_at = new Date(sensor.latest_transmission_received_at);
  }

  // Helper properties
  sensor.isLoraSensor =
    sensor.type === SensorType.WOODSENSE_WOODY ||
    sensor.type === SensorType.WOODSENSE_FLATTY ||
    sensor.type === SensorType.SENSATIVE_STRIP ||
    sensor.type === SensorType.ELSYS;

  return sensor as Sensor;
};

// Cache query keys
export const getQueryKeySensorAlarmRules = (sensorId: string) => ['sensorAlarmRules', sensorId];
export const getQueryKeySensorAlarmRuleEnabled = (sensorId: string, alarmRuleId: string) => [
  'sensorAlarmRuleEnabled',
  sensorId,
  alarmRuleId,
];

export default Sensor;
