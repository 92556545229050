import isEqual from 'lodash/isEqual';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiValue } from 'react-select';

import { MultiSelect } from 'components/inputs/components';
import { OptionType } from 'components/inputs/components/MultiSelect/types';
import { SensorsSelectInputProps } from 'components/inputs/SensorsSelectInput/types';
import { sortedArrayByStringProperty } from 'utils/arrays';
import { useCurrentUserCustomers } from 'utils/hooks/data';

const SensorsSelectInputInner = (
  {
    disabled,
    initialSelectedSensorIds,
    disabledSensorIds,
    sensorsToSelect,
    label,
    sensorIdToTooltip,
    ...props
  }: SensorsSelectInputProps,
  ref: React.Ref<any>,
) => {
  const [selectedOptions, setSelectedOptions] = useState<MultiValue<OptionType>>();

  const { customers } = useCurrentUserCustomers();

  const { t } = useTranslation('components');

  // Create mapping from customer ID -> customer name
  const customerIdToName: { [key: string]: string } = useMemo(() => {
    // Sort customers by name
    const customersSorted = sortedArrayByStringProperty(customers, 'name');

    const _customerIdToname: { [key: string]: string } = Object.fromEntries(
      customersSorted.map(customer => [customer.id, customer.name || customer.id]),
    );
    _customerIdToname['null'] = t('inputs.SensorsSelectInput.ownedByOther');
    return _customerIdToname;
  }, [customers, t]);

  // Define options array
  const options = useMemo(() => {
    const customerIdToSensors: { [key: string]: OptionType[] } = {};
    Object.keys(customerIdToName).forEach(customerId => (customerIdToSensors[customerId] = []));
    sensorsToSelect?.forEach(sensor => {
      // If user doesn't have access to the sensors customer -> Put it in the "n/a" category
      const customerId = customerIdToSensors.hasOwnProperty(sensor.customer_id!)
        ? sensor.customer_id!
        : 'null';
      const isDisabled = disabledSensorIds?.includes(sensor.id);
      customerIdToSensors[customerId].push({
        value: sensor.id,
        label: sensor.name || sensor.hardware_id,
        isDisabled,
        tooltip: sensorIdToTooltip?.[sensor?.id],
      });
    });

    // Construct options grouped by customer
    return Object.entries(customerIdToName).map(([customerId, customerName]) => ({
      label: customerName,
      options: sortedArrayByStringProperty(customerIdToSensors[customerId], 'label'),
    }));
  }, [customerIdToName, disabledSensorIds, sensorsToSelect, sensorIdToTooltip]);

  const optionsCount = options.map(option => option.options.length).reduce((a, b) => a + b, 0);

  // If `initialSelectedSensorIds` is provided -> use them to select initial options
  useEffect(() => {
    if (initialSelectedSensorIds) {
      const optionsFlat = options.map(option => option.options).flat();
      const optionsSelectedFlat = optionsFlat.filter(option =>
        initialSelectedSensorIds.includes(option.value),
      );

      const selectedOptionValues = selectedOptions?.map(x => x.value);
      const valuesSelectedFlat = optionsSelectedFlat?.map(x => x.value);
      if (!isEqual(selectedOptionValues, valuesSelectedFlat)) {
        setSelectedOptions(optionsSelectedFlat);
      }
    }
  }, [initialSelectedSensorIds, options]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {label && (
        <div className="flex justify-between">
          <label htmlFor="sensors-select-input">{label}</label>
          <samp className="text-sm relative top-1">
            {selectedOptions?.length || 0}/{optionsCount}
          </samp>
        </div>
      )}

      <MultiSelect
        ref={ref}
        options={options}
        value={selectedOptions ?? []}
        onChange={setSelectedOptions}
        disabled={disabled}
        inputId="sensors-select-input"
        placeholderLoading={t('inputs.SensorsSelectInput.placeholder.loading')}
        placeholderLoaded={t('inputs.SensorsSelectInput.placeholder.loaded')}
        allItemsLabel={t('inputs.helpers.MultiSelect.allSensors')}
        {...props}
      />
    </>
  );
};

export const SensorsSelectInput = React.forwardRef<any, SensorsSelectInputProps>(
  SensorsSelectInputInner,
);
