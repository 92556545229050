import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { ActionMeta, GroupBase, MultiValue } from 'react-select';

import { Badge } from 'components';
import { sortedArrayByStringProperty } from 'utils/arrays';
import AlarmRuleType from 'utils/enums/AlarmRuleType';
import { parseI18LanguageToLocale } from 'utils/enums/Locale';
import { getAlarmRuleTypeText } from 'utils/texts/AlarmRuleType';
import AlarmRule, { getAlarmRuleLocalizedName } from 'utils/types/AlarmRule';

type OptionType = { label: string; value: string };
type GroupType = GroupBase<OptionType>;

interface AlarmRulesSelectInputProps {
  onBlur?: ({ alarmRuleIds }: { alarmRuleIds: string[] }) => Promise<void>;
  initialSelectedAlarmRuleIds?: string[];
  disabled?: boolean;
  alarmRulesToSelect?: AlarmRule[];
  isPending?: boolean;
}

export const AlarmRulesSelectInput = React.forwardRef<any, AlarmRulesSelectInputProps>(
  ({ disabled, initialSelectedAlarmRuleIds, onBlur, alarmRulesToSelect, isPending }, ref) => {
    const [inputValue, setInputValue] = useState('');
    const [selectedOptions, setSelectedOptions] = useState<MultiValue<OptionType>>();

    const { t, i18n } = useTranslation('components');
    const currentLocale = parseI18LanguageToLocale(i18n.language);

    const onChange = (
      _selectedOptions: MultiValue<OptionType>,
      { action, option, removedValue }: ActionMeta<OptionType>,
    ) => {
      setSelectedOptions(_selectedOptions);
    };

    // Define options array
    const options = useMemo(
      () =>
        [AlarmRuleType.BuiltIn, AlarmRuleType.Custom].map(alarmRuleType => ({
          label: getAlarmRuleTypeText(AlarmRuleType.Custom),
          options: sortedArrayByStringProperty(
            alarmRulesToSelect
              ?.filter(alarmRule => alarmRule.type === alarmRuleType)
              .map(alarmRule => ({
                label: getAlarmRuleLocalizedName(alarmRule, currentLocale)!,
                value: alarmRule.id,
              })),
            'label',
          ),
        })),
      [alarmRulesToSelect, currentLocale],
    );

    useEffect(() => {
      if (initialSelectedAlarmRuleIds) {
        const optionsFlat = options.map(option => option.options).flat();
        const optionsSelectedFlat = optionsFlat.filter(option =>
          initialSelectedAlarmRuleIds.includes(option.value),
        );

        setSelectedOptions(optionsSelectedFlat);
      }
    }, [initialSelectedAlarmRuleIds, options]); // eslint-disable-line react-hooks/exhaustive-deps

    const formatGroupLabel = (data: GroupType) => (
      <div className="flex justify-between">
        <span className="grow">{data.label}</span>
        <Badge className="ws-text-primary shrink bg-brand-gray-light-3">
          {data.options.length}
        </Badge>
      </div>
    );

    return (
      <Select<OptionType, true, GroupType>
        ref={ref}
        inputValue={inputValue}
        onInputChange={(value, action) => {
          if (action.action === 'input-change') {
            setInputValue(value);
          }
        }}
        isLoading={isPending}
        value={selectedOptions}
        formatGroupLabel={formatGroupLabel}
        onChange={onChange}
        onBlur={async () => {
          if (onBlur) {
            const selectedIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
            setInputValue('');
            await onBlur({ alarmRuleIds: selectedIds });
          }
        }}
        options={options}
        placeholder={
          isPending
            ? t('inputs.AlarmRulesSelectInput.placeholder.loading')
            : t('inputs.AlarmRulesSelectInput.placeholder.loaded')
        }
        styles={{ input: provided => ({ ...provided, boxShadow: 'none' }) }}
        closeMenuOnSelect={false}
        maxMenuHeight={400}
        minMenuHeight={250}
        menuPlacement="auto"
        isMulti
        isDisabled={disabled}
        isClearable={false}
        backspaceRemovesValue={false}
        hideSelectedOptions
        isSearchable
        menuShouldScrollIntoView
        openMenuOnClick
        openMenuOnFocus
      />
    );
  },
);
