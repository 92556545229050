import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import useCurrentUser from 'utils/hooks/use-current-user';

type CountsModel = {
  primary_element_category: number;
  primary_element_type: number;
  complementary_element: number;
  orientation: number;
  room_type: number;
  vertical_placement: number;
  casing: number;
  climate: number;
  construction_phase: number;
  insulation: number;
  screw_type: number;
  ventilation: number;
};

type InsightsKPIsSensorTags = {
  sensor_counts: number;
  counts: CountsModel;
};

const useAdminInsightsKPIsSensorTags = () => {
  const queryKey = ['adminInsightsKPIsSensorTags'];

  const { isAdmin } = useCurrentUser();
  const { data: insightsKPIsSensorTags, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get('/admin/insights/kpis/sensor_tags'),
    select: (result: any) => result as InsightsKPIsSensorTags,
    enabled: isAdmin,
  });

  return { insightsKPIsSensorTags, isPending };
};

export default useAdminInsightsKPIsSensorTags;
