import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import { useMemo, useRef } from 'react';

import api from 'utils/api';
import AccessLink, { AccessLinkUpdate, parseAccessLink } from 'utils/types/AccessLink';

const useSensorAccessLinks = (sensorId?: string) => {
  const queryKey = ['sensorAccessLinks', sensorId];

  const queryClient = useQueryClient();

  const previousAccessLinksRef = useRef<AccessLink[] | undefined>();

  const { data: fetchedAccessLinks, isPending } = useQuery<AccessLink[]>({
    queryKey,
    queryFn: () => api.get(`/sensors/${sensorId}/access_links`),
    select: (accessLinks: any[]) => accessLinks.map(parseAccessLink),
    enabled: !!sensorId,
    refetchInterval: 5000,
  });

  const accessLinks = useMemo(() => {
    if (!isEqual(previousAccessLinksRef.current, fetchedAccessLinks)) {
      previousAccessLinksRef.current = fetchedAccessLinks;
      return fetchedAccessLinks || [];
    }
    return previousAccessLinksRef.current;
  }, [fetchedAccessLinks]);

  const { mutateAsync: updateAccessLinks } = useMutation({
    mutationFn: async (values: AccessLinkUpdate): Promise<AccessLink[]> => {
      const accessLinks = await api.post(`/sensors/${sensorId}/access_links`, values);
      return accessLinks.map(parseAccessLink);
    },
    onSuccess: async (accessLinks, values) => {
      queryClient.setQueryData(queryKey, accessLinks);
      await queryClient.invalidateQueries({
        queryKey: ['permissions'],
      });
      if (values.user_id) {
        await queryClient.invalidateQueries({
          queryKey: ['userSensors', values.user_id],
        });
      }
      if (values.customer_id) {
        await queryClient.invalidateQueries({
          queryKey: ['customerSensors', values.customer_id],
        });
      }
    },
  });

  return {
    accessLinks,
    updateAccessLinks,
    isPending,
  };
};

export default useSensorAccessLinks;
