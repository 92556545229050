import classNames from 'classnames';
import { HTMLAttributes } from 'react';

import { Badge } from 'components';
import Spinner from 'components/Spinner';
import MoistureState from 'utils/enums/MoistureState';
import { getSensorStateText } from 'utils/sensor/texts';

export const SensorStateBadge: React.FC<
  { state?: MoistureState } & HTMLAttributes<HTMLSpanElement>
> = ({ state, className, ...props }) =>
  state ? (
    <Badge
      {...props}
      className={classNames('text-xs font-medium text-white', className, {
        'bg-sensorState0-10 text-brand-gray': state === MoistureState.MOISTURE_0_10,
        'bg-sensorState10-15 text-brand-gray': state === MoistureState.MOISTURE_10_15,
        'bg-sensorState15-20': state === MoistureState.MOISTURE_15_20,
        'bg-sensorState20-25':
          state === MoistureState.MOISTURE_20_25 || state === MoistureState.ACTIVE,
        'bg-sensorState25-30': state === MoistureState.MOISTURE_25_30,
        'bg-sensorState30-35': state === MoistureState.MOISTURE_30_35,
        'bg-sensorState35-40': state === MoistureState.MOISTURE_35_40,
        'bg-sensorState40-100': state === MoistureState.MOISTURE_40_100,
        'bg-sensorStateInactive':
          state === MoistureState.INACTIVE || state === MoistureState.INVALID,
        'bg-brand-green': state === MoistureState.CREATED,
        'bg-brand-orange': state === MoistureState.UNKNOWN,
      })}
    >
      {getSensorStateText(state)}
    </Badge>
  ) : (
    <Spinner className="ml-2 text-brand-gray-light-1" />
  );
