import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { parseSensor } from 'utils/types/Sensor';

const useReportRevisionSensors = (reportId?: string, reportRevisionId?: string) => {
  const queryKey = ['reportRevisionSensors', reportRevisionId];

  // Get
  const {
    data: sensors,
    isPending,
    isFetching,
    isLoading,
  } = useQuery({
    queryKey,
    queryFn: () => api.get(`/reports/${reportId}/revisions/${reportRevisionId}/sensors`),
    select: (data: any[]) => data.map(parseSensor),
    enabled: !!reportId && !!reportRevisionId,
  });

  return {
    sensors,
    isPending: isPending || isFetching || isLoading,
  };
};

export default useReportRevisionSensors;
