import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';

import { Button, Table, TableLink } from 'components';
import { dateToLocaleString, dateToPrettyDate } from 'utils/date';
import { sortingFnBoolean } from 'utils/tables/sort-functions';
import { boolean2text } from 'utils/texts/common';
import Customer from 'utils/types/Customer';
import User from 'utils/types/User';

export const UsersTable: React.FC<{
  users: User[];
  isPending?: boolean;
  customers?: Customer[];
  onClick?: (row: User) => void;
  onDelete?: (row: User) => void;
  includeLink?: boolean;
  columnsToShow?: (keyof User)[];
}> = ({
  users,
  isPending,
  customers = [],
  onDelete,
  includeLink = true,
  columnsToShow = [
    'customer_id',
    'full_name',
    'email',
    'is_admin',
    'created_date',
    'receive_alarms_email',
  ],
  ...props
}) => {
  const { t } = useTranslation('components');

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<User>();

    return [
      columnHelper.accessor('full_name', {
        id: 'full_name',
        header: () => t('tables.UsersTable.columns.full_name.text'),
        enableColumnFilter: false,
        meta: {
          hidden: !columnsToShow.includes('full_name'),
        },
        cell: ({ getValue, row: { original } }) => {
          const value = getValue();
          if (!includeLink) return <>{value}</>;
          return (
            <div className="flex items-center pt-[5px]">
              <Avatar size="20px" round={true} maxInitials={2} name={value} title={value} />

              <TableLink
                className="ml-2"
                name={value}
                url={`/user/users/${original.id}`}
                datatip={t('tables.UsersTable.columns.full_name.dataTip.1')}
                externalDatatip={t('tables.UsersTable.columns.full_name.dataTip.2')}
              />
            </div>
          );
        },
      }),

      columnHelper.accessor('email', {
        id: 'email',
        header: () => t('tables.UsersTable.columns.email.text'),
        enableColumnFilter: false,
        meta: {
          hidden: !columnsToShow.includes('email'),
        },
        cell: ({ getValue }) => <>{getValue()}</>,
      }),

      columnHelper.accessor('is_admin', {
        id: 'is_admin',
        header: () => t('tables.UsersTable.columns.is_admin.text'),
        size: 65,
        enableColumnFilter: false,
        sortingFn: sortingFnBoolean,
        meta: {
          hidden: !columnsToShow.includes('is_admin'),
        },
        cell: ({ getValue }) => <>{boolean2text(getValue())}</>,
      }),

      columnHelper.accessor('receive_alarms_email', {
        id: 'receive_alarms_email',
        header: () => t('tables.UsersTable.columns.receive_alarms_email.text'),
        size: 65,
        enableColumnFilter: false,
        sortingFn: sortingFnBoolean,
        meta: {
          hidden: !columnsToShow.includes('receive_alarms_email'),
        },
        cell: ({ getValue }) => <>{boolean2text(getValue())}</>,
      }),

      columnHelper.accessor('customer_id', {
        id: 'customer_id',
        header: () => t('tables.UsersTable.columns.customer_id.text'),
        enableColumnFilter: false,
        meta: {
          hidden: !columnsToShow.includes('customer_id'),
        },
        cell: ({ getValue }) => {
          const value = getValue();
          const customer = customers.find(customer => customer.id === value);
          return (
            <TableLink
              name={(customer && customer.name) || value}
              url={`/admin/customers/${value}`}
              datatip={t('tables.UsersTable.columns.customer_id.dataTip.1')}
              externalDatatip={t('tables.UsersTable.columns.customer_id.dataTip.2')}
            />
          );
        },
      }),

      columnHelper.accessor('created_date', {
        id: 'created_date',
        header: () => t('tables.UsersTable.columns.created_date.text'),
        size: 85,
        sortingFn: 'datetime',
        enableColumnFilter: false,
        meta: {
          hidden: !columnsToShow.includes('created_date'),
        },
        cell: ({ getValue }) => (
          <span
            data-tooltip-content={dateToLocaleString(getValue())}
            data-tooltip-id="route-tooltip"
          >
            {dateToPrettyDate(getValue())}
          </span>
        ),
      }),

      columnHelper.accessor('updated_date', {
        id: 'updated_date',
        header: () => t('tables.UsersTable.columns.updated_date.text'),
        enableColumnFilter: false,
        sortingFn: (rowA: any, rowB: any, columnId: string) => {
          const valueA = rowA.getValue(columnId);
          const valueB = rowB.getValue(columnId);
          return valueA > valueB ? -1 : 1;
        },
        meta: {
          hidden: !columnsToShow.includes('updated_date'),
        },
        cell: ({ getValue, row: { original } }) => {
          const value = getValue();
          const val = value ? (
            <span data-tooltip-content={dateToLocaleString(value)} data-tooltip-id="route-tooltip">
              {dateToLocaleString(value)}
            </span>
          ) : (
            ''
          );
          return (
            <div className="flex justify-between">
              <span>{val}</span>
              {onDelete && (
                <Button
                  onClick={event => {
                    event.stopPropagation();
                    onDelete(original);
                  }}
                  size="sm"
                  variant="outline-danger"
                >
                  {t('tables.UsersTable.columns.updated_date.dataTip')}
                </Button>
              )}
            </div>
          );
        },
      }),
    ];
  }, [columnsToShow, t, includeLink, customers, onDelete]);

  return (
    <Table
      showSearchBar
      data={users}
      loading={isPending}
      columns={columns}
      pageSize={5}
      {...props}
    />
  );
};
