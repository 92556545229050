import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Slider, SliderOption } from 'components/inputs/SliderSwitch/components';
import BlueprintViewStateContext, {
  BlueprintViewStateType,
} from 'utils/contexts/blueprint-view-state-context';

export const SliderSwitch = ({ id }: { id: string }) => {
  const { t } = useTranslation('components');

  const { blueprintViewState, setBlueprintViewState } = useContext(BlueprintViewStateContext);

  return (
    <div
      id={id}
      className="flex w-full md:w-1/2 xl:w-1/4 border border-transparent p-0.25 rounded-full relative text-center bg-white shadow-[1px_1px_2px_2px_rgba(0,0,0,0.1)] mt-5"
      data-tooltip-content={t('inputs.SliderSwitch.helperText')}
      data-tooltip-id="route-tooltip"
    >
      <SliderOption
        id={`${BlueprintViewStateType.Moisture}${id}`}
        label={t('inputs.SliderSwitch.moisture')}
        isSelected={blueprintViewState === BlueprintViewStateType.Moisture}
        onClick={() => setBlueprintViewState(BlueprintViewStateType.Moisture)}
      />
      <SliderOption
        id={`${BlueprintViewStateType.SignalStrength}${id}`}
        label={t('inputs.SliderSwitch.signalStrength')}
        isSelected={blueprintViewState === BlueprintViewStateType.SignalStrength}
        onClick={() => setBlueprintViewState(BlueprintViewStateType.SignalStrength)}
      />
      <Slider id={id} selected={blueprintViewState} />
    </div>
  );
};
