import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { parseAlarmNotification } from 'utils/types/AlarmNotification';

const useAlarmAlarmNotifications = (alarmId: string) => {
  const queryKey = ['alarmNotifications', alarmId];

  // Get
  const { data: alarmNotifications, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/alarms/${alarmId}/notifications`),
    select: (alarmNotifications: any[]) => alarmNotifications.map(parseAlarmNotification),
    enabled: !!alarmId,
  });

  return {
    alarmNotifications,
    isPending,
  };
};

export default useAlarmAlarmNotifications;
