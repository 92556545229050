import AlarmRule from 'utils/types/AlarmRule';
import Gateway from 'utils/types/Gateway';
import Sensor from 'utils/types/Sensor';
import User from 'utils/types/User';

// NOTE: The following way of doing it is very inefficient
// export const sensorId2NameFunc = (sensors?: Sensor[]) =>
//   (sensors || []).reduce(
//     (o, sensor) => ({ ...o, [sensor.id]: sensor.name }),
//     {} as { [key: string]: string }
//   );

export const sensorId2NameFunc = (sensors?: Sensor[]) =>
  Object.fromEntries((sensors || []).map(sensor => [sensor.id, sensor.name]));

export const hardwareId2NameFunc = (sensors?: Sensor[]) =>
  Object.fromEntries((sensors || []).map(sensor => [sensor.hardware_id, sensor.name]));
export const hardwareId2DescriptionFunc = (sensors?: Sensor[]) =>
  Object.fromEntries(
    (sensors || []).map(sensor => [sensor.hardware_id, sensor?.geographic_location?.description])
  );

export const hardwareId2SensorIdFunc = (sensors?: Sensor[]) =>
  Object.fromEntries((sensors || []).map(sensor => [sensor.hardware_id, sensor.id]));

export const gatewayId2NameFunc = (gateways?: Gateway[]) =>
  Object.fromEntries((gateways || []).map(gateway => [gateway.id, gateway.name]));

export const alarmRuleId2NameFunc = (alarmRules?: AlarmRule[]) =>
  Object.fromEntries((alarmRules || []).map(alarmRule => [alarmRule.id, alarmRule.name]));

export const userId2NameFunc = (users?: User[]) =>
  Object.fromEntries((users || []).map(user => [user.id, user.full_name]));
