import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { parseAlarm } from 'utils/types/Alarm';

const useAlarm = (alarmId: string) => {
  const queryKey = ['alarms', alarmId];

  // Get
  const { data: alarm, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/alarms/${alarmId}`),
    select: (alarm: any) => parseAlarm(alarm),
    enabled: !!alarmId,
  });

  return {
    alarm,
    isPending,
  };
};

export default useAlarm;
