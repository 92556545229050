import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { parseAlarm } from 'utils/types/Alarm';

const useSensorGroupAlarms = (sensorGroupId?: string) => {
  const queryKey = ['sensorGroupAlarms', sensorGroupId];

  // Get
  const { data: alarms, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/sensor_groups/${sensorGroupId}/alarms`),
    select: (data: any[]) => data.map(parseAlarm),
    enabled: !!sensorGroupId,
  });

  return {
    alarms,
    isPending,
  };
};

export default useSensorGroupAlarms;
