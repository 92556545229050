import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import useCurrentUser from 'utils/hooks/use-current-user';
import { parseTransmissionsMixed } from 'utils/sensor/transmissions';
import { parseSensor } from 'utils/types/Sensor';
import { parseTransmission } from 'utils/types/Transmission';
import { parseTransmissionAnomaly } from 'utils/types/TransmissionAnomaly';

const useAdminInsightsTransmissions = ({
  fromTimestamp,
  toTimestamp,
}: {
  fromTimestamp?: Date;
  toTimestamp?: Date;
}) => {
  const queryKey = ['adminInsightsTransmissions', fromTimestamp, toTimestamp];

  const { isAdmin } = useCurrentUser();

  const { data: sensorsAndTransmissions, isPending } = useQuery({
    queryKey,
    queryFn: () =>
      api.get('/admin/insights/transmissions', {
        params: {
          from_timestamp: fromTimestamp,
          to_timestamp: toTimestamp,
        },
      }),
    select: (sensorsAndTransmissions: any[]) =>
      sensorsAndTransmissions.map(
        ({ sensor: sensorRaw, transmissions: transmissionsRaw, anomalies: anomaliesRaw }: any) => {
          const sensor = parseSensor(sensorRaw);
          const transmissions = transmissionsRaw.map(parseTransmission);
          const anomalies = anomaliesRaw.map(parseTransmissionAnomaly);

          return {
            sensor,
            transmissions: parseTransmissionsMixed({
              transmissions,
              sensors: [sensor],
              anomalies,
            }),
          };
        }
      ),
    enabled: !!fromTimestamp && !!toTimestamp && isAdmin,
  });

  return { sensorsAndTransmissions, isPending };
};

export default useAdminInsightsTransmissions;
