import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import HookOptions from 'utils/types/HookOptions';
import { parseUser } from 'utils/types/User';

const useUserUsers = (userId?: string, { enableGet = true }: HookOptions = {}) => {
  const { data: users, isPending } = useQuery({
    queryKey: ['userUsers', userId],
    queryFn: () => api.get(`/users/${userId}/users`),
    select: (users: any[]) => users.map(parseUser),
    enabled: !!userId && enableGet,
  });

  return {
    users,
    isPending,
  };
};

export default useUserUsers;
