type SalesOrder = {
  id: string;

  airtable_sales_order_id: string;
  airtable_sales_order_name: string;

  sensors_selected: string;
  gateways_selected: string;
  sensors_product_name: string;
  gateways_product_name: string;

  customer_id: string;
  customer_email: string;
  user_email_invited?: string;

  postnord_tracking_number?: string;
  ups_tracking_number?: string;

  sensor_group_id: string;

  created_date: Date;
  created_by: string;
};

export const parseSalesOrder = (salesOrder: any): SalesOrder => {
  salesOrder.created_date = new Date(salesOrder.created_date);

  return salesOrder as SalesOrder;
};

export default SalesOrder;
