import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { parseDocumentChange } from 'utils/types/DocumentChange';
import HookOptions from 'utils/types/HookOptions';

const useGatewayChanges = (gatewayId: string, { enableGet = true }: HookOptions = {}) => {
  const queryKey = ['gatewayChanges', gatewayId];

  // Get
  const { data: changes, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/gateways/${gatewayId}/changes`),
    select: (data: any[]) => data.map(parseDocumentChange),
    enabled: enableGet && !!gatewayId,
  });

  return {
    changes,
    isPending,
  };
};

export default useGatewayChanges;
