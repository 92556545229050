import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { useCurrentUser } from 'utils/hooks';

const useAdminSalesOrders = ({
  sensorsCount,
  gatewaysCount,
  customerName,
  locale,
  postnordTrackingNumber,
  upsTrackingNumber,
  inviteUserEmail,
}: {
  sensorsCount: number;
  gatewaysCount: number;
  customerName: string;
  locale: string;
  postnordTrackingNumber?: string;
  upsTrackingNumber?: string;
  inviteUserEmail?: string;
}) => {
  const queryKey = [
    'adminSalesOrderMailText',
    sensorsCount,
    gatewaysCount,
    customerName,
    locale,
    postnordTrackingNumber,
    upsTrackingNumber,
    inviteUserEmail,
  ];

  const { isAdmin } = useCurrentUser();

  // Get sales orders
  const { data: salesOrderMailText } = useQuery({
    queryKey,
    queryFn: () =>
      api.get('/admin/sales_orders_mail_html', {
        params: {
          sensors_count: sensorsCount,
          gateways_count: gatewaysCount,
          customer_name: customerName,
          locale,
          postnord_tracking_number: postnordTrackingNumber,
          ups_tracking_number: upsTrackingNumber,
          invite_user_email: inviteUserEmail,
        },
        _disableErrorNotifications: true,
      }),
    select: (mailDetails: any) =>
      mailDetails as {
        first_name: string;
        greeting: string;
        content_html: string;
        content_html_including_greeting: string;
        subject: string;
      },
    enabled: isAdmin,
  });

  return {
    salesOrderMailText,
  };
};

export default useAdminSalesOrders;
