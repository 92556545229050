import classNames from 'classnames';
import { ComponentPropsWithRef } from 'react';

import { Button, Variant } from 'components';
import Spinner from 'components/Spinner';

export const SubmittingButton: React.FC<
  {
    buttonText: string;
    submittingText?: string;
    submitting: boolean;
    disabled?: boolean;
    variant?: Variant;
    size?: 'sm' | 'md' | 'lg';
  } & ComponentPropsWithRef<'button'>
> = ({ buttonText, submittingText, variant = 'primary', submitting, className, ...props }) => (
  <Button variant={variant} className={classNames(className)} type="submit" {...props}>
    {submitting ? (
      <div className="flex items-center gap-1">
        <Spinner />
        {submittingText}
      </div>
    ) : (
      buttonText
    )}
  </Button>
);
