import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import api from 'utils/api';
import { useCurrentUser } from 'utils/hooks';
import {
  parseAirtableSalesOrder,
  parseAirtableProductInventory,
  parseAirtableProject,
} from 'utils/types/Airtable';

const useAdminAirtable = () => {
  const { isAdmin } = useCurrentUser();

  const queryClient = useQueryClient();

  // Get sales orders
  const { data: fetchedAirtableSalesOrders, refetch: refetchSalesOrders } = useQuery({
    queryKey: ['adminAirtableSalesOrders'],
    queryFn: () => api.get('/admin/airtable/sales_orders'),
    select: (salesOrders: any[]) => salesOrders.map(parseAirtableSalesOrder),
    enabled: isAdmin,
  });

  // Update sales order in airtable
  const { mutateAsync: updateAirtableSalesOrderById } = useMutation({
    mutationFn: async ({
      id,
      status,
    }: {
      id: string;
      status: 'Being prepared' | 'Created' | 'Prepare order' | 'Sensors shipped';
    }) => {
      const salesOrders: any[] = await api.patch(`/admin/airtable/sales_orders/${id}`, {
        status,
      });
      return salesOrders.map(parseAirtableSalesOrder);
    },
    onSuccess: async salesOrders => {
      queryClient.setQueryData(['adminAirtableSalesOrders'], salesOrders);
    },
  });

  // Get product inventory
  const { data: airtableProductInventories, refetch: refetchProductInventories } = useQuery({
    queryKey: ['adminAirtableProductInventories'],
    queryFn: () => api.get('/admin/airtable/product_inventories'),
    select: (productInventories: any[]) => productInventories.map(parseAirtableProductInventory),
    enabled: isAdmin,
  });

  // Get projects
  const { data: airtableProjects, refetch: refetchProjects } = useQuery({
    queryKey: ['adminAirtableProjects'],
    queryFn: () => api.get('/admin/airtable/projects'),
    select: (projects: any[]) => projects.map(parseAirtableProject),
    enabled: isAdmin,
  });

  const airtableSalesOrders = useMemo(
    () =>
      // Convert Project ID to Project Name
      (fetchedAirtableSalesOrders || []).map(salesOrder => {
        if (salesOrder.fields['Project name'] && salesOrder.fields['Project name'].length > 0) {
          const projectId = salesOrder.fields['Project name'][0];
          const project = airtableProjects?.find(x => x.id === projectId);
          salesOrder.fields['Project name'] = project ? project.fields['Name'] : projectId;
        }
        return salesOrder;
      }),
    [fetchedAirtableSalesOrders, airtableProjects],
  );

  return {
    airtableProjects,
    airtableProductInventories,
    airtableSalesOrders,
    updateAirtableSalesOrderById,
    refetchSalesOrders,
    refetchProductInventories,
    refetchProjects,
  };
};

export default useAdminAirtable;
