import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
// import TagManager from 'react-gtm-module';
import { RouterProvider } from 'react-router-dom';

import * as serviceWorker from 'serviceWorker';
import environment, { commit } from 'utils/environment';
import applyFixes from 'utils/fixes';
import isRunningLocally from 'utils/locally';
import router from 'utils/ws-router';

applyFixes();

if (module.hot) {
  module.hot.accept();
}

if (!isRunningLocally) {
  // Init Sentry
  Sentry.init({
    dsn: 'https://524d0e42811047928a849345bd815722@o974608.ingest.us.sentry.io/5930321',
    integrations: [Sentry.browserTracingIntegration()],
    environment,
    debug: false, // Will log sentry debug info to Console
    release: commit,
    ignoreErrors: [
      'Request failed with status code 401', // Ignore 401 Unauthorized errors
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // NOTE: 0.2 is taken from https://docs.sentry.io/platforms/javascript/performance/
    tracesSampleRate: environment === 'production' ? 0.2 : 1.0,
  });

  // TODO: Update `gtmId`
  // Init Google Tag Manager
  // TagManager.initialize({
  //   gtmId: 'GTM-M4DB5RV',
  // });
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<RouterProvider router={router} />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
