import { faExclamationCircle, faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { Button } from 'components';
import SensorTagsModal from 'components/modals/SensorTagsModal';
import SensorTagsEnvironment from 'components/sensors/tags/SensorTagsEnvironment';
import SensorTagsPrimaryPlacement from 'components/sensors/tags/SensorTagsPrimaryPlacement';
import SensorTagsRoomAndOrientation from 'components/sensors/tags/SensorTagsRoomAndOrientation';
import SensorTagsTheSensor from 'components/sensors/tags/SensorTagsTheSensor';
import { useSensorTags } from 'utils/hooks/data';
import SensorTags, { anySensorTagsDefined } from 'utils/types/SensorTags';

const SensorTagsAll: React.FC<
  {
    sensorTags?: SensorTags;
    loading?: boolean;
    showPrimaryPlacementTags?: boolean;
    showEnvironmentTags?: boolean;
    showRoomAndOrientationTags?: boolean;
    showTheSensorTags?: boolean;
    sensorId?: string;
    showEditButton?: boolean;
    openModalOnClick?: boolean;
  } & Pick<HTMLAttributes<HTMLDivElement>, 'className'>
> = ({
  sensorTags,
  loading,
  showPrimaryPlacementTags = false,
  showEnvironmentTags = false,
  showRoomAndOrientationTags = false,
  showTheSensorTags = false,
  showEditButton = false,
  className,
  sensorId,
  openModalOnClick = false,
}) => {
  const [show, setShow] = useState(false);
  const [useColors, setUseColors] = useState(false);

  const { t } = useTranslation();

  const { updateSensorTags } = useSensorTags(sensorId);

  const onClick = openModalOnClick ? async () => setShow(true) : undefined;

  return loading ? (
    <Skeleton height={35} duration={1} />
  ) : (
    <div
      className="flex justify-start gap-2 items-center"
      onMouseEnter={() => setUseColors(true)}
      onMouseLeave={() => setUseColors(false)}
    >
      <div className="flex flex-wrap gap-2">
        {showPrimaryPlacementTags && (
          <SensorTagsPrimaryPlacement
            className={className}
            sensorTagPrimaryElementCategory={sensorTags?.primary_element_category}
            sensorTagPrimaryElementType={sensorTags?.primary_element_type}
            sensorTagComplementaryElement={sensorTags?.complementary_element}
            onClick={onClick}
            useColors={useColors}
          />
        )}

        {showEnvironmentTags && (
          <SensorTagsEnvironment
            className={className}
            sensorTagClimate={sensorTags?.climate}
            sensorTagConstructionPhase={sensorTags?.construction_phase}
            sensorTagInsulation={sensorTags?.insulation}
            sensorTagVentilation={sensorTags?.ventilation}
            onClick={onClick}
            useColors={useColors}
          />
        )}

        {showRoomAndOrientationTags && (
          <SensorTagsRoomAndOrientation
            className={className}
            sensorTagRoomType={sensorTags?.room_type}
            sensorTagOrientation={sensorTags?.orientation}
            sensorTagVerticalPlacement={sensorTags?.vertical_placement}
            onClick={onClick}
            useColors={useColors}
          />
        )}

        {showTheSensorTags && (
          <SensorTagsTheSensor
            className={className}
            sensorTagCasing={sensorTags?.casing}
            sensorTagScrewType={sensorTags?.screw_type}
            sensorTagUsage={sensorTags?.usage}
            onClick={onClick}
            useColors={useColors}
          />
        )}
      </div>

      {showEditButton && (
        <>
          <Button
            data-tooltip-content={
              anySensorTagsDefined(sensorTags)
                ? t('components:sensors.tags.sensorTagsAll.buttonText')
                : null
            }
            data-tooltip-id="route-tooltip"
            variant="outline-secondary"
            onClick={() => setShow(true)}
          >
            {anySensorTagsDefined(sensorTags) ? (
              <FontAwesomeIcon icon={faPen} />
            ) : (
              <>
                <FontAwesomeIcon className="text-brand-green mr-2" icon={faExclamationCircle} />
                {t('components:sensors.tags.sensorTagsAll.noTagsButtonText')}
              </>
            )}
          </Button>

          <SensorTagsModal
            onSubmit={async values => {
              await updateSensorTags(values);
              setShow(false);
            }}
            setShow={setShow}
            show={show}
            sensorTags={sensorTags}
            sensorId={sensorId}
            showCopyFromSensorButton
          />
        </>
      )}
    </div>
  );
};

export default SensorTagsAll;
