import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { useCurrentUser } from 'utils/hooks';
import { parseSensor } from 'utils/types/Sensor';

const useAdminSensors = () => {
  const queryKey = ['adminSensors'];

  const { isAdmin } = useCurrentUser();

  // Get
  const { data: sensors, isPending } = useQuery({
    queryKey,
    queryFn: () =>
      api.get('/admin/sensors', {
        params: { include_demo_data: true, include_thumbnails: false },
      }),
    select: (sensors: any[]) => sensors.map(parseSensor),
    enabled: isAdmin,
  });

  return {
    sensors,
    isPending,
  };
};

export default useAdminSensors;
