import useSupportTickets from 'utils/hooks/data/use-support-tickets';

const useAdminSupportTickets = () =>
  useSupportTickets({
    queryKey: ['supportTickets'],
    endpoint: '/admin/support_tickets',
    enabled: true,
  });

export default useAdminSupportTickets;
