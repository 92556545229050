import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { faWsTransmissions } from 'components/icons';
import { dateToLocaleString, dateToPrettyDate } from 'utils/date';

const LatestTransmissionStatus: React.FC<
  {
    latestTransmissionReceivedAt?: Date;
    classNameText?: string;
  } & Omit<FontAwesomeIconProps, 'icon'>
> = ({
  latestTransmissionReceivedAt,
  className = 'text-base',
  classNameText = 'text-sm',
  ...props
}) => {
  const { t } = useTranslation('components');

  return (
    <div
      className="flex"
      data-tooltip-content={
        latestTransmissionReceivedAt
          ? t('signals.LatestTransmissionStatus.dataTip.1', {
              receivedAt: dateToLocaleString(latestTransmissionReceivedAt),
            })
          : t('signals.LatestTransmissionStatus.dataTip.2')
      }
      data-tooltip-id="route-tooltip"
    >
      <FontAwesomeIcon
        className={classNames('mr-2 relative top-[1px]', className)}
        icon={faWsTransmissions}
        {...props}
      />
      <span className={classNameText}>{dateToPrettyDate(latestTransmissionReceivedAt)}</span>
    </div>
  );
};

export default LatestTransmissionStatus;
