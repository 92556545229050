import { faMinusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table, TableLink } from 'components';
import { dateToLocaleString, dateToPrettyDate } from 'utils/date';
import Customer from 'utils/types/Customer';

export const CustomersTable: React.FC<{
  customers: Customer[];
  onClick?: (row: Customer) => void;
  onDelete?: (row: Customer) => void;
  columnsToShow?: (keyof Customer)[];
  showSearchBar?: boolean;
  showAdminLink?: boolean;
}> = ({
  customers,
  onClick,
  onDelete,
  columnsToShow = ['name', 'logo_url', 'created_date'],
  showSearchBar = true,
  showAdminLink = false,
  ...props
}) => {
  const { t } = useTranslation('components');

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<Customer>();

    return [
      columnHelper.accessor('name', {
        id: 'name',
        header: () => t('tables.CustomersTable.columns.name.text'),
        enableColumnFilter: false,
        meta: {
          hidden: !columnsToShow.includes('name'),
        },
        cell: ({ getValue, row: { original } }) => (
          <TableLink
            name={getValue()}
            url={
              showAdminLink
                ? `/admin/customers/${original.id}`
                : `/user/organizations/${original.id}`
            }
            datatip={t('tables.CustomersTable.columns.name.dataTip.1')}
            externalDatatip={t('tables.CustomersTable.columns.name.dataTip.2')}
          />
        ),
      }),

      columnHelper.accessor('logo_url', {
        id: 'logo_url',
        header: () => t('tables.CustomersTable.columns.logo_url.text'),
        enableColumnFilter: false,
        meta: {
          hidden: !columnsToShow.includes('logo_url'),
        },
        cell: ({ getValue, row: { original } }) => (
          <a href={getValue()} target="_blank" rel="noopener noreferrer">
            <img
              style={{ maxHeight: '28px', maxWidth: '120px' }}
              src={getValue()}
              alt={original.name}
            />
          </a>
        ),
      }),

      columnHelper.accessor('demo_application_id', {
        id: 'demo_application_id',
        header: () => t('tables.CustomersTable.columns.demo_application_id.text'),
        enableColumnFilter: false,
        meta: {
          hidden: !columnsToShow.includes('logo_url'),
        },
        cell: ({ getValue, row: { original } }) => (
          <TableLink
            name={getValue()}
            url={`/admin/demo_applications/${getValue()}`}
            datatip={t('tables.CustomersTable.columns.demo_application_id.dataTip.1')}
            externalDatatip={t('tables.CustomersTable.columns.demo_application_id.dataTip.2')}
          />
        ),
      }),

      columnHelper.accessor('created_date', {
        id: 'created_date',
        header: () => t('tables.CustomersTable.columns.created_date.text'),
        sortingFn: 'datetime',
        enableColumnFilter: false,
        meta: {
          hidden: !columnsToShow.includes('logo_url'),
        },
        cell: ({
          getValue,
          cell: {
            row: { original },
          },
        }) => (
          <>
            <span
              data-tooltip-content={dateToLocaleString(getValue())}
              data-tooltip-id="route-tooltip"
            >
              {dateToPrettyDate(getValue())}
            </span>
            {onDelete && (
              <FontAwesomeIcon
                className="text-brand-orange float-right cursor-pointer"
                icon={faMinusSquare}
                size="lg"
                data-tooltip-content={t('tables.CustomersTable.columns.created_date.dataTip')}
                data-tooltip-id="route-tooltip"
                onClick={event => {
                  event.stopPropagation();
                  onDelete(original);
                }}
              />
            )}
          </>
        ),
      }),
    ];
  }, [columnsToShow, t, showAdminLink, onDelete]);

  return (
    <Table
      data={customers}
      columns={columns}
      showSearchBar={showSearchBar}
      placeholder={t('tables.CustomersTable.search.placeholder')}
      pageSize={5}
      onClick={onClick}
      noDataPlaceholder={t('tables.CustomersTable.noDataIndication')}
      {...props}
    />
  );
};
