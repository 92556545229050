import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { useCurrentUser } from 'utils/hooks';
import { parseDeviceLoRaDetails } from 'utils/types/DeviceLoRaDetails';
import HookOptions from 'utils/types/HookOptions';

const useAdminSensorLoRaDetails = (sensorId?: string, { enableGet = true }: HookOptions = {}) => {
  const queryKey = ['adminSensorLoRaDetails', sensorId];

  const { isAdmin } = useCurrentUser();

  // Sensor Changes - Get
  const { data: deviceLoRaDetails, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/admin/sensors/${sensorId}/lora_details`),
    select: (data: any) => parseDeviceLoRaDetails(data),
    enabled: isAdmin && enableGet && !!sensorId,
  });

  return {
    deviceLoRaDetails,
    isPending,
  };
};

export default useAdminSensorLoRaDetails;
