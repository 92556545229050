import { useCurrentUserCustomers } from 'utils/hooks/data/current-user';

const useDemoApplicationCustomer = (demoApplicationId?: string) => {
  const { customers, isPending } = useCurrentUserCustomers();

  const customer = customers?.find(customer => customer.demo_application_id === demoApplicationId);

  return {
    customer,
    isPending,
  };
};

export default useDemoApplicationCustomer;
