import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { useQueryCreate } from 'utils/queries/create';
import HookOptions from 'utils/types/HookOptions';
import { parseNote } from 'utils/types/Note';

const useSensorNotes = (
  sensorId?: string,
  { includeInternalNotes = false }: { includeInternalNotes?: boolean } = {},
  { enableGet = true }: HookOptions = {}
) => {
  // Sensor Notes - Get
  const { data: notes, isPending } = useQuery({
    queryKey: ['sensorNotes', sensorId],
    queryFn: () =>
      api.get(`/sensors/${sensorId}/notes`, {
        params: { include_internal: includeInternalNotes },
      }),
    select: (data: any[]) => data.map(parseNote),
    enabled: enableGet && !!sensorId,
  });

  // Sensor Notes - Create
  const { mutateAsync: createNote } = useQueryCreate({
    api,
    queryKey: ['sensorNotes', sensorId],
    endpoint: `/sensors/${sensorId}/notes`,
    parseFn: parseNote,
    invalidateQueryKeys: [
      ['sensorNotes', sensorId],
      ['sensorInternalNotes', sensorId],
    ],
  });

  // Sensor Notes (internal) - Create
  const { mutateAsync: createInternalNote } = useQueryCreate({
    api,
    queryKey: ['sensorInternalNotes', sensorId],
    endpoint: `/admin/sensors/${sensorId}/internal_notes`,
    parseFn: parseNote,
    invalidateQueryKeys: [
      ['sensorNotes', sensorId],
      ['sensorInternalNotes', sensorId],
    ],
  });

  return {
    notes,
    createNote,
    createInternalNote,
    isPending,
  };
};

export default useSensorNotes;
