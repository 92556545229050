import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import api from 'utils/api';
import { floorToLatest5Minutes } from 'utils/date';
import HookOptions from 'utils/types/HookOptions';
import TransmissionForecast from 'utils/types/TransmissionForecast';

export const parseForecast = (forecast: any): TransmissionForecast => {
  forecast.timestamp = new Date(forecast.timestamp);
  return forecast;
};

const useSensorForecasts = (
  sensorId: string,
  {
    fromTimestamp,
    toTimestamp,
  }: {
    fromTimestamp: Date;
    toTimestamp: Date;
  },
  { enableGet = true }: HookOptions = {}
) => {
  fromTimestamp = floorToLatest5Minutes(fromTimestamp);
  toTimestamp = floorToLatest5Minutes(toTimestamp);

  const queryKey = ['sensorForecasts', sensorId, fromTimestamp, toTimestamp];

  const { data, isPending } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/sensors/${sensorId}/transmissions/forecasts/`, {
        params: { from_timestamp: fromTimestamp, to_timestamp: toTimestamp },
      }),
    select: (data: any[]) => data.map(parseForecast),
    enabled: !!sensorId && enableGet,
  });

  const transmissionForecasts = useMemo(() => (enableGet ? data : undefined), [data, enableGet]);

  return {
    transmissionForecasts,
    isPending,
  };
};

export default useSensorForecasts;
