import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from 'utils/api';
import Sensor, { parseSensor } from 'utils/types/Sensor';

const useSensors = () => {
  const queryKey = ['sensors'];

  const queryClient = useQueryClient();

  // Update by ID
  const { mutateAsync: updateSensorById } = useMutation({
    mutationFn: ({ id: sensorId, ...props }: Partial<Sensor>) =>
      api.put(`/sensors/${sensorId}`, props),
    onSuccess: (sensorRaw: any) => {
      const sensor = parseSensor(sensorRaw);
      queryClient.setQueryData(['sensors', sensor.id], sensor);
      queryClient.invalidateQueries({
        queryKey: ['sensors'],
        exact: true,
      });
      queryClient.invalidateQueries({
        queryKey: ['sensorGroupSensors'],
      });
      queryClient.invalidateQueries({
        queryKey: ['customerSensors'],
      });
      queryClient.invalidateQueries({
        queryKey: ['gatewaySensors'],
      });
      queryClient.invalidateQueries({
        queryKey: ['blueprintSensors'],
      });
      queryClient.invalidateQueries({
        queryKey: ['reportRevisionSensors'],
      });
    },
  });

  // Delete
  const { mutateAsync: deleteSensorById } = useMutation({
    mutationFn: (sensorId: string) => api.delete(`/sensors/${sensorId}`),
    onSuccess: (result, sensorId, context) => {
      queryClient.invalidateQueries({
        queryKey,
        exact: true,
      });
      queryClient.removeQueries({
        queryKey: ['sensors', sensorId],
      });
    },
  });

  return {
    updateSensorById,
    deleteSensorById,
  };
};

export default useSensors;
