import React, { Dispatch, SetStateAction } from 'react';

export enum BlueprintViewStateType {
  Moisture = 'moisture',
  SignalStrength = 'signal_strength',
}

export type BlueprintViewStateContextProps = {
  blueprintViewState: BlueprintViewStateType;
  setBlueprintViewState: Dispatch<SetStateAction<BlueprintViewStateType>>;
};

const BlueprintViewStateContext = React.createContext<BlueprintViewStateContextProps>({
  blueprintViewState: BlueprintViewStateType.Moisture,
  setBlueprintViewState: () => {},
});

export default BlueprintViewStateContext;
