import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { useCurrentUser } from 'utils/hooks';
import { parseAlarm } from 'utils/types/Alarm';
import HookOptions from 'utils/types/HookOptions';

const useAdminAlarms = ({ limit = 1000, offset = 0 }: HookOptions = {}) => {
  const queryKey = ['adminAlarms'];

  const { isAdmin } = useCurrentUser();

  // Get
  const { data: alarms, isPending } = useQuery({
    queryKey,
    queryFn: () =>
      api.get('/admin/alarms', {
        params: { limit, offset },
      }),
    select: (alarms: any[]) => alarms.map(parseAlarm),
    enabled: isAdmin,
  });

  return {
    alarms,
    isPending,
  };
};

export default useAdminAlarms;
