import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';

import api from 'utils/api';
import queryClient from 'utils/queries/client';
import { useQueryUpdate } from 'utils/queries/update';
import Customer, { allCustomer, parseCustomer } from 'utils/types/Customer';
import CustomerSettings from 'utils/types/CustomerSettings';
import HookOptions from 'utils/types/HookOptions';

const useCustomer = (
  customerId?: string,
  {
    enableGet = true,
    disableProgressBar = false,
    disableErrorNotifications = false,
    disableRedirects = false,
  }: HookOptions = {},
) => {
  const queryKey = ['customers', customerId];

  const showAllCustomers = customerId === allCustomer.id;

  // Get
  const { data, isPending, isFetching, error } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/customers/${customerId}`, {
        _disableProgressBar: disableProgressBar,
        _disableErrorNotifications: disableErrorNotifications,
        _disableRedirects: disableRedirects,
      }),
    select: (customer: any) => (customer ? parseCustomer(customer) : undefined),
    enabled: enableGet && !!customerId && !showAllCustomers,
  });

  const fetchStatusCode =
    error && axios.isAxiosError(error) ? (error?.request?.status as number) : undefined;

  const customer = showAllCustomers ? allCustomer : data;

  // Update
  const { mutateAsync: updateCustomer } = useQueryUpdate<Customer>({
    api,
    queryKey,
    endpoint: `/customers/${customerId}`,
    invalidateQueryKeys: [queryKey.slice(0, 1)],
    parseFn: parseCustomer,
  });

  // Update settings
  const { mutateAsync: updateCustomerSettings } = useMutation({
    mutationFn: (values: Partial<CustomerSettings>) =>
      api.patch(`/customers/${customerId}/settings`, values),
    onSuccess: data => {
      queryClient.setQueryData(queryKey, data);
    },
  });

  // Delete
  const { mutateAsync: deleteCustomer } = useMutation({
    mutationKey: queryKey,
    mutationFn: () => api.delete(`/customers/${customerId}`),
    onSuccess: async () => queryClient.invalidateQueries({ queryKey: queryKey.slice(0, 1) }),
  });

  return {
    customer,
    isPending,
    isFetching,
    updateCustomer,
    updateCustomerSettings,
    deleteCustomer,
    fetchStatusCode,
  };
};

export default useCustomer;
