import classNames from 'classnames';
import { useMemo, useRef, useState, Dispatch, SetStateAction, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import { useOnClickOutside } from 'utils/hooks';
import { notificationWarningConfirm } from 'utils/notifications';

const Modal: React.FC<
  React.PropsWithChildren<
    {
      title: string | JSX.Element;
      show: boolean;
      setShow: Dispatch<SetStateAction<boolean>> | ((accepted: boolean) => void);
      animation?: boolean;
      footer?: JSX.Element | string;
      size?: 'sm' | 'md';
      hasUnsavedChanges?: () => boolean;
      closeOnClickOutside?: boolean;
    } & Omit<HTMLAttributes<HTMLDivElement>, 'title'>
  >
> = ({
  title,
  show,
  setShow,
  className,
  children,
  footer,
  size = 'md',
  hasUnsavedChanges,
  closeOnClickOutside = true,
  ...props
}) => {
  const controlId = useMemo(() => Math.random().toString(36).substring(7), []);

  const [awaitingConfirmation, setAwaitingConfirmation] = useState(false);

  const { t } = useTranslation('components');

  const modalRoot = useRef<Element>(document.getElementById('modal-root'));
  const ref = useRef<HTMLDivElement>(null);

  const close = async () => {
    if (hasUnsavedChanges instanceof Function && hasUnsavedChanges()) {
      setAwaitingConfirmation(true);

      // Prompt user before closing
      const confirmed = await notificationWarningConfirm({
        text: t('modals.Modal.close.text'),
        cancelButtonText: t('modals.Modal.close.cancelButtonText'),
        confirmButtonText: t('modals.Modal.close.confirmButtonText'),
      });

      setAwaitingConfirmation(false);
      if (!confirmed) return;
    }
    setShow(false);
  };

  useOnClickOutside(ref, () => closeOnClickOutside && !awaitingConfirmation && close());

  return modalRoot.current
    ? ReactDOM.createPortal(
        <>
          {show && (
            <div
              id={controlId}
              className={classNames(
                'overflow-x-hidden bg-brand-gray bg-opacity-50 fixed inset-0 z-50 justify-center items-center flex',
              )}
              {...props}
            >
              <div
                ref={ref}
                className={classNames('relative p-4', {
                  'w-full max-w-5xl': size === 'md',
                  'w-fit': size === 'sm',
                })}
              >
                <div className="relative bg-white rounded-lg shadow max-h-[90vh]">
                  <div className="flex justify-between px-7 py-6 border-b border-brand-gray-light-4">
                    <h1 className="grow">{title}</h1>
                    <button
                      onClick={() => close()}
                      type="button"
                      className="grow-0 flex-none bg-transparent rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                      data-modal-toggle={controlId}
                      data-testid="close-btn"
                    >
                      <svg
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className={classNames(className, 'px-7 py-6 overflow-y-auto max-h-[70vh]')}>
                    {children}
                  </div>
                  {footer && (
                    <div className="px-7 py-2.5 border-t border-brand-gray-light-4">{footer}</div>
                  )}
                </div>
              </div>
            </div>
          )}
        </>,
        modalRoot.current,
      )
    : null;
};

export default Modal;
