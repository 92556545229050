import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { parseDocumentChange } from 'utils/types/DocumentChange';
import HookOptions from 'utils/types/HookOptions';

const useUserDocumentChanges = (userId?: string, { limit, offset }: HookOptions = {}) => {
  const queryKey = ['userDocumentChanges', userId, limit, offset];

  // Get
  const { data: documentChanges, isPending } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/users/${userId}/document_changes`, {
        params: {
          limit,
          offset,
        },
      }),
    select: (documentChanges: any[]) => documentChanges.map(parseDocumentChange),
    enabled: !!userId,
  });

  return {
    documentChanges,
    isPending,
  };
};

export default useUserDocumentChanges;
