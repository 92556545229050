import { t } from 'translations';
import { SensorTagPrimaryElementCategory } from 'utils/enums/sensor-tags';

const getSensorTagPrimaryElementCategoryText = (
  sensorTagPrimaryElementCategory: SensorTagPrimaryElementCategory
) => {
  switch (sensorTagPrimaryElementCategory) {
    case SensorTagPrimaryElementCategory.ExternalWalls:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementCategoryText.ExternalWalls');
    case SensorTagPrimaryElementCategory.InteriorWalls:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementCategoryText.InteriorWalls');
    case SensorTagPrimaryElementCategory.Slabs:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementCategoryText.Slabs');
    case SensorTagPrimaryElementCategory.StairsAndRamps:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementCategoryText.StairsAndRamps');
    case SensorTagPrimaryElementCategory.LoadBearingBeamsAndColumns:
      return t(
        'utils:texts.sensor-tags.getSensorTagPrimaryElementCategoryText.LoadBearingBeamsAndColumns'
      );
    case SensorTagPrimaryElementCategory.BalconiesAndExteriorHallways:
      return t(
        'utils:texts.sensor-tags.getSensorTagPrimaryElementCategoryText.BalconiesAndExteriorHallways'
      );
    case SensorTagPrimaryElementCategory.Roofs:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementCategoryText.Roofs');
    case SensorTagPrimaryElementCategory.OtherPrimary:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementCategoryText.OtherPrimary');
    default:
      return 'n/a';
  }
};

export const getSensorTagPrimaryElementCategoryTextShort = getSensorTagPrimaryElementCategoryText;

export default getSensorTagPrimaryElementCategoryText;
