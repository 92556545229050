import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Konva from 'konva';
import { MutableRefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Label, Tag, Text, Stage, Layer } from 'react-konva/lib/ReactKonvaCore';
import 'konva/lib/shapes/Text';
import 'konva/lib/shapes/Label';
import 'konva/lib/shapes/Image';

import {
  MAX_SCALE,
  MIN_SCALE,
  PERFECT_DRAW_ENABLED,
  SCALE_STEPS,
} from 'components/CanvasStage/CanvasStage';
import { ImageSize, LabelProps, StageState } from 'components/CanvasStage/types';
import { isMac } from 'utils/browsers';
import environment from 'utils/environment';
import { useIsPressingModKey } from 'utils/hooks';
import { Environment, isFeatureEnabled } from 'utils/isFeatureEnabled';

export type MainStageProps = {
  stageRef: React.MutableRefObject<Konva.Stage> | undefined;
  backgroundImage: HTMLImageElement | undefined;
  stageHeight: number;
  stageState: StageState;
  enableWheelScrolling?: boolean;
  enableDragging?: boolean;
  grayscale: boolean;
  imageSize: ImageSize;
  imageConfigs?: Konva.ImageConfig[];
  labelConfigs?: LabelProps[];
  setStageState: React.Dispatch<React.SetStateAction<StageState>>;
  onClick: (({ evt, target }: Konva.KonvaEventObject<MouseEvent>) => void) | undefined;
};

export const MainStage: React.FC<MainStageProps> = ({
  stageRef,
  backgroundImage,
  stageHeight,
  stageState,
  enableWheelScrolling = false,
  enableDragging = false,
  grayscale,
  imageSize,
  imageConfigs,
  labelConfigs,
  setStageState,
  onClick,
}) => {
  const backgroundImageRef = useRef<Konva.Image>() as MutableRefObject<Konva.Image> | undefined;

  const { t } = useTranslation('components');
  const isPressingModKey = useIsPressingModKey();

  const handleWheel = ({ evt, target }: Konva.KonvaEventObject<WheelEvent>) => {
    if (!isPressingModKey) return;
    evt.preventDefault();

    const scaleBy = SCALE_STEPS;
    const stage = target.getStage();
    if (!stage) return;

    const oldScale = stage?.scaleX();
    const pointerPosition = stage.getPointerPosition()!;
    const mousePointTo = {
      x: pointerPosition.x / oldScale - stage.x() / oldScale,
      y: pointerPosition.y / oldScale - stage.y() / oldScale,
    };

    const newScale = evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

    if (newScale < MIN_SCALE || MAX_SCALE < newScale) return;

    setStageState({
      scale: newScale,
      x: -(mousePointTo.x - pointerPosition.x / newScale) * newScale,
      y: -(mousePointTo.y - pointerPosition.y / newScale) * newScale,
    });
  };

  // Handle dragging (panning)
  const handleDrag = ({ evt, target }: Konva.KonvaEventObject<DragEvent>) => {
    evt.preventDefault();

    const stage = target.getStage();
    if (!stage) return;

    const scale = stage?.scaleX();
    const pointerPosition = stage.getPointerPosition()!;
    const mousePointTo = {
      x: pointerPosition.x / scale - stage.x() / scale,
      y: pointerPosition.y / scale - stage.y() / scale,
    };

    setStageState({
      scale,
      x: -(mousePointTo.x - pointerPosition.x / scale) * scale,
      y: -(mousePointTo.y - pointerPosition.y / scale) * scale,
    });
  };

  // Scale image and label position and sizes
  const scaledImages = constructScaledImages(imageConfigs, stageState, imageSize);
  const scaledLabels = constructScaledLabels(labelConfigs, stageState, imageSize);

  const isToggleBlueprintColorEnabled = isFeatureEnabled(
    'toggleBlueprintColor',
    environment as Environment,
  );

  const imageSizeIsValid = imageSize.height !== 0 && imageSize.width !== 0;

  // Cache background image
  if (imageSizeIsValid && backgroundImageRef?.current && !backgroundImageRef?.current?.isCached()) {
    backgroundImageRef.current.cache({ pixelRatio: 1 });
  }

  return (
    <div className="relative">
      <Stage
        height={stageHeight}
        ref={stageRef}
        onWheel={enableWheelScrolling ? handleWheel : undefined}
        onDragEnd={handleDrag}
        scaleX={stageState.scale}
        scaleY={stageState.scale}
        x={stageState.x}
        y={stageState.y}
        draggable={enableDragging}
      >
        <Layer>
          <Image
            class
            ref={backgroundImageRef}
            filters={
              isToggleBlueprintColorEnabled && grayscale ? [Konva.Filters.Grayscale] : undefined
            }
            perfectDrawEnabled={PERFECT_DRAW_ENABLED}
            image={backgroundImage}
            onClick={onClick}
          />

          {imageSizeIsValid && scaledImages}
          {imageSizeIsValid && scaledLabels}
        </Layer>
      </Stage>
      <p
        className={classNames('absolute bottom-1 text-center w-full text-sm pointer-events-none', {
          'text-brand-gray-light-2': !isPressingModKey,
          hidden: isPressingModKey,
        })}
      >
        <FontAwesomeIcon className="mr-1" icon={faExclamationTriangle} />
        {isMac()
          ? t('CanvasStage.components.MainStage.holdModKeyForScroll.mac')
          : t('CanvasStage.components.MainStage.holdModKeyForScroll.windows')}
      </p>
    </div>
  );
};

const constructScaledImages = (
  imageConfigs: Konva.ImageConfig[] | undefined,
  stageState: StageState,
  imageSize: ImageSize,
) =>
  imageConfigs?.map(({ x, y, width, height, offsetX, offsetY, ...props }, index) => {
    const imageScale =
      props.image instanceof HTMLImageElement ? props.image.height / props.image.width : 1.0;

    const scaledWidth = width! / stageState.scale;
    const scaledHeight = scaledWidth * imageScale;

    return (
      <Image
        key={index}
        x={x! * imageSize.width}
        y={y! * imageSize.height}
        width={scaledWidth}
        height={scaledHeight}
        offsetX={scaledWidth / 2}
        offsetY={scaledHeight / 2}
        perfectDrawEnabled={PERFECT_DRAW_ENABLED}
        {...props}
      />
    );
  });

const constructScaledLabels = (
  labelConfigs: LabelProps[] | undefined,
  stageState: StageState,
  imageSize: ImageSize,
) =>
  labelConfigs?.map(
    (
      {
        x,
        y,
        tag: { pointerWidth, pointerHeight, strokeWidth, ...tagProps },
        text: { fontSize, padding, ...textProps },
        ...labelProps
      },
      index,
    ) => (
      <Label key={index} x={x! * imageSize.width} y={y! * imageSize.height} {...labelProps}>
        <Tag
          pointerWidth={pointerWidth! / stageState.scale}
          pointerHeight={pointerHeight! / stageState.scale}
          strokeWidth={strokeWidth! / stageState.scale}
          {...tagProps}
        />
        <Text
          fontSize={fontSize! / stageState.scale}
          padding={padding! / stageState.scale}
          {...textProps}
        />
      </Label>
    ),
  );
