import { useState, useEffect } from 'react';

export type ImageStatus = 'loading' | 'loaded' | 'failed';

const useImage = (url?: string): [image: HTMLImageElement | undefined, status: ImageStatus] => {
  const [state, setState] = useState<{
    image: HTMLImageElement | undefined;
    status: ImageStatus;
  }>({ image: undefined, status: 'loading' });

  useEffect(() => {
    if (!url) return;
    const img = new Image();

    const onload = () => {
      setState({ image: img, status: 'loaded' });
    };

    const onerror = () => {
      setState({ image: undefined, status: 'failed' });
    };

    img.addEventListener('load', onload, { passive: true });
    img.addEventListener('error', onerror, { passive: true });
    img.src = url;
    // img.crossOrigin = 'anonymous';

    return () => {
      img.removeEventListener('load', onload);
      img.removeEventListener('error', onerror);
      setState({
        image: undefined,
        status: 'loading',
      });
    };
  }, [url]);

  return [state.image, state.status];
};

export default useImage;
