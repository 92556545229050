import useCustomer from 'utils/hooks/data/customers/use-customer';
import useUser from 'utils/hooks/data/users/use-user';

const useUserCustomer = (userId?: string) => {
  const { user, updateUser } = useUser(userId);
  const setUserCustomer = (customerId: string) => updateUser({ customer_id: customerId });

  return {
    setUserCustomer,
    ...useCustomer(user?.customer_id),
  };
};

export default useUserCustomer;
