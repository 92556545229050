import { useMutation, useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import queryClient from 'utils/queries/client';
import HookOptions from 'utils/types/HookOptions';
import { parseSensorDownlink } from 'utils/types/SensorDownlink';

const useSensorDownlinks = (sensorId?: string, { enableGet = true }: HookOptions = {}) => {
  const queryKey = ['sensorDownlinks', sensorId];

  // Sensor Downlinks - Get
  const { data: downlinks, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/sensors/${sensorId}/downlinks`),
    select: (downlinks: any[]) => downlinks.map(parseSensorDownlink),
    enabled: enableGet && !!sensorId,
  });

  // Sensor Downlinks - Create by ID
  const { mutateAsync: createDownlinkById } = useMutation({
    mutationFn: ({ id: sensorId, frequency }: { id: string; frequency: number }) =>
      api.post(
        `/sensors/${sensorId}/downlinks`,
        {},
        {
          params: {
            frequency,
          },
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  // Sensor Downlinks - Create
  const createDownlink = async (frequency: number) =>
    await createDownlinkById({ id: sensorId!, frequency });

  return {
    downlinks,
    createDownlink,
    createDownlinkById,
    isPending,
  };
};

export default useSensorDownlinks;
