import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import api from 'utils/api';
import useSelectedCustomer from 'utils/hooks/use-selected-customer';
import AlarmRule, {
  parseAlarmRule,
  getQueryKeyAlarmRule,
  getQueryKeyAdminAlarmRules,
} from 'utils/types/AlarmRule';
import AlarmRuleCriterion from 'utils/types/AlarmRuleCriterion';
import AlarmRuleLocaleDetail from 'utils/types/AlarmRuleLocaleDetail';
import { getQueryKeyCustomerAlarmRules } from 'utils/types/Customer';
import HookOptions from 'utils/types/HookOptions';

const useAlarmRule = (
  alarmRuleId?: string,
  { enableGet = true, disableErrorNotifications = false }: HookOptions = {},
) => {
  const queryKey = getQueryKeyAlarmRule(alarmRuleId!);

  const queryClient = useQueryClient();
  const { customerId } = useSelectedCustomer();

  // Get
  const {
    data: alarmRule,
    isPending,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/alarm_rules/${alarmRuleId}`, {
        _disableErrorNotifications: disableErrorNotifications,
      }),
    select: (alarm_rule: any) => parseAlarmRule(alarm_rule),
    enabled: !!alarmRuleId && enableGet,
  });

  // Update
  const { mutateAsync: updateAlarmRule } = useMutation({
    mutationKey: queryKey,
    mutationFn: async (
      values: Pick<
        AlarmRule,
        'name' | 'type' | 'active_period_from' | 'active_period_to' | 'repeat_yearly'
      >,
    ): Promise<AlarmRule> => {
      const alarmRule = await api.patch(`/alarm_rules/${alarmRuleId}`, values);
      return parseAlarmRule(alarmRule);
    },
    onSuccess: async alarmRule => {
      queryClient.setQueryData(queryKey, alarmRule);
      queryClient.invalidateQueries({ queryKey: getQueryKeyCustomerAlarmRules(customerId!) });
      queryClient.invalidateQueries({ queryKey: getQueryKeyAdminAlarmRules() });
    },
  });

  // Delete
  const { mutateAsync: deleteAlarmRule } = useMutation({
    mutationKey: queryKey,
    mutationFn: () => api.delete(`/alarm_rules/${alarmRuleId}`),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: getQueryKeyCustomerAlarmRules(customerId!) });
      queryClient.invalidateQueries({ queryKey: getQueryKeyAdminAlarmRules() });
    },
  });

  // Create alarm rule criterion
  const { mutateAsync: createAlarmRuleCriterion } = useMutation({
    mutationFn: async (
      values: Pick<
        AlarmRuleCriterion,
        'parameter' | 'comparison_operator' | 'value' | 'type' | 'delayed_days'
      >,
    ): Promise<AlarmRule> => {
      const alarmRule = await api.post(`/alarm_rules/${alarmRuleId}/criteria`, values);
      return parseAlarmRule(alarmRule);
    },
    onSuccess: (alarmRule: AlarmRule): void => {
      // Save new alarm rule to cache
      queryClient.setQueryData(queryKey, alarmRule);
    },
  });

  // Delete alarm rule criterion
  const { mutateAsync: deleteAlarmRuleCriterion } = useMutation({
    mutationFn: async (alarmRuleCriterionId: string): Promise<AlarmRule> => {
      const alarmRule = await api.delete(
        `/alarm_rules/${alarmRuleId}/criteria/${alarmRuleCriterionId}`,
      );
      return parseAlarmRule(alarmRule);
    },
    onSuccess: (alarmRule: AlarmRule): void => {
      // Save new alarm rule to cache
      queryClient.setQueryData(queryKey, alarmRule);
    },
  });

  // Create alarm rule locale detail
  const { mutateAsync: createAlarmRuleLocaleDetail } = useMutation({
    mutationFn: async (
      values: Pick<AlarmRuleLocaleDetail, 'locale' | 'name'>,
    ): Promise<AlarmRule> => {
      const alarmRule = await api.post(`/alarm_rules/${alarmRuleId}/locale_details`, values);
      return parseAlarmRule(alarmRule);
    },
    onSuccess: (alarmRule: AlarmRule): void => {
      // Save new alarm rule to cache
      queryClient.setQueryData(queryKey, alarmRule);
    },
  });

  // Update alarm rule locale detail
  const { mutateAsync: updateAlarmRuleLocaleDetailById } = useMutation({
    mutationFn: async ({
      id,
      ...values
    }: Pick<AlarmRuleLocaleDetail, 'id' | 'locale' | 'name'>): Promise<AlarmRule> => {
      const alarmRule = await api.patch(`/alarm_rules/${alarmRuleId}/locale_details/${id}`, values);
      return parseAlarmRule(alarmRule);
    },
    onSuccess: (alarmRule: AlarmRule): void => {
      // Save new alarm rule to cache
      queryClient.setQueryData(queryKey, alarmRule);
    },
  });

  // Delete alarm rule locale detail
  const { mutateAsync: deleteAlarmRuleLocaleDetailById } = useMutation({
    mutationFn: async (id: string): Promise<AlarmRule> => {
      const alarmRule = await api.delete(`/alarm_rules/${alarmRuleId}/locale_details/${id}`);
      return parseAlarmRule(alarmRule);
    },
    onSuccess: (alarmRule: AlarmRule): void => {
      // Save new alarm rule to cache
      queryClient.setQueryData(queryKey, alarmRule);
    },
  });

  return {
    alarmRule,
    deleteAlarmRule,
    updateAlarmRule,
    createAlarmRuleCriterion,
    deleteAlarmRuleCriterion,
    createAlarmRuleLocaleDetail,
    updateAlarmRuleLocaleDetailById,
    deleteAlarmRuleLocaleDetailById,
    isPending: isPending || isLoading || isFetching,
  };
};

export default useAlarmRule;
