import { forwardRef, MutableRefObject, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericSelectInput, SelectProps } from 'components';
import GroupType, { getGroupTypeText } from 'utils/enums/GroupType';

interface Props extends Omit<SelectProps<GroupType>, 'name'> {
  includeLabel?: boolean;
}

const GroupTypeSelectInput = forwardRef<HTMLInputElement, Props>(
  ({ includeLabel = false, ...props }, _ref) => {
    const ref = _ref as MutableRefObject<HTMLInputElement | null>;
    const name = 'ws-group-type-select-input';

    const { t } = useTranslation('components');

    return (
      <>
        {includeLabel && (
          <label htmlFor={name} onClick={() => ref?.current && ref.current.focus()}>
            {t('inputs.GroupTypeSelect.label')}
          </label>
        )}

        <GenericSelectInput
          ref={ref}
          name={name}
          optionsToSelect={useMemo(
            () => [GroupType.Unknown, GroupType.Project, GroupType.Delivery],
            [],
          )}
          placeholder={t('inputs.GroupTypeSelect.placeholder')}
          valueToTextFn={useCallback((value: GroupType) => getGroupTypeText(value), [])}
          {...props}
        />
      </>
    );
  },
);

export default GroupTypeSelectInput;
