import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { TableLink } from 'components';
import { SkeletonRows } from 'components/tables/Table/components/TableContent/components';
import { dateToLocaleString, dateToPrettyDate } from 'utils/date';
import { sensorId2NameFunc } from 'utils/formatting';
import AlarmNotification from 'utils/types/AlarmNotification';
import Sensor from 'utils/types/Sensor';

export const OverviewAlarmNotificationsTable: React.FC<{
  data: AlarmNotification[];
  sensors: Sensor[];
  isPending?: boolean;
}> = ({ data, sensors, isPending }) => {
  const { t } = useTranslation('components');

  const columns = useMemo(() => {
    const sensorId2Name = sensorId2NameFunc(sensors);

    const columnHelper = createColumnHelper<AlarmNotification>();

    return [
      columnHelper.accessor('sensor_id', {
        id: 'sensor_id',
        header: () => t('tables.AlarmNotificationsTable.columns.sensor_id.text'),
        enableColumnFilter: false,
        size: 100,
        cell: ({ getValue, row: { original } }) => {
          const value = getValue();
          const name = original.sensor_id ? sensorId2Name[original.sensor_id] || value : 'Gateway';
          const nameTruncated = name?.substring(0, 9);
          return original.sensor_id ? (
            <TableLink
              className="text-sm mr-1"
              name={nameTruncated}
              url={`/user/sensors/${value}`}
              datatip={t('tables.AlarmNotificationsTable.columns.sensor_id.dataTip.1')}
            />
          ) : (
            <TableLink
              className="text-sm mr-2"
              name={nameTruncated}
              url={`/user/gateways/${original.gateway_id}`}
              datatip={t('tables.AlarmNotificationsTable.columns.gateway_id.dataTip.1')}
            />
          );
        },
      }),

      columnHelper.accessor('description', {
        id: 'description',
        header: () => t('tables.AlarmNotificationsTable.columns.description.text'),
        meta: {
          className: 'text-sm truncate max-w-xs',
        },
        enableColumnFilter: false,
        cell: ({ getValue, row: { original } }) => (
          <Link to={`/user/notifications/${original.id}`}>{getValue()}</Link>
        ),
      }),

      columnHelper.accessor('sent_time', {
        header: () => t('tables.AlarmNotificationsTable.columns.sent_time.text'),
        id: 'sent_time',
        enableColumnFilter: false,
        sortingFn: 'datetime',
        cell: ({ getValue }) => (
          <p
            data-tooltip-content={dateToLocaleString(getValue())}
            data-tooltip-id="route-tooltip"
            className="text-sm"
          >
            {dateToPrettyDate(getValue())}
          </p>
        ),
      }),
    ];
  }, [sensors, t]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <table className="min-w-full table-auto text-brand-gray border-collapse">
      <tbody>
        {isPending ? (
          <SkeletonRows className="py-2" rows={4} />
        ) : (
          table.getRowModel().rows.map((row, i) => (
            <tr
              key={row.id}
              className={classNames('border-gray-lighter', {
                'border-b': i !== table.getRowModel().rows.length - 1,
              })}
            >
              {row.getVisibleCells().map(cell => (
                <td key={cell.id} className="py-4" style={{ width: `${cell.column.getSize()}px` }}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
};
