import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useMemo } from 'react';

import { Table, TableLink } from 'components';
import { dateToLocaleString, dateToPrettyDate } from 'utils/date';
import { userId2NameFunc } from 'utils/formatting';
import { useCurrentUserUsers, useCurrentUserCustomers } from 'utils/hooks/data';
import { customerId2CustomerFunc } from 'utils/mapping';
import SalesOrder from 'utils/types/SalesOrder';

export const SalesOrdersTable: React.FC<{
  data: SalesOrder[];
  isPending?: boolean;
  onClick?: (row?: SalesOrder) => void;
}> = ({ data, isPending, ...props }) => {
  const { users } = useCurrentUserUsers();
  const { customers } = useCurrentUserCustomers();

  const columns = useMemo(() => {
    const userId2Name = userId2NameFunc(users);
    const customerId2Customer = customerId2CustomerFunc(customers);

    const columnHelper = createColumnHelper<SalesOrder>();

    return [
      columnHelper.accessor('airtable_sales_order_name', {
        id: 'airtable_sales_order_name',
        header: () => 'Sales order name',
        enableColumnFilter: false,
        size: 300,
      }),

      columnHelper.accessor('sensors_selected', {
        id: 'sensors_selected',
        header: () => 'Sensors',
        enableColumnFilter: false,
        size: 200,
        cell: ({ getValue, row: { original } }) => {
          const value = getValue();
          const count = value === '' ? 0 : value.split(',').length;
          return count === 0 ? (
            <samp>n/a</samp>
          ) : (
            <>
              <samp>{count}</samp> x {original.sensors_product_name}
            </>
          );
        },
      }),

      columnHelper.accessor('gateways_selected', {
        id: 'gateways_selected',
        header: () => 'Gateways',
        enableColumnFilter: false,
        size: 200,
        cell: ({ getValue, row: { original } }) => {
          const value = getValue();
          const count = value === '' ? 0 : value.split(',').length;
          return count === 0 ? (
            <samp>n/a</samp>
          ) : (
            <>
              <samp>{count}</samp> x {original.gateways_product_name}
            </>
          );
        },
      }),

      columnHelper.accessor('sensor_group_id', {
        id: 'sensor_group_id',
        header: () => 'Group',
        size: 150,
        enableColumnFilter: false,
        cell: ({ getValue }) => (
          <TableLink
            name="Group"
            url={`/user/groups/${getValue()}`}
            externalDatatip="Open in new tab"
          />
        ),
      }),

      columnHelper.accessor('customer_id', {
        id: 'customer_id',
        header: () => 'Customer',
        enableColumnFilter: false,
        size: 500,
        cell: ({ getValue }) => {
          const value = getValue();
          const customer = customerId2Customer[value];
          return (
            <TableLink
              name={customer ? customer.name : value}
              url={`/admin/customers/${value}`}
              externalDatatip="Open in new tab"
            />
          );
        },
      }),

      columnHelper.accessor('user_email_invited', {
        id: 'user_email_invited',
        header: () => 'User invited',
        enableColumnFilter: false,
        size: 350,
        cell: ({ getValue }) => <samp>{getValue()}</samp>,
      }),

      columnHelper.accessor('postnord_tracking_number', {
        id: 'postnord_tracking_number',
        header: () => 'Tracking number (PostNord)',
        enableColumnFilter: false,
        size: 400,
        cell: ({ getValue }) => {
          const value = getValue();
          return value ? (
            <a
              className="underline text-brand-green hover:text-blue-500"
              href={`https://tracking.postnord.com/en/?id=${value}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <samp className="mr-2">{value}</samp>
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </a>
          ) : (
            <></>
          );
        },
      }),

      columnHelper.accessor('ups_tracking_number', {
        id: 'ups_tracking_number',
        header: () => 'Tracking number (UPS)',
        enableColumnFilter: false,
        size: 400,
        cell: ({ getValue }) => {
          const value = getValue();
          return value ? (
            <a
              className="underline text-brand-green hover:text-blue-500"
              href={`https://www.ups.com/track?tracknum=${value}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <samp className="mr-2">{value}</samp>
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </a>
          ) : (
            <></>
          );
        },
      }),

      columnHelper.accessor('created_date', {
        header: () => 'Completed date',
        id: 'created_date',
        enableColumnFilter: false,
        sortingFn: 'datetime',
        sortDescFirst: true,
        size: 150,
        cell: ({ getValue }) => (
          <span
            data-tooltip-content={dateToLocaleString(getValue())}
            data-tooltip-id="route-tooltip"
          >
            {dateToPrettyDate(getValue())}
          </span>
        ),
      }),

      columnHelper.accessor('created_by', {
        id: 'created_by',
        header: () => 'Completed by',
        size: 300,
        enableColumnFilter: false,
        cell: ({ getValue }) => {
          const value = getValue();
          if (!value) return <>n/a</>;
          const name = userId2Name[value];
          const text = name || value;
          return (
            <TableLink
              name={<samp>{text}</samp>}
              url={`/user/organization/users/${value}`}
              externalDatatip="Open in new tab"
            />
          );
        },
      }),
    ];
  }, [customers, users]);

  return (
    <Table
      data={data}
      columns={columns}
      pageSize={10}
      loading={isPending}
      showSearchBar
      sortBy={[
        {
          id: 'created_date',
          desc: true,
        },
      ]}
      {...props}
    />
  );
};
