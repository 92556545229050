import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { getQueryKeySensorAlarmRuleEnabled } from 'utils/types/Sensor';

const useSensorAlarmRule = (sensorId?: string, alarmRuleId?: string) => {
  // Check if is enabled
  const { data: enabled, isPending } = useQuery({
    queryKey: getQueryKeySensorAlarmRuleEnabled(sensorId!, alarmRuleId!),
    queryFn: () => api.get(`/sensors/${sensorId}/alarm_rules/${alarmRuleId}/enabled`),
    select: (enabled: boolean) => enabled,
    enabled: !!sensorId && !!alarmRuleId,
  });

  return {
    enabled,
    isPending,
  };
};

export default useSensorAlarmRule;
