import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import useSelectedCustomer from 'utils/hooks/use-selected-customer';
import { getQueryKeyCustomerAlarmRuleSensors } from 'utils/types/Customer';
import HookOptions from 'utils/types/HookOptions';
import { parseSensor } from 'utils/types/Sensor';

const useCustomerAlarmRuleSensors = (
  customerId?: string,
  alarmRuleId?: string,
  { enableGet = true, ignoreAdminCustomerOverwrite = false }: HookOptions = {},
) => {
  const queryKey = getQueryKeyCustomerAlarmRuleSensors(customerId!, alarmRuleId!, false);

  const { showAllCustomers } = useSelectedCustomer();
  const url =
    showAllCustomers && !ignoreAdminCustomerOverwrite
      ? `/admin/alarm_rules/${alarmRuleId}/sensors`
      : `/customers/${customerId}/alarm_rules/${alarmRuleId}/sensors`;

  // Get
  const {
    data: sensors,
    isPending,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: () => api.get(url),
    select: (sensors: any[]) => sensors.map(parseSensor),
    enabled: !!customerId && !!alarmRuleId && enableGet,
  });

  return {
    sensors,
    isPending: isPending || isLoading || isFetching,
  };
};

export default useCustomerAlarmRuleSensors;
