import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { HTMLAttributes } from 'react';

const ExternalLink: React.FC<
  {
    className?: string;
    text?: string;
    url?: string;
  } & HTMLAttributes<HTMLAnchorElement>
> = ({ className, text, url, ...props }) => (
  <a
    href={url}
    className={classNames('underline text-brand-green hover:text-blue-500', className)}
    target="_blank"
    rel="noreferrer"
    {...props}
  >
    {text}
    <FontAwesomeIcon className="ml-1" icon={faExternalLinkAlt} />
  </a>
);

export default ExternalLink;
