import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import queryString from 'query-string';
import { Link } from 'react-router-dom';

const SensorLink: React.FC<
  React.PropsWithChildren<{
    sensorId: string;
    fromSensorGroupId?: string;
    timeFrom?: Date;
    timeTo?: Date;
    className?: string;
    datatip?: string;
    externalDatatip?: string;
  }>
> = ({
  sensorId,
  fromSensorGroupId,
  timeFrom,
  timeTo,
  className: _className,
  children,
  datatip,
  externalDatatip,
}) => {
  const to = {
    pathname: `/user/sensors/${sensorId}/values`,
    search: queryString.stringify({
      fromSensorGroup: fromSensorGroupId,
      timeFrom: timeFrom?.toISOString(),
      timeTo: timeTo?.toISOString(),
    }),
  };
  const onClick = (event: React.MouseEvent<HTMLElement>) => event.stopPropagation();

  const className = 'text-brand-gray-light-1 hover:text-blue-500';

  return (
    <>
      <Link
        className={classNames(className, _className)}
        to={to}
        onClick={onClick}
        data-tooltip-content={datatip}
        data-tooltip-id="route-tooltip"
      >
        {children}
      </Link>
      {externalDatatip && (
        <Link
          className={classNames('float-right', className)}
          target="_blank"
          onClick={onClick}
          to={to}
          data-tooltip-content={externalDatatip}
          data-tooltip-id="route-tooltip"
        >
          <FontAwesomeIcon icon={faExternalLinkAlt} />
        </Link>
      )}
    </>
  );
};

export default SensorLink;
