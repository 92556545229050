import { Column } from '@tanstack/react-table';
import { ChangeEvent, Dispatch, ReactNode, SetStateAction, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useOnClickOutside } from 'utils/hooks';

export const ToggleColumnsVisibilityModal = <T extends {}>({
  columsToToggle,
  setIsToggleColumnsVisibilityModalOpen,
  getIsAllColumnsVisible,
  getToggleAllColumnsVisibilityHandler,
  resetColumnVisibility,
}: {
  columsToToggle: Column<T>[];
  setIsToggleColumnsVisibilityModalOpen: Dispatch<SetStateAction<boolean>>;
  getIsAllColumnsVisible: () => boolean;
  getToggleAllColumnsVisibilityHandler: () => (event: ChangeEvent<HTMLInputElement>) => void;
  resetColumnVisibility: (defaultState?: boolean | undefined) => void;
}) => {
  const { t } = useTranslation('components');

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setIsToggleColumnsVisibilityModalOpen(false));

  return (
    <div
      ref={ref}
      className="absolute z-50 bg-white p-2.5 right-1  top-2 rounded max-h-96 overflow-y-auto shadow"
    >
      <div>
        <label className="flex items-center text-left">
          <input
            type="checkbox"
            checked={getIsAllColumnsVisible()}
            onChange={getToggleAllColumnsVisibilityHandler()}
            className="mr-1"
          />{' '}
          {t('table.Table.ToggleColumnsVisibilityModal.all')}
        </label>
      </div>
      {columsToToggle.map(column => {
        const x =
          typeof column.columnDef.header === 'function'
            ? (column.columnDef.header as () => string | ReactNode)
            : (column.columnDef.header as string);
        const label = typeof x === 'function' ? x() : x;
        return (
          <div key={column.id} className="px-1">
            <label className="flex items-baseline text-left">
              <input
                type="checkbox"
                checked={column.getIsVisible()}
                disabled={!column.getCanHide()}
                onChange={column.getToggleVisibilityHandler()}
                className="mr-1"
              />{' '}
              {label}
            </label>
          </div>
        );
      })}
      <div>
        <button onClick={() => resetColumnVisibility()}>Reset</button>
      </div>
    </div>
  );
};
