import { useMemo, SetStateAction, Dispatch } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, DownlinksTable } from 'components';
import SensorFrequencyUpdateModal from 'components/modals/SensorFrequencyUpdateModal';
import { confirmSensorDownlinkSubmit } from 'components/notifications';
import { useCurrentUser } from 'utils/hooks';
import { useSensorDownlinks, useSensorTransmissions } from 'utils/hooks/data';
import { notificationSuccess } from 'utils/notifications';

const SensorDownlinks: React.FC<{
  sensorId: string;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  hideSubmitButton?: boolean;
}> = ({ sensorId, showModal, setShowModal, hideSubmitButton = false }) => {
  const { isAdmin } = useCurrentUser();
  const { downlinks, createDownlink, isPending: isPendingDownlinks } = useSensorDownlinks(sensorId);

  const now = new Date();
  const yesterday = new Date(now.getTime());
  yesterday.setDate(yesterday.getDate() - 1);

  const { transmissions } = useSensorTransmissions(sensorId, {
    fromTimestamp: yesterday,
    toTimestamp: now,
  });

  const data = useMemo(() => downlinks || [], [downlinks]);

  const { t } = useTranslation('components');

  const onSubmitDownlink = async (frequency: number) => {
    const isConfirmed = await confirmSensorDownlinkSubmit();

    if (isConfirmed) {
      await createDownlink(frequency);
      notificationSuccess(t('cards.sensors.SensorDownlinkCard.onSubmitText'));
      setShowModal(false);
    }
  };

  const [latestTransmission] = transmissions;

  return (
    <>
      <div className="mb-2.5">
        <p>
          <>
            {t('cards.sensors.SensorDownlinkCard.text.1')}{' '}
            {t('cards.sensors.SensorDownlinkCard.text.2')}{' '}
            <span className="font-semibold">{t('cards.sensors.SensorDownlinkCard.text.3')}</span>{' '}
            {t('cards.sensors.SensorDownlinkCard.text.4')}
          </>
        </p>
        {latestTransmission?.heartbeat_interval && (
          <p>
            <>
              {t('cards.sensors.SensorDownlink.Card.text.5')}
              <strong>
                {latestTransmission?.heartbeat_interval}
                {'.'}
              </strong>
            </>
          </p>
        )}
      </div>

      <div className="mb-3">
        <DownlinksTable
          sensorId={sensorId}
          loading={isPendingDownlinks}
          data={data}
          showUser={isAdmin}
        />
      </div>

      {!hideSubmitButton && (
        <>
          <Button size="sm" variant="outline-info" onClick={() => setShowModal(true)}>
            {t('cards.sensors.SensorDownlinkCard.buttonText')}
          </Button>

          <SensorFrequencyUpdateModal
            show={showModal}
            setShow={setShowModal}
            onSubmit={onSubmitDownlink}
          />
        </>
      )}
    </>
  );
};

export default SensorDownlinks;
