import { useTranslation } from 'react-i18next';
//@ts-ignore
import SimpleReactCalendar from 'simple-react-calendar';

import 'styles/components/_calendar.scss';

export type RangeType = {
  start?: Date | null;
  end?: Date | null;
};

type CalendarInputProps = {
  onSelect: (date: Date | RangeType) => void;
  initialDate?: Date | RangeType | null;
  maxMonthsBack?: number;
  minDate?: Date | null;
  maxDate?: Date | null;
  mode?: 'range' | 'single';
};

export const CalendarInput: React.FC<CalendarInputProps> = ({
  onSelect,
  initialDate,
  maxMonthsBack = 12,
  minDate,
  maxDate,
  mode = 'single',
}) => {
  const { t } = useTranslation('components');

  // Set min date
  const now = new Date();
  const minDateMonthsBack = new Date(now.getTime());
  minDateMonthsBack.setMonth(minDateMonthsBack.getMonth() - maxMonthsBack);

  const CALENDAR_DAYS_OF_WEEK = [
    t('inputs.CalendarRange.Mon'), // 'Man',
    t('inputs.CalendarRange.Tue'), // 'Tir',
    t('inputs.CalendarRange.Wed'), // 'Ons',
    t('inputs.CalendarRange.Thu'), // 'Tor',
    t('inputs.CalendarRange.Fri'), // 'Fre',
    t('inputs.CalendarRange.Sat'), // 'Lør',
    t('inputs.CalendarRange.Sun'), // 'Søn',
  ];

  return (
    <SimpleReactCalendar
      daysOfWeek={CALENDAR_DAYS_OF_WEEK}
      activeMonth={now}
      selected={initialDate}
      minDate={minDate ? minDate : minDateMonthsBack}
      maxDate={maxDate ? maxDate : undefined}
      mode={mode}
      onSelect={onSelect}
    />
  );
};
