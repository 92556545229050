import { Dispatch, SetStateAction, useState } from 'react';

//Inspiration taken from here: https://usehooks.com/useLocalStorage/
const useLocalStorage = <T extends NonNullable<{}> | null>(
  key: string,
  initialValue: NonNullable<T> | null
): [T, Dispatch<SetStateAction<T>>] => {
  // NOTE: maxLength can used to make sure a corrupt (T = string) localStorage value is not.

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from local storage by key
      const item = localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      localStorage.removeItem(key);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T | ((value: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.warn(`Error setting localStorage key “${key}”:`, error);
      console.log(error);
    }
  };
  return [storedValue, setValue];
};

export default useLocalStorage;
