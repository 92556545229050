import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { parseUser } from 'utils/types/User';

const useSensorUsers = (sensorId?: string) => {
  const queryKey = ['sensorUsers', sensorId];

  // Sensor Users - Get
  const { data: users, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/sensors/${sensorId}/users`),
    select: (data: any[]) => data.map(parseUser),
    enabled: !!sensorId,
  });

  return {
    users,
    isPending,
  };
};

export default useSensorUsers;
