import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, HTMLAttributes, forwardRef } from 'react';
import Autosuggest, { InputProps } from 'react-autosuggest';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { faWsSensor, faWsGroup, faWsUsers, faWsGateway } from 'components/icons';
import PulsatingCircle from 'components/PulsatingCircle';
import { md } from 'utils/breakpoints';
import debounce from 'utils/debounce';
import { useSelectedCustomer, useIsMounted, useWindowSize } from 'utils/hooks';
import {
  useCustomerGateways,
  useCustomerSensorGroups,
  useCustomerSensors,
  useCustomerUsers,
} from 'utils/hooks/data';
import Gateway from 'utils/types/Gateway';
import Sensor from 'utils/types/Sensor';
import SensorGroup from 'utils/types/SensorGroup';
import User from 'utils/types/User';
import 'styles/components/_autosuggest.scss';
import 'styles/components/_global-search.scss';

const MAX_RESULTS = 20;

type UserSuggestion = {
  type: 'user';
  object: User;
};

type SensorSuggestion = {
  type: 'sensor';
  object: Sensor;
};

type GatewaySuggestion = {
  type: 'gateway';
  object: Gateway;
};

type SensorGroupSuggestion = {
  type: 'sensorGroup';
  object: SensorGroup;
};

export type Suggestion =
  | UserSuggestion
  | SensorSuggestion
  | GatewaySuggestion
  | SensorGroupSuggestion;

type Method = 'down' | 'up' | 'escape' | 'enter' | 'click' | 'type';

export const GlobalSearchInput = forwardRef<
  HTMLInputElement,
  {
    onSelect?: (suggestion: User | Sensor | Gateway | SensorGroup) => void;
  } & Omit<
    HTMLAttributes<HTMLInputElement>,
    'placeholder' | 'value' | 'onChange' | 'onBlur' | 'onFocus'
  >
>(({ onSelect, ...props }, ref) => {
  const isMounted = useIsMounted();

  const navigate = useNavigate();

  const [enableHooks, setEnableHooks] = useState(false);
  const [suggestedInput, setSuggestedInput] = useState('');
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [width] = useWindowSize();

  const { customerId } = useSelectedCustomer();
  const { sensors } = useCustomerSensors(customerId, {
    enableGet: enableHooks,
    includeTags: false,
  });
  const { sensorGroups } = useCustomerSensorGroups(customerId, {
    enableGet: enableHooks,
  });
  const { gateways } = useCustomerGateways(customerId, {
    enableGet: enableHooks,
  });
  const { users } = useCustomerUsers(customerId, {
    enableGet: enableHooks,
  });

  const { t } = useTranslation('components');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = debounce((query: string) => {
    if (!isMounted()) return;

    const suggestions: Suggestion[] = [];

    // Filter sensors
    const sensorsFiltered = sensors
      ?.filter(
        sensor =>
          sensor.id.toLowerCase().includes(query) ||
          (sensor.name || '').toLowerCase().includes(query) ||
          (sensor.hardware_id || '').toLowerCase().includes(query) ||
          (sensor.ttn_dev_id || '').toLocaleLowerCase().includes(query) ||
          (sensor.geographic_location?.description || '').toLowerCase().includes(query),
      )
      .slice(0, MAX_RESULTS)
      .map(sensor => ({
        type: 'sensor',
        object: sensor,
      })) as Suggestion[];

    if (sensorsFiltered) {
      suggestions.push(...sensorsFiltered);
    }

    if (suggestions.length < MAX_RESULTS) {
      // Filter gateways
      const gatewaysFiltered = gateways
        ?.filter(
          gateway =>
            gateway.id.toLowerCase().includes(query) ||
            (gateway.name || '').toLowerCase().includes(query),
        )
        .slice(0, MAX_RESULTS - suggestions.length)
        .map(gateway => ({
          type: 'gateway',
          object: gateway,
        })) as Suggestion[];

      if (gatewaysFiltered) {
        suggestions.push(...gatewaysFiltered);
      }
    }

    if (suggestions.length < MAX_RESULTS) {
      // Filter sensor groups
      const sensorGroupsFiltered = sensorGroups
        ?.filter(
          sensorGroup =>
            sensorGroup.name.toLowerCase().includes(query) ||
            (sensorGroup.description || '').toLowerCase().includes(query),
        )
        .slice(0, MAX_RESULTS - suggestions.length)
        .map(sensorGroup => ({
          type: 'sensorGroup',
          object: sensorGroup,
        })) as Suggestion[];

      if (sensorGroupsFiltered) {
        suggestions.push(...sensorGroupsFiltered);
      }
    }

    if (suggestions.length < MAX_RESULTS) {
      // Filter users
      const usersFiltered = users
        ?.filter(
          user =>
            user.full_name?.toLowerCase().includes(query) ||
            user.email?.toLowerCase().includes(query),
        )
        .slice(0, MAX_RESULTS - suggestions.length)
        .map(user => ({
          type: 'user',
          object: user,
        })) as Suggestion[];

      if (usersFiltered) {
        suggestions.push(...usersFiltered);
      }
    }

    setSuggestions(suggestions);
  }, 100);

  const getFilteredSuggestions = (value: string) => {
    value = value.toLowerCase().trim();

    debouncedSearch(value);
  };

  // Autosuggest will call this function every time you need to update suggestions.
  const onSuggestionsFetchRequested = ({ value }: { value: string }) =>
    getFilteredSuggestions(value);

  // Autosuggest will call this function every time you need to clear suggestions.
  const onAdminSuggestionsClearRequested = () => {
    if (!isMounted()) return;

    return setSuggestions([]);
  };

  const getSuggestionValue = (suggestion: Suggestion) => {
    if (suggestion.type === 'sensor') {
      return suggestion.object.name || 'Sensor';
    } else if (suggestion.type === 'gateway') {
      return suggestion.object.name || suggestion.object.id;
    } else if (suggestion.type === 'sensorGroup') {
      return suggestion.object.name;
    } else if (suggestion.type === 'user') {
      return suggestion.object.full_name || 'User';
    }
    return suggestion;
  };

  const onSuggestionSelected = (event: any, { suggestion }: { suggestion: Suggestion }) => {
    if (!isMounted()) return;

    event.preventDefault();

    const object = suggestion.object;
    const objectId = object.id;
    if (suggestion.type === 'sensor' && objectId) {
      navigate(`/user/sensors/${object.id}/values`);
    } else if (suggestion.type === 'gateway' && objectId) {
      navigate(`/user/gateways/${object.id}/overview`);
    } else if (suggestion.type === 'sensorGroup' && objectId) {
      navigate(`/user/groups/${object.id}/sensors`);
    } else if (suggestion.type === 'user' && objectId) {
      navigate(`/user/organization/users/${object.id}`);
    }

    setSuggestedInput('');

    if (onSelect) onSelect(object);
  };

  const handleHighlight = (result: any, search: string, id?: string) => {
    let idHighlighted: JSX.Element | null = null;
    const searchLower = search.toLowerCase();
    if (result && result.toLowerCase().indexOf(searchLower) >= 0) {
      const resultLower = result.toLowerCase();
      const searchStart = result.substring(0, resultLower.indexOf(searchLower));
      const higlight = result.substring(
        resultLower.indexOf(searchLower),
        resultLower.indexOf(searchLower) + searchLower.length,
      );
      const searchEnd = result.substring(
        resultLower.indexOf(searchLower) + searchLower.length,
        result.length,
      );
      result = (
        <>
          {searchStart}
          <u style={{ color: 'lightseagreen' }}>
            <strong>{higlight}</strong>
          </u>
          {searchEnd}
        </>
      );
    }
    if (!!id && id.toLowerCase().indexOf(searchLower) >= 0) {
      const idLower = id.toLowerCase();
      const idStart = id.substring(0, idLower.indexOf(searchLower));
      const idHiglight = id.substring(
        idLower.indexOf(searchLower),
        idLower.indexOf(searchLower) + searchLower.length,
      );
      const idEnd = id.substring(idLower.indexOf(searchLower) + searchLower.length, id.length);
      idHighlighted = (
        <>
          {idStart}
          <u style={{ color: 'lightseagreen' }}>
            <strong>{idHiglight}</strong>
          </u>
          {idEnd}
        </>
      );
    }

    return (
      <b className="ml-2">
        {result ? result : 'Sensor'}
        {id && width > md && (
          <strong>
            {' '}
            #<samp>({idHighlighted ? idHighlighted : id})</samp>
          </strong>
        )}
      </b>
    );
  };

  const renderSuggestion = (suggestion: Suggestion) => {
    let objectName: string | undefined,
      name: string,
      color: string,
      icon: IconDefinition,
      id: string | undefined;

    switch (suggestion.type) {
      case 'sensor':
        const sensor = suggestion.object;
        [objectName, name, color, icon, id] = [
          sensor.name,
          t('inputs.GlobalSearchInput.renderSuggestion.sensor'),
          '#A1512C',
          faWsSensor,
          sensor.hardware_id,
        ];
        break;
      case 'gateway':
        const gateaway = suggestion.object;
        [objectName, name, color, icon, id] = [
          gateaway.name,
          t('inputs.GlobalSearchInput.renderSuggestion.gateway'),
          '#326715',
          faWsGateway,
          gateaway.id,
        ];
        break;
      case 'sensorGroup':
        const sensorGroup = suggestion.object;
        [objectName, name, color, icon, id] = [
          sensorGroup.name,
          t('inputs.GlobalSearchInput.renderSuggestion.group'),
          '#532C0F',
          faWsGroup,
          sensorGroup.id,
        ];
        break;
      case 'user':
        const user = suggestion.object;
        [objectName, name, color, icon, id] = [
          user.full_name,
          t('inputs.GlobalSearchInput.renderSuggestion.user'),
          '#E69B24',
          faWsUsers,
          user.id,
        ];
        break;
    }

    return (
      <div className="relative flex">
        <Avatar name={objectName} size="38px" title={objectName} color={color} />

        <span className="ml-2 pt-2 grow">
          {name}:{handleHighlight(objectName, suggestedInput, id)}
          {suggestion.type === 'sensor' && (
            <PulsatingCircle
              className="ml-3"
              typeClass={suggestion.object.states.moisture_state}
              data-tooltip-content={`${suggestion.object.states.moisture_state}`}
              data-tooltip-id="route-tooltip"
            />
          )}
        </span>

        {width > md && <FontAwesomeIcon className="pt-2" icon={icon} size="lg" />}
      </div>
    );
  };

  return (
    <div className="global-search-div relative w-full z-50">
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onAdminSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={
          {
            ref,
            placeholder: t('inputs.GlobalSearchInput.placeholder'),
            value: suggestedInput,
            onChange: (event: any, { newValue, method }: { newValue: string; method: Method }) => {
              if (!isMounted()) return;

              setSuggestedInput(newValue);
            },
            onBlur: (event: any, { highlightedSuggestion }: { highlightedSuggestion: any }) => {
              if (!isMounted()) return;

              setSuggestedInput('');
            },
            onFocus: (event: any) => {
              if (!enableHooks) {
                setEnableHooks(true);
              }
            },
            ...props,
          } as InputProps<Suggestion>
        }
        onSuggestionSelected={onSuggestionSelected}
      />
    </div>
  );
});
