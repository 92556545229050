import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

const Page404 = lazy(() => import(/* webpackChunkName: "Page404" */ 'pages/Page404'));
const AlarmPage = lazy(() => import(/* webpackChunkName: "AlarmPage" */ 'pages/user/AlarmPage'));
const AlarmNotificationPage = lazy(
  () =>
    import(
      /* webpackChunkName: "AlarmNotificationPage" */ 'pages/user/alarms/AlarmNotificationPage'
    ),
);
const Blueprint = lazy(() => import(/* webpackChunkName: "Blueprint" */ 'pages/user/Blueprint'));
const Blueprints = lazy(() => import(/* webpackChunkName: "Blueprints" */ 'pages/user/Blueprints'));
const Gateway = lazy(
  () => import(/* webpackChunkName: "GatewayAlarms" */ 'pages/user/gateways/Gateway'),
);
const Gateways = lazy(
  () => import(/* webpackChunkName: "Gateways" */ 'pages/user/gateways/Gateways'),
);
const MapView = lazy(() => import(/* webpackChunkName: "Mapview" */ 'pages/user/MapView'));
const MaterialPassport = lazy(
  () =>
    import(
      /* webpackChunkName: "MaterialPassport" */ 'pages/user/material-passport/MaterialPassport'
    ),
);
const MaterialPassports = lazy(
  () =>
    import(
      /* webpackChunkName: "MaterialPassports" */ 'pages/user/material-passport/MaterialPassports'
    ),
);
const Note = lazy(() => import(/* webpackChunkName: "Note" */ 'pages/user/Note'));
const OrganizationPage = lazy(
  () => import(/* webpackChunkName: "OrganizationPage" */ 'pages/user/OrganizationPage'),
);
const Overview = lazy(() => import(/* webpackChunkName: "Overview" */ 'pages/user/Overview'));
const Report = lazy(() => import(/* webpackChunkName: "Report" */ 'pages/user/reports/Report'));
const Reports = lazy(() => import(/* webpackChunkName: "Reports" */ 'pages/user/reports/Reports'));
const Sensor = lazy(() => import(/* webpackChunkName: "Sensor" */ 'pages/user/sensors/Sensor'));
const SensorGroup = lazy(
  () => import(/* webpackChunkName: "SensorGroup" */ 'pages/user/sensor_groups/SensorGroup'),
);
const SensorGroups = lazy(
  () => import(/* webpackChunkName: "SensorGroups" */ 'pages/user/sensor_groups/SensorGroups'),
);
const Sensors = lazy(() => import(/* webpackChunkName: "Sensors" */ 'pages/user/sensors/Sensors'));
const SettingsPage = lazy(() => import(/* webpackChunkName: "Settings" */ 'pages/user/settings'));
const UserPage = lazy(() => import(/* webpackChunkName: "UserPage" */ 'pages/user/UserPage'));
const UsersPage = lazy(() => import(/* webpackChunkName: "UsersPage" */ 'pages/user/UsersPage'));

const routes: RouteObject[] = [
  {
    path: '/user/',
    Component: () => <Navigate to="/user/overview" replace={true} />,
  },
  {
    path: '/user/overview',
    Component: Overview,
  },
  {
    path: '/user/sensors/map',
    Component: MapView,
  },
  // {
  //   path: '/user/settings',
  //   Component:  () => <Navigate to="/settings/profile" replace={true} />,
  //   title: (props: any) => t('routes:titles./user/settings'),
  // },
  {
    path: '/user/settings/*',
    Component: SettingsPage,
  },
  {
    path: '/user/sensors',
    Component: Sensors,
  },
  {
    path: '/user/gateways/:id/*',
    Component: Gateway,
  },
  {
    path: '/user/gateways',
    Component: Gateways,
  },
  {
    path: '/user/groups',
    Component: SensorGroups,
  },
  {
    path: '/user/groups/:id/*',
    Component: SensorGroup,
  },
  {
    path: '/user/reports',
    Component: Reports,
  },
  {
    path: '/user/reports/:id',
    Component: Report,
  },
  {
    path: '/user/sensors/:id/*',
    Component: Sensor,
  },
  {
    path: '/user/alarms',
    Component: () => <Navigate to="/user/alarms/overview" replace={true} />,
  },
  {
    path: '/user/alarms/:id/*',
    Component: AlarmPage,
  },
  // TODO: Move this route to `/user/alarms/{alarm_id}/notifications/{alarm_notification_id}` somehow
  {
    path: '/user/notifications/:id',
    Component: AlarmNotificationPage,
  },

  {
    path: '/user/notes/:id',
    Component: Note,
  },
  {
    path: '/user/blueprints',
    Component: Blueprints,
  },
  {
    path: '/user/blueprints/:id',
    Component: Blueprint,
  },
  {
    path: '/user/material_passports',
    Component: MaterialPassports,
  },
  {
    path: '/user/material_passports/:id/*',
    Component: MaterialPassport,
  },
  {
    path: '/user/organization/users',
    Component: UsersPage,
  },
  {
    path: '/user/organization/users/:id',
    Component: UserPage,
  },
  {
    path: '/user/users/:id',
    Component: UserPage,
  },
  {
    path: '/user/organizations/:id',
    Component: OrganizationPage,
  },
  {
    path: '/user/*',
    Component: Page404,
  },
];

export default routes;
