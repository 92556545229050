import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import queryString from 'query-string';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { SliderSwitch } from 'components';
import { BlueprintSensorPositioning } from 'components/BlueprintSensorPositioning';
import { faWsPosition } from 'components/icons';
import BlueprintViewStateContext, {
  BlueprintViewStateType,
} from 'utils/contexts/blueprint-view-state-context';
import { Blueprint } from 'utils/types';
import Sensor from 'utils/types/Sensor';

export const BlueprintSensorPositioningCard = ({
  index,
  blueprint,
  timeFrom,
  timeTo,
  sensorIds,
  sensors,
}: {
  index: number;
  blueprint: Blueprint;
  timeFrom: Date;
  timeTo: Date;
  sensorIds: string[] | undefined;
  sensors: Sensor[] | undefined;
}) => {
  const { t } = useTranslation('components');

  const [blueprintViewState, setBlueprintViewState] = useState<BlueprintViewStateType>(
    BlueprintViewStateType.Moisture,
  );

  return (
    <BlueprintViewStateContext.Provider value={{ blueprintViewState, setBlueprintViewState }}>
      <div key={blueprint.id}>
        {index > 0 && <hr className="my-14 text-brand-gray-light-3" />}
        <h3 className="text-brand-gray font-semibold mb-4">
          <FontAwesomeIcon className="mr-3" icon={faWsPosition} />
          {blueprint?.descriptionTruncated}

          <Link
            className="float-right text-base underline hover:text-brand-blue"
            to={{
              pathname: `/user/blueprints/${blueprint.id}`,
              search: queryString.stringify({
                timeFrom: timeFrom?.toISOString(),
                timeTo: timeTo?.toISOString(),
              }),
            }}
          >
            {t('cards.sensor_groups.SensorGroupPositioningCard.blueprintDetailsLink')}
          </Link>
        </h3>

        <BlueprintSensorPositioning
          blueprintId={blueprint.id}
          onlyEnableSensorIds={sensorIds}
          availableSensors={sensors}
        />

        <div className="flex flex-grow items-end justify-end">
          <SliderSwitch id={blueprint.id} />
        </div>
      </div>
    </BlueprintViewStateContext.Provider>
  );
};
