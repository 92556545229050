import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import api from 'utils/api';
import { useCurrentUser } from 'utils/hooks';
import AlarmRule, { parseAlarmRule, getQueryKeyAdminAlarmRules } from 'utils/types/AlarmRule';
import { getQueryKeyCustomerAlarmRules } from 'utils/types/Customer';

const useAdminAlarmRules = () => {
  const queryKey = getQueryKeyAdminAlarmRules();

  const queryClient = useQueryClient();

  const { isAdmin } = useCurrentUser();

  // Get
  const { data: alarmRules, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get('/admin/alarm_rules'),
    select: (alarmRules: any[]) => alarmRules.map(parseAlarmRule),
    enabled: isAdmin,
  });

  // Create
  const { mutateAsync: createAlarmRule } = useMutation({
    mutationFn: async (
      values: Pick<
        AlarmRule,
        'name' | 'type' | 'active_period_from' | 'active_period_to' | 'repeat_yearly'
      >
    ) => {
      const alarmRules: any[] = await api.post('/admin/alarm_rules', values);
      return alarmRules.map(parseAlarmRule);
    },
    onSuccess: async alarmRules => {
      // Save new alarm rule to cache
      queryClient.setQueryData(queryKey, alarmRules);

      const customerAlarmRulesKey = getQueryKeyCustomerAlarmRules('').slice(0, 1);
      queryClient.invalidateQueries({ queryKey: customerAlarmRulesKey, exact: false });
    },
  });

  return {
    alarmRules,
    createAlarmRule,
    isPending,
  };
};

export default useAdminAlarmRules;
